import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";
import { HOME } from "../../../routes/Paths"; // Your home page path constant
import { useNavigate } from "react-router-dom";

const ChangeAdultCountAtFrontDeskModal = ({
  showConfirmAdultCountAtFrontDeskModal,
  confirmAdultCount,
  adultCount,
}) => {
  const { t } = useTranslation();
  const [selectedAdultCount, setSelectedAdultCount] = useState(adultCount);
  const [isFocused, setIsFocused] = useState(false);
  const [showOkModal, setShowOkModal] = useState(false); // For showing the "OK" modal
  const navigate = useNavigate();

  // Store the selected adult count in localStorage on change
  useEffect(() => {
    localStorage.setItem("selectedAdultCount", selectedAdultCount.toString());
  }, [selectedAdultCount]);

  const handleYesClick = () => {
    setShowOkModal(true); // Show the "OK" modal after clicking "Yes"
  };

  const handleOkClick = () => {
    setShowOkModal(false); // Close the "OK" modal
    navigate(HOME); // Navigate to the home page
  };

  const handleNoClick = () => {
    confirmAdultCount(selectedAdultCount); // Call the confirm function with the current adult count
  };

  return (
    <div>
      {/* Main Modal: Confirm Adult Count */}
      <Modal open={showConfirmAdultCountAtFrontDeskModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "800px",
            backgroundColor: "#f5f5f5",
            border: "2px #000",
            boxShadow: "0 0 24px rgba(0, 0, 0, 0.5)",
            padding: "70px",
            borderRadius: "20px",
          }}
        >
          <Typography sx={{ mt: 2, textAlign: "center", fontSize: "1.8rem" }}>
            <p>{t("Would you like to change Adult Count") + "?"}</p>
          </Typography>

          <div style={{ textAlign: "center" }}>
            <div style={{ marginBottom: "20px" }}>
              <select
                value={adultCount} // Display the adult count
                disabled // Prevent changes
                style={{
                  padding: "10px",
                  width: "100px",
                  border: isFocused ? "2px solid #007bff" : "2px solid #ced4da",
                  backgroundColor: "#f8f9fa",
                  outline: "none",
                  borderRadius: "5px",
                  transition: "border-color 0.2s ease",
                  cursor: "not-allowed",
                }}
              >
                <option value={adultCount}>{adultCount}</option>
              </select>
            </div>

            <div style={{ marginBottom: "20px", marginTop: "50px" }}>
              <button
                style={{
                  marginTop: "20px",
                  padding: "15px",
                  marginLeft: "15px",
                }}
                className="btn button-blue scan-btn"
                onClick={handleYesClick}
              >
                {t("Yes")}
              </button>

              <button
                style={{
                  marginTop: "20px",
                  padding: "15px",
                  marginLeft: "15px",
                }}
                className="btn button-blue scan-btn"
                onClick={handleNoClick}
              >
                {t("No")}
              </button>
            </div>
          </div>
        </Box>
      </Modal>

      {/* OK Modal: Display Confirmation with OK Button */}
      <Modal open={showOkModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "500px",
            backgroundColor: "#f5f5f5",
            border: "2px #000",
            boxShadow: "0 0 24px rgba(0, 0, 0, 0.5)",
            padding: "16px",
            borderRadius: "20px",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <i
              className="bx bx-check-circle"
              style={{ fontSize: "55px", color: "#0ec80e" }}
            ></i>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, textAlign: "center", fontSize: "1.8rem" }}
            >
              {t("To change adult count ,You may please proceed to front desk for assisstance!")}
            </Typography>
          </div>
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <button
              style={{
                padding: "15px",
                marginLeft: "15px",
              }}
              className="btn button-blue scan-btn"
              onClick={handleOkClick}
            >
              {t("OK")}
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ChangeAdultCountAtFrontDeskModal;


// import React, { useState, useEffect } from "react";
// import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
// import Modal from "@mui/material/Modal";
// import { useTranslation } from "react-i18next";
// import { HOME } from "../../../routes/Paths";
// import { Link, useNavigate } from "react-router-dom";

// const ChangeAdultCountAtFrontDeskModal = ({
//   showConfirmAdultCountAtFrontDeskModal,
//   confirmAdultCount,
//   closeModal,
//   backToHomePage,
//   adultCount,
// }) => {
//   const { t } = useTranslation();
//   const [selectedAdultCount, setSelectedAdultCount] = useState(adultCount);
//   const [isFocused, setIsFocused] = useState(false);
//   const [errorModalData2, setErrorModalData2] = useState({
//     toggleError: false,
//     errorMessage: "",
//   });
// const navigate = useNavigate();
//   useEffect(() => {
//     localStorage.setItem("selectedAdultCount", selectedAdultCount.toString());
//   }, [selectedAdultCount]);

//   const handleYesClick = () => {
//     <div>
//     <Modal  style={{ zIndex: "9999999999" }}>
//       <Box
//         sx={{
//           zIndex:"9999999999",
//           position: "absolute",
//           top: "50%",
//           left: "50%",
//           transform: "translate(-50%, -50%)",
//           width: "500px",
//           backgroundColor: "#f5f5f5",
//           border: "2px #000",
//           boxShadow: "0 0 24px rgba(0, 0, 0, 0.5)",
//           padding: "16px",
//           borderRadius: "20px",
//         }}
//       >
//         <div style={{ textAlign: "center" }}>
//           <i
//             className="bx bx-check-circle"
//             style={{ fontSize: "55px", color: "#0ec80e" }}
//           ></i>

//           {/* <LottieHelper animationData="key_card.json"/> */}
//         </div>
//         <Typography
//           id="modal-modal-description"
//           sx={{ mt: 2, textAlign: "center", fontSize: "1.8rem" }}
//         >
//           <p>test</p>
//         </Typography>
//         <div style={{ textAlign: "center" }}>
//           <div className="lds-ellipsis">
//             <div></div>
//             <div></div>
//           </div>
//         </div>
//       </Box>
//     </Modal>
//   </div>
  

//     // setErrorModalData2({
//     //   toggleError: true, // Ensure the modal is visible
//     //   modalClass: "error-message-adultcount",
//     //   errorMessage: t(
//     //     "Please proceed to the front desk for assistance."
//     //   ),
//     // });
//   };

//   const handleErrorModalClose = () => {
//     setErrorModalData2((state) => ({
//       ...state,
//       toggleError: false,
//     }));
//   };

//   return (
//     <div>
//       {/* Main Modal */}
//       <Modal open={showConfirmAdultCountAtFrontDeskModal} style={{ zIndex: "1" }}>
//         <Box
//           sx={{
//             position: "absolute",
//             top: "50%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//             width: "800px",
//             backgroundColor: "#f5f5f5",
//             border: "2px #000",
//             boxShadow: "0 0 24px rgba(0, 0, 0, 0.5)",
//             padding: "70px",
//             borderRadius: "20px",
//           }}
//         >
//           <Typography
//             id="modal-modal-description"
//             sx={{ mt: 2, textAlign: "center", fontSize: "1.8rem" }}
//           >
//             <p>{t("Would you like to change Adult Count") + "?"}</p>
//           </Typography>

//           <div style={{ textAlign: "center" }}>
//             <div style={{ marginBottom: "20px" }}>
//               <select
//                 value={adultCount} // Display the adult count
//                 disabled // Prevent changes
//                 style={{
//                   padding: "10px",
//                   width: "100px",
//                   border: isFocused
//                     ? "2px solid #007bff"
//                     : "2px solid #ced4da",
//                   backgroundColor: "#f8f9fa",
//                   outline: "none",
//                   borderRadius: "5px",
//                   transition: "border-color 0.2s ease",
//                   cursor: "not-allowed",
//                 }}
//               >
//                 <option value={adultCount}>{adultCount}</option>
//               </select>
//             </div>

//             <div style={{ marginBottom: "20px", marginTop: "50px" }}>
//               <button
//                 style={{ marginTop: "20px", padding: "15px", marginLeft: "15px" }}
//                 className="btn button-blue scan-btn"
//                 onClick={handleYesClick}
//               >
//                 {t("Yes")}
//               </button>

//               <button
//                 style={{ marginTop: "20px", padding: "15px", marginLeft: "15px" }}
//                 className="btn button-blue scan-btn"
//                 onClick={() => {
//                   console.log("********************************************No Click started");
//                   confirmAdultCount(selectedAdultCount);
//                 }}
//               >
//                 {t("No")}
//               </button>
//             </div>
//           </div>
//         </Box>
//       </Modal>

//       {/* Error Modal */}
//       <Modal open={errorModalData2.toggleError} style={{ zIndex: "2" }}>
//         <Box
//           sx={{
//             position: "absolute",
//             top: "50%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//             width: "600px",
//             backgroundColor: "#fff",
//             border: "2px solid #000",
//             boxShadow: "0 0 24px rgba(0, 0, 0, 0.5)",
//             padding: "50px",
//             borderRadius: "15px",
//           }}
//         >
//           <Typography
//             sx={{ mt: 2, textAlign: "center", fontSize: "1.5rem", color: "red" }}
//           >
//             {errorModalData2.errorMessage}
//           </Typography>
//           <div style={{ textAlign: "center", marginTop: "20px" }}>
//           <button
//               style={{ marginTop: "20px", padding: "15px", marginLeft: "15px" }}
//               className="btn button-blue scan-btn"
//               onClick={() => {
//                // closeModal(); // Close the first modal
//                navigate(HOME, { replace: true }); // Navigate to upgrade
//               }}
//             >
//               {t("OK")}
//             </button>
//           </div>
//         </Box>
//       </Modal>
//     </div>
//   );
// };

// export default ChangeAdultCountAtFrontDeskModal;











// // import React, { useState,useEffect  } from "react";
// // import Box from "@mui/material/Box";
// // import Typography from "@mui/material/Typography";
// // import Modal from "@mui/material/Modal";
// // import { getParametersFromLocalStorage } from "../../../helpers/commonHelper";
// // import { initReactI18next, useTranslation } from "react-i18next";

// // const ChangeAdultCountAtFrontDeskModal = ({
// //   showConfirmAdultCountModal,
// //   confirmAdultCount,
// //   closeModal,
// //   adultCount
// // }) => {
// //   const { t } = useTranslation();
// //   const [selectedAdultCount, setSelectedAdultCount] = useState(adultCount);
// //   const [showProceedToFrontDeskModal, setShowProceedToFrontDeskModal] = useState(false);
// //   const [isFocused, setIsFocused] = useState(false);
// //   const [loading, setLoading] = useState(false);
// //   const handleSelectChange = (event) => {
// //     const selectedValue = parseInt(event.target.value);
   
// //     setSelectedAdultCount(Math.min(selectedValue)); //modified to set it based on adultcount
// //   };
 
// //   const [errorModalData2, setErrorModalData2] = useState({
// //     toggleError: false,
// //     errorMessage: "",
// //   });
  
// //   const handleCloseProceedToFrontDeskModal = () => {
// //     setShowProceedToFrontDeskModal(false); // Close the new popup
// //   };

// //   useEffect(() => {
// //     localStorage.setItem("selectedAdultCount", selectedAdultCount.toString());
// //   }, [selectedAdultCount]);

// //   const handleYesClick = () => {
// //     console.log("********************************************handling Yes Click started");
// //     setErrorModalData2((state) => ({
// //       ...state,
// //       toggleError: true, // Ensure the modal is visible
// //       errorMessage: t(
// //         "Payment unsuccessful. Please review your payment details and try again, or proceed to the front desk for assistance."
// //       ),
// //     }));
// //   };
  
// //   return (
// //     <div>
// //       <Modal open={showConfirmAdultCountModal}  erroModalData2={erroModalData2} style={{ zIndex: "1" }}>
// //         <Box 
// //           sx={{
// //             position: "absolute",
// //             top: "50%",
// //             left: "50%",
// //             transform: "translate(-50%, -50%)",
// //             width: "800px",
// //             backgroundColor: "#f5f5f5",
// //             border: "2px #000",
// //             boxShadow: "0 0 24px rgba(0, 0, 0, 0.5)",
// //             padding: "70px",
// //             borderRadius: "20px",
// //           }}
// //         >
// //           <Typography
// //             id="modal-modal-description"
// //             sx={{ mt: 2, textAlign: "center", fontSize: "1.8rem" }}
// //           >
// //             <p>{t("Would you like to change Adult Count")+"?"}</p>
// //           </Typography>

// //           <div style={{ textAlign: "center" }}>
// //             <div style={{ marginBottom: "20px" }}>
// //                       <select
// //                   value={adultCount} // Display the adult count
// //                   disabled // Prevent changes
// //                   style={{
// //                     padding: "10px",
// //                     width: "100px",
// //                     border: isFocused ? "2px solid #007bff" : "2px solid #ced4da",
// //                     backgroundColor: "#f8f9fa", // Grayish background for disabled
// //                     outline: "none",
// //                     borderRadius: "5px",
// //                     transition: "border-color 0.2s ease",
// //                     cursor: "not-allowed", // Show disabled cursor
// //                   }}
// //                 >
// //                   <option value={adultCount}>{adultCount}</option>
// //                 </select>
// //             </div>

// //             <div style={{ marginBottom: "20px" ,  marginTop: "50px"}}>
// //                 <button
// //                 style={{ marginTop: "20px", padding: "15px", marginLeft: "15px" }}
// //                 className="btn button-blue scan-btn"
// //                 onClick={handleYesClick} // Directly call the function here
// //                 >
// //                 {t("Yes")}
// //                 </button>

// //                 <button
// //                 style={{ marginTop: "20px", padding: "15px", marginLeft: "15px" }}
// //                 className="btn button-blue scan-btn"
// //                 onClick={() => {
// //                   console.log("********************************************No Click started");
// //                   confirmAdultCount(selectedAdultCount);
// //                 }}
// //                 >
// //                 {t("No")}
// //                 </button>
// //             </div>
// //           </div>
// //         </Box>
// //       </Modal>
     
     
// //     </div>
// //   );
// // };

// // export default ChangeAdultCountAtFrontDeskModal;

import React, { useEffect, useState } from 'react';

import Lottie from 'react-lottie';

const LottieHelper = (props) => {

const [animationData, setAnimationData] = useState(null);
    // const defaultOptions = {
    //     loop: true,
    //     autoplay: true,
    //     animationData: srcImg,
    //     rendererSettings: {
    //     preserveAspectRatio: 'xMidYMid slice',
    //     },
    // };
    useEffect(() => {
        const loadAnimation = async () => {
          try {
            const response = await fetch(`/lottie/${props.animationData}`);
            const data = await response.json();
            setAnimationData(data);
          } catch (error) {
            console.error('Error loading animation data:', error);
          }
        };
    
        loadAnimation();
      }, [props.animationData]);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };

    // useEffect(() => {


    //     // You can use setIsPlaying to control the animation playback dynamically
    //     // For example, setIsPlaying(true) to play the animation or setIsPlaying(false) to pause it
    // }, []);


    return (
       <div style={{marginTop:"40px"}}>
            <Lottie 
                options={defaultOptions}
            />
        </div>
    )
}

export default LottieHelper;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import baseApi from "../../network/baseApi";
import {
  getPostalCodeDetail,
} from "../../network/apiService";

export const fetchReservationDetails = createAsyncThunk("reservationDetails/fetchReservationDetails", async (qd, token) => {

    const searchParams = new URLSearchParams(qd);
    let query = searchParams.toString();

    const response = await baseApi.post(`api/Kiosk/GetReservationDetails?${query}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
 
    return response.data;
  }
);

const initialState = {
  reservationDetailsData: { loading: false, data: {}, error: "" },
};

const reservationDetailsSlice = createSlice({
  name: "reservationDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchReservationDetails.pending, (state) => {
      state.reservationDetailsData.loading = true;
    });
    builder.addCase(fetchReservationDetails.fulfilled, (state, action) => {
      state.reservationDetailsData.loading = false;
      state.reservationDetailsData.data = action.payload;
      state.reservationDetailsData.error = "";
    });
    builder.addCase(fetchReservationDetails.rejected, (state, action) => {
      state.reservationDetailsData.loading = false;
      state.reservationDetailsData.data = [];
      state.reservationDetailsData.error = action.error.message;
    });
  },
});

export const getPostalCodeDetails = createAsyncThunk(
  "getPostalCodeDetail",
  async ({ postalCode, token }) => {
    const data = { postalCode };  // Ensure postalCode is passed correctly
    const response = await getPostalCodeDetail(data, token);  // Pass data and token
    return response.data;
  }
);
export const getReservationUserDetailsData = (state) =>
  state.reservationDetails.reservationDetailsData;

export default reservationDetailsSlice.reducer;

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";

import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoIcon from "@mui/icons-material/Info";
import Modal from "@mui/material/Modal";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const FilterPopupForm = ({ show, proceedFirstNameFilter, closeModel }) => {
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const { t } = useTranslation();

  const firstNameChangeHandler = (e) => {
    const value = e.target.value;
    setFirstName(value);
  };

  return (
    <div>
      <Modal open={show} style={{ zIndex: "1" }}>
        <Box
          sx={{
            zIndex: "1",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "600px",
            backgroundColor: "#f5f5f5",
            border: "2px #000",
            boxShadow: "0 0 24px rgba(0, 0, 0, 0.5)",
            padding: "16px",
            borderRadius: "20px",
          }}
        >
          <button className="close-icon" onClick={closeModel}>
            <CloseIcon
              style={{ float: "right", margin: "5px", color: "white" }}
              onClick={closeModel}
            />
          </button>

          <div className="flex text-center" style={{ padding: "30px" }}>
            <ErrorOutlineIcon
              style={{ color: "#e25b5b", fontSize: "60px", transform: "rotate(180deg)"}}
              onClick={closeModel}
            />
          
            <Typography
              id="modal-modal-description"
              sx={{
                mt: 2,
                textAlign: "center",
                fontSize: "1.2rem",
                marginBottom: "30px",
              }}
            >
              {t("Multiple reservations found")}
              <br />
              {t("Please validate first name to proceed")}
            </Typography>
            <hr className="dotted-line"/>
            <input
              type="text"
              name="firstName"
              value={firstName}
              onChange={firstNameChangeHandler}
              className="form-control form-control-set did-floating-input py-2 px-5 bg-[#F0F8FF] border border-black rounded-xl w-full placeholder:text-[#4278A3]"
              autoComplete="off"
              placeholder={t("Enter First Name")}
            />
            <button
              style={{ margin: "auto", width: "100%" }}
              className="btn button-blue scan-btn"
              onClick={() => {
                proceedFirstNameFilter(firstName);
              }}
            >
              {t("Proceed")}
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default FilterPopupForm;

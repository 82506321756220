import { initReactI18next, useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import { getSelectedLanguage } from "../../store/Checkin/languageSlice";
import i18n from "i18next";
import { useSelector } from "react-redux";

const Header = ({
  onClick,
  setShowModal,
  isHome,
  title,
  subTitle,
  hightlight,
}) => {
  const { t } = useTranslation();
  const selectedLanguage = useSelector(getSelectedLanguage);

  useEffect(async () => {
    i18n.changeLanguage(selectedLanguage);
  }, []);

  return (
    <>
      <>
        {isHome === true ? (
          <>
            <div
              className="row"
              style={{
                display: "inline",
                position: "relative",
                zIndex: "1",
              }}
            >
              <button onClick={onClick} className="btn home-btn">
                <i className="ri-home-8-line mr-3" style={{verticalAlign:"middle"}}></i> <span style={{verticalAlign:"middle"}}> {t("Home")}</span> 
              </button>
            </div>
          </>
        ) : (
          <div>
            <div>
              <button onClick={onClick} className="btn button-blue back-btn">
                <i className="bx bx-chevron-left"></i>
                {t("BACK")}
              </button>
            </div>
            <div>
              <button onClick={setShowModal} className="btn home-btn">
                <i className="ri-arrow-go-back-line mr-3"></i>
                {t("RESTART")}
              </button>
            </div>
          </div>
        )}
      </>
      <div
        className="flex text-center"
        style={{ marginTop: "-40px", marginBottom: "20px" }}
      >
        <div className="d-flex text-center justify-content-center">
          {hightlight === "" || hightlight === null || hightlight === undefined ? (
            <>
              <h2 className="highlight-title">{t(title)}</h2>
            </>
          ) : (
            <>
              <h4 className="highlight-title">
                {t(hightlight)}
              </h4>
              <span style={{marginLeft:"10px"}}></span>
              <h4 className="welcome-title">{t(title)}</h4>
            </>
          )}
        </div>

        <h5>{t(subTitle)}</h5>
      </div>
      {title !== "" && subTitle !== "" && <hr />}
    </>
  );
};

export default Header;

import { useEffect, useState } from "react";

import SignatureCanvas from "react-signature-canvas";
import { useTranslation } from "react-i18next";
const SignaturePad = ({
  signature,
  setSignature,
  url,
  setUrl,
  gotSignature,
  showRetryBtn = true,
}) => {
  const { t } = useTranslation();
  const [canvasWidth, setCanvasWidth] = useState(300); // Default width
  const [canvasHeight, setCanvasHeight] = useState(185); // Default height

  const clearSign = () => {
    signature.clear();
    setUrl(null);
  };

  useEffect(() => {
    const resizeHandler = () => {
      const signatureBox = document.querySelector(".signature-box");
      if (signatureBox) {
        const rect = signatureBox.getBoundingClientRect();
        setCanvasWidth(rect.width);
        setCanvasHeight(rect.height);
      }
    };

    window.addEventListener("resize", resizeHandler);
    resizeHandler();

    return () => window.removeEventListener("resize", resizeHandler);
  }, []);

  return (
    <div>
      <div>
        <div className="signature-box">
          <SignatureCanvas
            canvasProps={{ height: canvasHeight, width: canvasWidth, className: "sigCanvas", style: { marginBottom: "4px" } }}
            ref={(data) => {
              setSignature(data);
            }}
            onEnd={gotSignature}
          />
          
        </div>
        {/* <p className="sign-here">Sign Here</p> */}
      </div>
      {showRetryBtn && (
        <div className="d-flex justify-content-end" style={{ float: "right" }} >
          <button onClick={clearSign} className="btn terms-retry-btn">
            <i className="bx bx-revision"></i>{t("Retry")}
          </button>
          {/* <button onClick={gotSignature} className="btn terms-retry-btn" style={{marginLeft:"7px"}}>
            Save
          </button> */}
        </div>
      )}
      <div className="sign-here-line">
        <span>X</span>
        <span className="line"></span>
        {/* <span>Sign Here</span> */}
      </div>
    </div>
  );
};

export default SignaturePad;

/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import {
  ArrivalDateSection,
  CheckOutTimeSection,
  CountryOfResidenceSection,
  DailyRoomRateSection,
  DepartureDateSection,
  EmailSection,
  MobileNoSection,
  NameSection,
  NationalitySection,
  NightsCountSection,
  PincodeSection,
  TotalChargesSection
} from "./UserReservationFields";
import {
  addPrefixIfSingleLetter,
  formatDate,
  getParametersFromLocalStorage,
  homePageHandler,
  nameValidation,
} from "../../helpers/commonHelper";
import {
  fetchCountries,
  getCountryList,
} from "../../store/Checkin/reservationformSlice";
import { initReactI18next, useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ErrorPopup from "../LoaderAndNotification/ErrorPopup";
import Header from "../Layout/Header";
import LoaderPopup from "../LoaderAndNotification/LoaderPopup";
import PolicyDetailsModal from "../../components/Layout/Card/PolicyDetailsModal";
import SignaturePad from "../Forms/SignaturePad";
import { getSelectedLanguage } from "../../store/Checkin/languageSlice";
import { getTermsAndConditons } from "../../network/apiService";
import i18n from "i18next";

const UserReservationDetails = ({
  loading,
  loading2,
  loading3,
  erroModalData,
  erroModalData2,
  erroModalData3,
  closeErrorModal,
  closeErrorModal2,
  closeErrorModal3,
  openModal,
  reservationData,
  confirmationDetails,
  confirmationDetailsHandler,
  signature,
  setSignature,
  setUrl,
  url,
  gotSignature,
  userAgreed,
  navigateToPayment,
  handleCheckboxChange,
  clearSign,
  updateProfile,
  openCheckOutTimeModal,
  toggleCheckoutTimeModal,
  timeChanged,
  setTimeChanged,
  onChangeHandler,
  emailValidation,
  countryValidation,
  mobileNoValidation,
  enterEmailValidation,
  enterPincodeValidation,
  property,
  userData,
  countries,
  handlebackPage,
  showModalPopup,
  countryCodeAddressUpdate
}) => {
  const [termsAndConditionData, setTermsAndConditionData] = useState();
  const settings = getParametersFromLocalStorage("settings");
  const inputFieldList = getParametersFromLocalStorage("InputFieldList");
  const labelFieldList = getParametersFromLocalStorage("LabelFieldList");
  const [name, setName] = useState(false);
  const [noOfNights, setNoOfNights] = useState(false);
  const [arrivalDate, setArrivalDate] = useState(false);
  const [departureDate, setDepartureDate] = useState(false);
  const [totalCharges, setTotalCharge] = useState(false);
  const [dailyRoomRate, setDailyRoomRate] = useState(false);
  const [checkoutTime, setCheckOutTime] = useState(false);
  const [countryOfResidence, setcountryOfResidence] = useState({ status: false, isMandatory: false });
  const [email, setEmail] = useState({ status: false, isMandatory: false });
  const [nationality, setnationality] = useState({ status: false, isMandatory: false });
  const [pincode, setPincode] = useState({ status: false, isMandatory: false });
  const [mobileNo, setMobileNo] = useState({ status: false, isMandatory: false });

  const checkOutTime =
    settings?.find((setting) => setting.parameterName === "CheckOutTime")
      ?.parameterValue ?? "12:00 AM";
  const [tnCLoader, setTnCLoader] = useState(false);
  const [erroModalData4, setErrorModalData4] = useState({
    toggleError: false,
    errorMessage: "",
  });

  const [showDownloadKeyModal, setDownloadKeyModal] = useState(false);
  const token = getParametersFromLocalStorage("token");
  const [totalDue, setTotalDue] = useState(0);
  const [printRate, setPrintRate] = useState(false);

  useEffect(() => {
    setPrintRate(userData?.printRate);
  });
  useEffect(() => {
    labelFieldList.forEach(element => {
      if (element.fieldCode === "fullName") { setName(true); }
      else if (element.fieldCode === "noOfNights") { setNoOfNights(true); }
      else if (element.fieldCode === "arrivalDate") { setArrivalDate(true); }
      else if (element.fieldCode === "departureDate") { setDepartureDate(true); }
      else if (element.fieldCode === "totalCharges") { setTotalCharge(true); }
      else if (element.fieldCode === "dailyRoomRate") { setDailyRoomRate(true); }
    });

    inputFieldList.forEach(element => {
      if (element.fieldCode === "CheckOutTime") setCheckOutTime(true);
      else if (element.fieldCode === "countryOfResidence") setcountryOfResidence({ status: true, isMandatory: element.isMandatory });
      else if (element.fieldCode === "nationality") setnationality({ status: true, isMandatory: element.isMandatory });
      else if (element.fieldCode === "email") setEmail({ status: true, isMandatory: element.isMandatory });
      else if (element.fieldCode === "pincode") setPincode({ status: true, isMandatory: element.isMandatory });
      else if (element.fieldCode === "phoneNo_UAE") setMobileNo({ status: true, isMandatory: element.isMandatory });
    })

  }, []);
  useEffect(() => {
    if (reservationData !== null && reservationData !== undefined) {
      const amount = parseFloat(reservationData.totalAmountDue);

      if (amount > 0) {
        setTotalDue(amount);
      } else {
        setTotalDue(0);
      }
    }
  }, [reservationData]);
  const openDownloadKey = () => {
    setDownloadKeyModal(true);
  };

  const closeDownloadKey = () => {
    setDownloadKeyModal(false);
  };

  const doneDownloadKey = () => {
    setDownloadKeyModal(false);
  };

  const { t } = useTranslation();
  const selectedLanguage = useSelector(getSelectedLanguage);

  useEffect(async () => {
    i18n.changeLanguage(selectedLanguage);
  }, []);


  const closeErrorModal4 = () => {
    setErrorModalData4((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };

  const getTermsAndConditonsData = useCallback(async () => {
    try {
      setTnCLoader(true);
      console.log("selectedLanguage ==" + selectedLanguage);
      const response = await getTermsAndConditons(token);
      if (response.status === 200) {
        const filteredData = response.data.data.filter(
          (item) => item.language === selectedLanguage
        );
        setTermsAndConditionData(filteredData);
        setTnCLoader(false);
        return;
      }
    } catch (error) {
      setTnCLoader(false);
      // console.log(error);
      setErrorModalData4((state) => ({
        ...state,
        toggleError: true,
        errorMessage: error.message,
      }));
    }
    return;
  }, [token]);

  useEffect(() => {
    getTermsAndConditonsData();
  }, []);

  useEffect(() => {
    if (timeChanged) {
      openCheckOutTimeModal();
    }
  }, [timeChanged]);

  const [containerHeight, setContainerHeight] = useState(0);

  useEffect(() => {
    const calculateContainerHeight = () => {
      const footerHeight = document.querySelector("footer").clientHeight;
      const viewportHeight = window.innerHeight;
      setContainerHeight(`calc(${viewportHeight}px - ${footerHeight}px)`);
    };

    calculateContainerHeight();
    window.addEventListener("resize", calculateContainerHeight);

    return () => {
      window.removeEventListener("resize", calculateContainerHeight);
    };
  }, []);

  return (
    <div>
      <main>
        <LoaderPopup loading={loading} />
        <LoaderPopup loading={loading2} />
        <LoaderPopup loading={loading3} />
        <LoaderPopup loading={tnCLoader} />
        <ErrorPopup
          closeErrorModal={closeErrorModal}
          erroModalData={erroModalData}
        />
        {erroModalData?.toggleError && (
          <ErrorPopup
            closeErrorModal={closeErrorModal}
            erroModalData={erroModalData}
          />
        )}
        <ErrorPopup
          closeErrorModal={closeErrorModal2}
          erroModalData={erroModalData2}
        />
        <ErrorPopup
          closeErrorModal={closeErrorModal3}
          erroModalData={erroModalData3}
        />
        <ErrorPopup
          closeErrorModal={closeErrorModal4}
          erroModalData={erroModalData4}
        />
        {showDownloadKeyModal && (
          <PolicyDetailsModal
            closeDownloadKey={closeDownloadKey}
            doneDownloadKey={doneDownloadKey}
            policyData={
              Array.isArray(termsAndConditionData)
                ? termsAndConditionData[0]?.policyInfo
                : termsAndConditionData?.policyInfo
            }
          />
        )}

        <div
          className="main-content"
          style={{ height: containerHeight, overflowY: "auto" }}
        >
          <div class="d-flex h-100 flex-nowrap ">
            <div class="col-6  p-2  ">
              {" "}
              <div>
                <Header
                  onClick={handlebackPage}
                  setShowModal={openModal}
                  isHome={true}
                  title={""}
                  subTitle={""}
                />
              </div>
              <form className="h-70" autoComplete="off">
                <div className="d-flex flex-column" style={{ height: "100%" }}>
                  <div
                    className="mb-20"
                    style={{
                      paddingLeft: "50px",
                      paddingRight: "50px",
                      paddingTop: "20px",
                    }}
                  >
                    <div className="terms-title">
                      <h4 class="terms-title-font-size">{t("Reservation Details")}</h4>
                    </div>
                    <div className="d-flex flex-wrap reservation-detail-content">

                      {name && <div className="col-md-6"><NameSection reservationData={reservationData} /></div>}
                      {noOfNights && <div className="col-md-6"><NightsCountSection reservationData={reservationData} /></div>}
                      {arrivalDate && <div className="col-md-6"> <ArrivalDateSection reservationData={reservationData} /></div>}
                      {departureDate && <div className="col-md-6"><DepartureDateSection reservationData={reservationData} /></div>}
                      {dailyRoomRate && <div className="col-md-6"><DailyRoomRateSection reservationData={reservationData} property={property} /></div>}
                      {totalCharges && <div className="col-md-6"><TotalChargesSection property={property} totalDue={totalDue} /></div>}


                    </div>
                    <div style={{ marginTop: "20px" }}>
                      <p>
                        {Array.isArray(termsAndConditionData) ? (
                          <p>{termsAndConditionData[0]?.moreInfo}</p>
                        ) : (
                          <p>{termsAndConditionData || ""}</p>
                        )}
                      </p>
                    </div>
                    {checkOutTime && <CheckOutTimeSection checkOutTime={checkOutTime} />}
                    {countryOfResidence.status && <CountryOfResidenceSection isMandatory={countryOfResidence.isMandatory} confirmationDetails={confirmationDetails} confirmationDetailsHandler={confirmationDetailsHandler} countryValidation={countryValidation} countries={countries} showModalPopup={showModalPopup} countryCodeAddressUpdate={countryCodeAddressUpdate}/>}
                    {nationality.status && <NationalitySection isMandatory={nationality.isMandatory} confirmationDetails={confirmationDetails} confirmationDetailsHandler={confirmationDetailsHandler} countryValidation={countryValidation} countries={countries} />}
                    {email.status && <EmailSection isMandatory={email.isMandatory} confirmationDetailsHandler={confirmationDetailsHandler}
                      confirmationDetails={confirmationDetails}
                      emailValidation={emailValidation} />}
                    {mobileNo.status && <MobileNoSection isMandatory={mobileNo.isMandatory} confirmationDetailsHandler={confirmationDetailsHandler}
                      confirmationDetails={confirmationDetails}
                      mobileNoValidation={mobileNoValidation} />}
                    {pincode.status && <PincodeSection isMandatory={pincode.isMandatory} confirmationDetailsHandler={confirmationDetailsHandler}
                      confirmationDetails={confirmationDetails} enterPincodeValidation={enterPincodeValidation}/>}

                  </div>
                </div>
              </form>
            </div>
            <div class="col-6 col-left-bg">
              {" "}
              <div className="terms-condition-width">
                <div>
                  <div className="terms-title">
                    <h4 class="terms-title-font-size">{t("Terms and Conditions")}</h4>
                    <div className="terms-condition-scroll">
                      {Array.isArray(termsAndConditionData) ? (
                        termsAndConditionData?.map((ele, idx) => (
                          <p key={idx}>{ele.content}</p>
                        ))
                      ) : (
                        <p>{termsAndConditionData || ""}</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="h-100" style={{ marginTop: "30px" }}>
                  <div>
                    <div className="mt-20 d-flex justify-content-between">
                      <div className="terms-title">
                        <h4 class="terms-title-font-size">
                          {t("I accept the Terms & Conditions")} {t("and")}{" "}
                          <a onClick={openDownloadKey}>
                            <u>{t("Privacy Policy")}</u>
                          </a>
                        </h4>
                      </div>
                      <div style={{ marginRight: "5px" }}>
                        <button onClick={clearSign} className="terms-retry-btn">
                          <i className="bx bx-revision"></i>
                        </button>
                      </div>
                    </div>

                    <div className="sign-wrapper">
                      <SignaturePad
                        signature={signature}
                        setSignature={setSignature}
                        gotSignature={gotSignature}
                        setUrl={setUrl}
                        url={url}
                        showRetryBtn={false}
                      />

                      {t("I would like to receive information from hotel") === "" || t("I would like to receive information from hotel")?.length === 0 ? (
                        <> </>
                      ) : (
                        <>
                          <div className="accept-condition">
                            <input
                              type="checkbox"
                              style={{ borderColor: "green" }}
                              className="accept-check"
                              checked={userAgreed}
                              onChange={handleCheckboxChange}
                            />
                            <span>
                              {t(
                                "I would like to receive information from hotel"
                              )}
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div>
                    <div
                      className="d-flex justify-content-end"
                      style={{ marginTop: "10px" }}
                    >
                      <button
                         disabled={!url ? true : false}//proceed //ss
                        onClick={updateProfile}
                        className="btn button-blue confirm-number-btn"
                      >
                        {t("Proceed")}
                        {/* Proceed <i className="bx bx-chevron-right"></i> */}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default UserReservationDetails;

import React, { useEffect, useState } from "react";
import {
  addPrefixIfSingleLetter,
  formatDate,
  nameValidation,
} from "../../helpers/commonHelper";

import { useTranslation } from "react-i18next";
import { Clear } from "@mui/icons-material";

//export const Name = ({reservationData,isMandatory}) => (
export function NameSection({ reservationData = {} }) {
  const { t } = useTranslation();
  return (
    <div className="reservation-width ">
      <label>{t("Name")}:</label>
      {reservationData.name !== "" &&
      reservationData.name !== null &&
      reservationData.name !== undefined ? (
        <p style={{ margin: 0, whiteSpace: "nowrap" }}>
          {nameValidation(reservationData.name, undefined)}
        </p>
      ) : (
        <span>
          {nameValidation(reservationData.firstName, reservationData.lastName)}
        </span>
      )}
    </div>
  );
}

export function NightsCountSection({ reservationData }) {
  const { t } = useTranslation();

  return (
    <div className="reservation-width-right">
      <label>{t("Number of Nights")+ " :"}</label>
      <p>{addPrefixIfSingleLetter(reservationData.nights + "")}</p>
    </div>
  );
}

export function ArrivalDateSection({ isMandatory, reservationData }) {
  const { t } = useTranslation();

  return (
    <div className="reservation-width ">
      <label>{t("Arrival Date")+ " :"}</label>
      <p>{formatDate(reservationData?.arrivalDate)}</p>
    </div>
  );
}

export function DepartureDateSection({ isMandatory, reservationData }) {
  const { t } = useTranslation();
  return (
    <div className="reservation-width-right ">
      <label>{t("Departure Date")+ " :"}</label>
      <p>{formatDate(reservationData?.departureDate)}</p>
    </div>
  );
}

export function DailyRoomRateSection({
  isMandatory,
  reservationData,
  property,
}) {
  const { t } = useTranslation();

  return (
    <div className="reservation-width">
      <label>{t("Daily Room Rate")+ " :"}</label>
      <p>
        {reservationData?.printRate === false ? (
          "-"
        ) : (
          <>
            {property.currency}{" "}
            {parseFloat(reservationData?.roomRate).toFixed(2)}
          </>
        )}
      </p>
    </div>
  );
}
export function TotalChargesSection({ property, totalDue }) {
  const { t } = useTranslation();

  return (
    <div className="reservation-width-right">
      <div className="highlight">
        <label>{t("Total Charges")+ " :"}</label>
        <br />
        <strong>
          <span style={{ fontWeight: "700" }}>
            {property.currency} {parseFloat(totalDue)?.toFixed(2)}
          </span>
        </strong>
      </div>
    </div>
  );
}
export function CheckOutTimeSection({ checkOutTime }) {
  const { t } = useTranslation();

  return (
    <div className="d-flex justify-content-between communication-detail">
      <label>{t("Check-out Time")+ " :"}</label>
      <input
        type="text"
        name="checkoutTime"
        placeholder={t("Check-out Time")}
        autoComplete="off"
        value={checkOutTime}
        disabled
        className="form-control form-control-set"
      />
    </div>
  );
}
export function CountryOfResidenceSection({

  isMandatory,
  confirmationDetails = {},
  confirmationDetailsHandler,
  countryValidation,
  countries = [],
  showModalPopup,
  countryCodeAddressUpdate
}) {
  const { t } = useTranslation();
  const [showView, setShowView] = useState(false);
  useEffect(() => {
    console.log("ThreeCountryCodeForAddressUpdate: ", countryCodeAddressUpdate?.parameterValue);
    console.log("confirmationDetails?.country: ", confirmationDetails?.country);
    if (
      countryCodeAddressUpdate?.parameterValue &&
      confirmationDetails?.country &&
      confirmationDetails?.country?.includes(countryCodeAddressUpdate?.parameterValue))
    {
      setShowView(true);
    } else setShowView(false);
  }, [confirmationDetails.country]);

  useEffect(() => {
    showModalPopup(false);
  }, []);

  return (
    <div>
      <div className="d-flex justify-content-between communication-detail">
        <label>
          {t("Country of Residence"+ " :")}
          {isMandatory && <span style={{ color: "red" }}>*</span>}
        </label>
        <select
          name="country"
          value={confirmationDetails.country || ""}
          onChange={confirmationDetailsHandler}
          className={
            isMandatory && countryValidation
              ? "form-error form-control form-control-set"
              : "form-control form-control-set"
          }
          style={showView ? { alignItems: "right" } : {}}
        >
          <option value="" default>
            {t("Select")}
          </option>
          {countries?.map((country) => (
            <option key={country?.CountryCode} value={country?.CountryCode}>
              {country?.CountryName}
            </option>
          ))}
        </select>
      </div>
      {showView && (
        <div
          className="d-flex justify-content-end"
          style={{ marginTop: "10px" }}
        >
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              showModalPopup(true);
            }}
          >
            <i className="bx bx-search"></i> {t("Update Address")}
          </a>
        </div>
      )}
    </div>
  );
}
export function NationalitySection({
  isMandatory,
  confirmationDetails = {},
  confirmationDetailsHandler,
  countryValidation,
  countries = [],
}) {
  const { t } = useTranslation();

  const handleNationalityChange = (event) => {
    const selectedCountryCode = event.target.value;
    const selectedCountryName = countries.find(
      (country) => country.CountryCode === selectedCountryCode
    )?.CountryName;
    console.log(selectedCountryName);
    confirmationDetailsHandler({
      target: {
        name: "nationality",
        value: selectedCountryCode,
      },
    });
  };

  return (
    <div>
      <div className="d-flex justify-content-between communication-detail">
        <label>
          {t("Nationality")+" :"}
          {isMandatory && <span style={{ color: "red" }}>*</span>}
        </label>
        <select
          name="nationality"
          value={confirmationDetails.nationality || ""}
          onChange={handleNationalityChange}
          className={
            isMandatory && countryValidation
              ? "form-error form-control form-control-set"
              : "form-control form-control-set"
          }
        >
          <option value="" default>
            {t("Select")}
          </option>
          {countries?.map((country) => (
            <option key={country?.CountryCode} value={country?.CountryCode}>
              {country?.CountryName}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
export function EmailSection({
  isMandatory,
  confirmationDetailsHandler,
  confirmationDetails = {},
  emailValidation,
  enterEmailValidation,
}) {
  const { t } = useTranslation();
  return (
    <div className="d-flex justify-content-between communication-detail">
      <label>
        {t("Email")+" :"}
        {isMandatory && <span style={{ color: "red" }}>*</span>}
      </label>
      <input
        type="email"
        onChange={confirmationDetailsHandler}
        name="email"
        placeholder={t("Email")}
        autoComplete="off"
        value={confirmationDetails.email}
        required={isMandatory}
        className={
          isMandatory && (emailValidation === true || enterEmailValidation === true)
            ? "form-error form-control form-control-set"
            : "form-control form-control-set"
        }
      />
      
    </div>
  );
}
export function MobileNoSection({
  isMandatory,
  confirmationDetailsHandler,
  confirmationDetails = {},
  mobileNoValidation,
}) {
  const { t } = useTranslation();
// type="number" //set type="text"  if + to be in phone number foreg:+971 to be in phone number field
  return (
    <div className="communication-detail communication-detail-mobile-number">
      <div className="d-flex align-items-center justify-content-between input-container">
        <label htmlFor="myNumberInput" >
          {t("Mobile Number")+" :"}
          {isMandatory && <span style={{ color: "red" }}>*</span>}
        </label>
        <div className="mobile-number-field-with-message">
          <input
            type="text"
            id="myNumberInput"
            placeholder={t("Mobile Number")}
            onChange={confirmationDetailsHandler}
            autoComplete="off"
            className={
              isMandatory && mobileNoValidation
                ? "form-error form-control form-control-set"
                : "form-control form-control-set"
            }
            name="mobileNo"
            value={confirmationDetails.mobileNo}
          />
        </div>
      </div>
    </div>
  );
}
export function PincodeSection({
  isMandatory,
  confirmationDetailsHandler,
  confirmationDetails = {},
  enterPincodeValidation,
}) {
  const { t } = useTranslation();

  console.log("isMandatory: ", isMandatory);
  console.log("enterPincodeValidation: ", enterPincodeValidation);
  return (
    <div className="d-flex align-items-center justify-content-between communication-detail">
      <label>
        {t("Pincode")+" :"}
        {isMandatory && <span style={{ color: "red" }}>*</span>}
      </label>
      <input
        type="text"
        onChange={confirmationDetailsHandler}
        name="pincode"
        placeholder={t("Pincode")}
        autoComplete="off"
        value={confirmationDetails.pincode}
        required={isMandatory}
        className={
          isMandatory && enterPincodeValidation
            ? "form-error form-control form-control-set"
            : "form-control form-control-set"
        }
      />
    </div>
  );
}

export function PopupPincodeSection({
  isMandatory,
  addressChangeHandler,
  addressData,
  pincodeValidation,
  searchPinCode,
  isDisable,
  clearData,
  confirmationDetails,
  isClear,
}) {
  const { t } = useTranslation();

  return (
    <div>
      <div className="d-flex justify-content-between communication-detail">
        <label>
          {t("Pincode")+" :"}
          {isMandatory && <span style={{ color: "red" }}>*</span>}
        </label>
        <input
          type="text"
          onChange={addressChangeHandler}
          name="pincode"
          placeholder={t("Pincode")}
          autoComplete="off"
          value={addressData?.pincode}
          required={isMandatory}
          disabled={isDisable}
          className={
            isMandatory && pincodeValidation
              ? "form-error form-control form-control-set"
              : "form-control form-control-set"
          }
          style={{ textAlign: "right", width: "200px" }}
        />
      </div>

      <div
        className="d-flex justify-content-end"
        style={{ alignItems: "flex-end" }}
      >
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            isClear ? clearData() : searchPinCode();
          }}
        >
          <i className={`bx ${isClear ? "" : "bx-search"}`}></i>
          {t(isClear ? "Clear" : "Search")}
        </a>
      </div>
    </div>
    /* {!isDisable && (
        <button className="btn button-blue" type="button" onClick={searchPinCode} style={{ width: "12%", fontSize: "14px" }}>
          {t("Search")}
        </button>
      )}
  
      {isDisable && (
        <button className="btn button-blue" type="button" onClick={clearData} style={{ width: "12%", fontSize: "14px" }}>
          {t("Clear")}
        </button>
      )} */
  );
}
export function PopupStateSection({
  isMandatory,
  addressChangeHandler,
  addressData = {},
  pincodeValidation,
  confirmationDetails,
  isDisable
}) {
  const { t } = useTranslation();
  return (
    <div className="d-flex justify-content-between communication-detail">
      <label>
        {t("State")+" :"}
        {isMandatory && <span style={{ color: "red" }}>*</span>}
      </label>
      <input
        type="text"
        onChange={addressChangeHandler}
        name="state"
        placeholder={t("State")}
        autoComplete="off"
        value={addressData?.state}
        required={isMandatory}
        disabled={isDisable}
        className={
          isMandatory && pincodeValidation
            ? "form-error form-control form-control-set"
            : "form-control form-control-set"
        }
        // readOnly
        // disabled
      />
    </div>
  );
}
export function PopupNeighborhoodSection({
  isMandatory,
  addressChangeHandler,
  addressData = {},
  pincodeValidation,
  confirmationDetails,
}) {
  const { t } = useTranslation();
  return (
    <div className="d-flex justify-content-between communication-detail">
      <label>
        {t("Neighborhood")+" :"}
        {isMandatory && <span style={{ color: "red" }}>*</span>}
      </label>
      <input
        type="text"
        onChange={addressChangeHandler}
        name="neighborhood"
        placeholder={t("Neighborhood")}
        autoComplete="off"
        value={addressData?.neighborhood}
        required={isMandatory}
        className={
          isMandatory && pincodeValidation
            ? "form-error form-control form-control-set"
            : "form-control form-control-set"
        }
      />
    </div>
  );
}
export function PopupComplementSection({
  isMandatory,
  addressChangeHandler,
  addressData = {},
  pincodeValidation,
  isDisable,
  confirmationDetails,
}) {
  const { t } = useTranslation();
  return (
    <div className="d-flex justify-content-between communication-detail">
      <label>
        {t("Complement")+" :"}
        {isMandatory && <span style={{ color: "red" }}>*</span>}
      </label>
      <input
        type="text"
        onChange={addressChangeHandler}
        name="complement"
        placeholder={t("Complement")}
        autoComplete="off"
        value={addressData?.complement}
        required={isMandatory}
        disabled={isDisable}
        className={
          isMandatory && pincodeValidation
            ? "form-error form-control form-control-set"
            : "form-control form-control-set"
        }
      />
    </div>
  );
}
export function PopupCitySection({
  isMandatory,
  addressChangeHandler,
  addressData = {},
  pincodeValidation,
  confirmationDetails,
  isDisable
}) {
  const { t } = useTranslation();
  return (
    <div className="d-flex justify-content-between communication-detail">
      <label>
        {t("City")}
        {isMandatory && <span style={{ color: "red" }}>*</span>}
      </label>
      <input
        type="text"
        onChange={addressChangeHandler}
        name="city"
        placeholder={t("City")}
        autoComplete="off"
        value={addressData?.city}
        required={isMandatory}
        disabled={isDisable}
        className={
          isMandatory && pincodeValidation
            ? "form-error form-control form-control-set"
            : "form-control form-control-set"
        }
        // readOnly
        // disabled
      />
    </div>
  );
}
export function PopupStreetSection({
  isMandatory,
  addressChangeHandler,
  addressData = {},
  pincodeValidation,
  isDisable,
  confirmationDetails,
  isClear,
  searchPinCode
  
}) {
  const { t } = useTranslation();
  return (
    <div className="d-flex justify-content-between communication-detail">
      <label>
        {t("Street")+" :"}
        {isMandatory && <span style={{ color: "red" }}>*</span>}
      </label>
      <input
        type="text"
        onChange={addressChangeHandler}
      
        onClick={(e) => {
          e.preventDefault();
          if (!isClear) {
            searchPinCode();
          }
        }}
        name="street"
        placeholder={t("Street")}
        autoComplete="off"
        value={addressData?.street}
        required={isMandatory}
        disabled={isDisable}
        className={
          isMandatory && pincodeValidation
            ? "form-error form-control form-control-set"
            : "form-control form-control-set"
        }
      />
    </div>
  );
}
export function PopupNumberofResidenceSection({
  isMandatory,
  addressChangeHandler,
  pincodeValidation,
  confirmationDetails,
  addressData,
}) {
  const { t } = useTranslation();
  return (
    <div className="d-flex justify-content-between communication-detail">
      <label>
        {t("NumberofResidence")+" :"}
        {isMandatory && <span style={{ color: "red" }}>*</span>}
      </label>
      <input
        type="text"
        onChange={addressChangeHandler}
        name="numberofResidence"
        placeholder={t("NumberofResidence")}
        autoComplete="off"
        value={addressData?.numberofResidence}
        required={isMandatory}
        className={
          isMandatory && pincodeValidation
            ? "form-error form-control form-control-set"
            : "form-control form-control-set"
        }
      />
    </div>
  );
}

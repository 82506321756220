import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getNonRegisteredKiosks, registerDevice, verifyActivation } from "../network/apiService";

const initialState = {
    activationData: { loading: false, data: null, error: "" },
};


export const getNonRegisterdKiosksCall = createAsyncThunk(
  "getNonRegisterdKiosks",
  async () => {
    try {
      var response = await getNonRegisteredKiosks();
      
      return response.data;
    } catch (error) {
      console.error("Error:", error?.error);
      throw error;
    }
  }
);



export const verifyKioskActivation = createAsyncThunk(
  "verifyKioskActivation",
  async ({propertyCode, kioskID}) => {
    try {
      
      var response = await verifyActivation(propertyCode, kioskID);
      
      return response.data;
    } catch (error) {
      console.error("Error:", error?.error);
      throw error;
    }
  }
);



export const registerDeviceCall = createAsyncThunk(
  "registerDevice",
  async ({propertyCode, kioskID}) => {
    try {
      
      var response = await registerDevice(propertyCode, kioskID);
      
      return response.data;
    } catch (error) {
      console.error("Error:", error?.error);
      throw error;
    }
  }
);

const activationSlice = createSlice({
    name: "activation",
    initialState,
    reducers: {
      resetActivationData: (state) => {
         
      },
    },
    extraReducer: (builder) => {
      
    }
});

export default activationSlice.reducer;
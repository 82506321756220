/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { initReactI18next, useTranslation } from "react-i18next";

import ErrorPopup from "../LoaderAndNotification/ErrorPopup";
import Header from "../Layout/Header";
import LoaderPopup from "../LoaderAndNotification/LoaderPopup";
import LottieHelper from "../Layout/LottieHelper";
import PaymentModal from "../Layout/Card/PaymentModal";
import { STEP_MASTER_ENUM } from "../../constants/constant";
import SubHeader from "../Layout/SubHeader";
import SuccessPopup from "../LoaderAndNotification/SuccessPopup";
import creditCardScan from "../../assets/images/CreditCardScan.gif";
import { getModuleConfiguration } from "../../store/Checkin/moduleConfigurationSlice";
import { getSelectedLanguage } from "../../store/Checkin/languageSlice";
import i18n from "i18next";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const Payment = ({
  loading,
  loading2,
  loading3,
  userData,
  erroModalData,
  erroModalData2,
  erroModalData3,
  erroModalData4,
  generateToken,
  closeErrorModal,
  closeErrorModal2,
  closeErrorModal3,
  closeErrorModal4,
  paymentToggle,
  setPaymentToggle,
  openModal,
  navigateUrl,
  paymentTypeHandler,
  userCheckIn,
  successLoader,
  successMessage,
  property,
  totalDue,
  onBackPressed,
}) => {
  const { t } = useTranslation();
  const selectedLanguage = useSelector(getSelectedLanguage);
  const stepMaster = useSelector((state) => getModuleConfiguration(state));
  const paymentPage =
    stepMaster && stepMaster.length > 0
      ? stepMaster.find((ele) => ele.stepCode === STEP_MASTER_ENUM.PAYMENT_PAGE)
      : null;
  const paymentPageCheckout =
    stepMaster && stepMaster.length > 0
      ? stepMaster.find((ele) => ele.stepCode === STEP_MASTER_ENUM.CHECKOUT_PAYMENT_PAGE)
      : null;

  useEffect(async () => {
    i18n.changeLanguage(selectedLanguage);
  }, []);

  return (
    <div>
      <main>
        <SuccessPopup loading={successLoader} successMessage={successMessage} />
        <LoaderPopup loading={loading} />
        {paymentToggle === false && <LoaderPopup loading={loading2} />}

        <ErrorPopup
          erroModalData={erroModalData}
          closeErrorModal={closeErrorModal}
        />
        <ErrorPopup
          erroModalData={erroModalData2}
          closeErrorModal={closeErrorModal2}
        />
        <ErrorPopup
          erroModalData={erroModalData3}
          closeErrorModal={closeErrorModal3}
        />

        <ErrorPopup
          erroModalData={erroModalData4}
          closeErrorModal={closeErrorModal4}
        />

        <div className="main-content">
          <div className="d-flex  h-100 flex-row">
            <div className="col-5 flex-fill">
              <SubHeader
                onClick={onBackPressed}
                setShowModal={openModal}
                isHome={true}
                title={""}
                subTitle={""}
              />
              <div
                className="justify-content-center"
                style={{ marginTop: "10%" }}
              >
                <div className="p-2">
                  <div className="scan-text">
                    {totalDue > 0 && (
                      <h3 className="text-center font-18 font-weight-700">
                        {/* Make the Payment */}
                        {t("It's time to process your Payment")}
                      </h3>
                    )}
                  </div>
                  <div>
                    <br />
                    <br />
                  </div>

                  {loading ? (
                    <></>
                  ) : (
                    <>
                      <div
                        className="p-2"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div
                          className="highlight mt-30"
                          style={{ minWidth: "350px" }}
                        >
                          <h5 className="font-weight-600 text-center font-amt">
                            {t("Total Amount Due:")}
                          </h5>
                          <div className="text-center tot-amt font-weight-600 text-center font-amt">
                            <p>
                              {property.currency}{" "}
                              {parseFloat(totalDue ?? 0).toFixed(2)}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="scan-text">
                        {totalDue > 0 && (
                          <h6 className="text-center font-14 font-weight-100">
                           {t("Please tap Pay and follow the instructions on the")}
                           {t("Payment reader")}
                          </h6>
                        )}
                      </div>
                      <div className="text-center mt-30">
                        {totalDue ? (
                          <button
                            onClick={generateToken}
                            className="btn button-blue proceed-btn"
                          >
                            {t("PAY")}
                          </button>
                        ) : (
                          <button
                            onClick={userCheckIn}
                            style={{
                              backgroundColor: "#03355c",
                              width: "166px",
                            }}
                            className="btn button-blue proceed-btn"
                          >
                            {t("Continue")}
                          </button>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-7 flex-fill">
              {/* <div className="process-payment-img">
                <img src={creditCardScan} />
              </div> */}
              {paymentPage?.stepImage?.includes(".json") ? (
                <>
                  <LottieHelper animationData="payment_terminal.json" />
                </>
              ) : (
                <>
                  <img
                    src={paymentPage?.stepImage}
                    alt=""
                    style={{ backgroundSize: "cover", width:"100%" }}
                  />
                </>
              )}
              {paymentPageCheckout?.stepImage?.includes(".json") ? (
                <>
                  <LottieHelper animationData="payment_terminal.json" />
                </>
              ) : (
                <>
                  <img
                    src={paymentPageCheckout?.stepImage}
                    alt=""
                    style={{ backgroundSize: "cover", width:"100%" }}
                  />
                </>
              )}
            </div>
          </div>
        </div>

        {paymentToggle && (
          <PaymentModal
            setPaymentToggle={setPaymentToggle}
            loading2={loading2}
            loading3={loading3}
            erroModalData2={erroModalData2}
            erroModalData3={erroModalData3}
            erroModalData4={erroModalData4}
            closeErrorModal2={closeErrorModal2}
            closeErrorModal3={closeErrorModal3}
            closeErrorModal4={closeErrorModal4}
            paymentTypeHandler={paymentTypeHandler}
            totalAmount={totalDue}
          />
        )}
      </main>
    </div>
  );
};

export default Payment;

/* eslint-disable jsx-a11y/alt-text */
import "../../assets/stylesheet/change-homepage.css";
import { CHECKIN, CHECKOUT, KEYPAGE } from "../../routes/Paths";
import { CONFIG_MASTER_ENUM, MODULE_MASTER_ENUM } from "../../constants/constant";
import { Hidden, colors } from "@mui/material";
import React, { Suspense } from "react";
import {
  fetchModulesConfiguration,
  getMainModuleConfiguration,
  getModuleConfiguration,
  moduleConfigurationInfo,
} from "../../store/Checkin/moduleConfigurationSlice";
import {
  generateTokenApi,
  getAllowedDocuments,
  getStepWithModules,
} from "../../network/apiService";
import { getConfigurationCode, getModuleStatus, getParametersFromLocalStorage, getStepMaster } from "../../helpers/commonHelper";
import {
  getSelectedLanguage,
  setSelectedLanguage,
} from "../../store/Checkin/languageSlice";
import {
  homePageHandler,
  saveParametersIntoLocalStorage,
} from "../../helpers/commonHelper";
import { initReactI18next, useTranslation } from "react-i18next";
import {
  resetScanDocumentGuestCount,
  setDocumentInfo,
} from "../../store/Checkin/Scan/documentScanSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Backend from "i18next-http-backend";
import Chip from '@mui/material/Chip';
import ErrorPopup from "../../components/LoaderAndNotification/ErrorPopup";
import LanguageDetector from "i18next-browser-languagedetector";
import LoaderPopup from "../../components/LoaderAndNotification/LoaderPopup";
import { STEP_MASTER_ENUM } from "../../constants/constant";
import { Visibility } from "@mui/icons-material";
import i18n from "i18next";

// import logo from "../../assets/images/rotana-logo.PNG";

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
  returnEmptyString: true,
  debug: true,
  fallbackLng: "en",
});

const HomePage = ({ type, currentStep }) => {
 
  const { t } = useTranslation();
  const selectedLanguage = useSelector(getSelectedLanguage);
  const [loading, setLoading] = useState(false);
  const [erroModalData, setErrorModalData] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const languages = getParametersFromLocalStorage("Languages");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [stepType, setStepType] = useState("");
  const stepMaster = useSelector((state) => getMainModuleConfiguration(state));

  const stepMasterHome = getStepMaster(stepMaster, currentStep);
  const modules = stepMasterHome?.modules;

  const _checkinModule = getModuleStatus(modules, MODULE_MASTER_ENUM.CHECKIN);
  const _checkoutModule = getModuleStatus(modules, MODULE_MASTER_ENUM.CHECKOUT);
  const _keyModule = getModuleStatus(modules, MODULE_MASTER_ENUM.KEY);

  const [checkinModule, setcheckinModule] = useState(_checkinModule);
  const stepHomePage = getStepMaster(
    stepMaster,
    STEP_MASTER_ENUM.HOME_PAGE
  );
  const configs = stepHomePage?.configs;
  const changeHomepage = getConfigurationCode(//ss
    configs,
    CONFIG_MASTER_ENUM.CHANGE_HOMEPAGE
  );
  const languageRequired = getConfigurationCode(
    configs,
    CONFIG_MASTER_ENUM.LANGUAGE
  );
  const [isMaintanance, setIsMaintanance] = useState(false);
  const deviceDetails = getParametersFromLocalStorage("DeviceInfo");
  const [isActive, setIsActive] = useState(false);
  const [selectedLanguageCode, setSelectedLanguageCode] = useState(false);
  //const [changeHomepage, setchangeHomepage] = useState(false);


  useEffect(() => {
    const maintananceMode = deviceDetails
      ? deviceDetails.find((ele) => ele?.settingsName === "MaintananceMode")
      : null;

    if (maintananceMode?.settingsValue === "True") {
      setIsMaintanance(true);
    } else {
      setIsMaintanance(false);
    }
  }, [deviceDetails]);

  useEffect(() => {
    const lng = localStorage.getItem("defaultLanguage") ?? 'en';
    i18n.changeLanguage(lng);
    dispatch(setSelectedLanguage(lng));
    setSelectedLanguageCode(lng);
  }, []);

  useEffect(async () => {
    if (stepMaster === null) {
      const token = getParametersFromLocalStorage("token");
      const getStepsWithModules = await getStepWithModules(
        {
          IsStepEnabled: true,
          IsModuleEnabled: true,
          imageSpecified: true,
          Apptype: "kiosk",
          StepType: "",
        },
        token
      );
      if (
        getStepsWithModules.status === 200 &&
        getStepsWithModules.data.result
      ) {
        dispatch(moduleConfigurationInfo(getStepsWithModules?.data?.data)); //moduleConfigurationInfo
      }
    }
  }, [stepMaster]);

  useEffect(() => {
    if (currentStep) {
      getCurrentStep();
    }
  }, [currentStep]);

  const getCurrentStep = () => {
    const documentScan = getStepMaster(stepMaster, currentStep);
    return documentScan;
  };

  useEffect(() => {
    setcheckinModule(_checkinModule);
  }, [_checkinModule]);

  const [checkoutModule, setcheckoutModule] = useState(_checkoutModule);

  useEffect(() => {
    setcheckoutModule(_checkoutModule);
  });

  const [keyModule, setkeyModule] = useState(_keyModule);
  useEffect(() => {
    setkeyModule(_keyModule);
  });

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    dispatch(setSelectedLanguage(lng));
    setIsActive(true);
    setSelectedLanguageCode(lng);
  };

  useEffect(() => {
    setcheckinModule(checkinModule);
    setcheckoutModule(checkoutModule);
    setkeyModule(keyModule);
  }, []);

  useEffect(() => {
    homePageHandler(dispatch, null);
  }, []);

  const closeErrorModal = () => {
    setErrorModalData((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };

  const generateToken = async (navigateUrl) => {
    try {
      setLoading(true);
      dispatch(resetScanDocumentGuestCount());

      const response = await generateTokenApi({
        userName: JSON.parse(localStorage.getItem("username")),
        password: JSON.parse(localStorage.getItem("password")),
        kioskId: JSON.parse(localStorage.getItem("Kiosk"))
      });
      if (response.status === 200) {
        const data = response.data || {};
        saveParametersIntoLocalStorage("token", data?.token);
        const docTypes = await getAllowedDocuments(
          {
            appType: "kiosk",
          },
          data?.token
        );

        if (docTypes?.data?.result) {
          dispatch(setDocumentInfo(docTypes?.data?.data));
        }

        if (navigateUrl == CHECKIN) {
          dispatch(
            fetchModulesConfiguration({
              IsStepEnabled: true,
              IsModuleEnabled: true,
              imageSpecified: true,
              Apptype: "kiosk",
              StepType: "checkin",
              token: data?.token,
            })
          );
          setStepType("checkin");
        } else if (navigateUrl == CHECKOUT) {
          dispatch(
            fetchModulesConfiguration({
              IsStepEnabled: true,
              IsModuleEnabled: true,
              imageSpecified: true,
              Apptype: "kiosk",
              StepType: "checkout",
              token: data?.token,
            })
          );
          setStepType("checkout");
        } else if (navigateUrl == KEYPAGE) {
          dispatch(
            fetchModulesConfiguration({
              IsStepEnabled: true,
              IsModuleEnabled: true,
              imageSpecified: true,
              Apptype: "kiosk",
              StepType: "key",
              token: data?.token,
            })
          );
          setStepType("key");
        }
        setLoading(false);
        navigate(navigateUrl, { replace: true });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setErrorModalData((state) => ({
        ...state,
        toggleError: true,
        errorMessage: error.message,
      }));
      return;
    }
    return;
  };

  return (
    
    <div>
     
      <section className="section-bg" style={{ display: !isMaintanance ? 'block' : 'none' }}>
      {
        changeHomepage === true? (
       
        <>
        <div className="container-fluid">
          <div className="">
            <div className="row">
              <div className="col-sm-6 pl-0 pr-0">
                <div className="h-100 relative">
                  <div className="vh-100">
                    {/* <div className="content-center"> */}
                    <div className="content-center mt-50">
                      <div >
                        <div className="logo-img text-center ">
                          <img id="logoImg" />
                        </div>
                      </div>

                      <div className="mt-50">
                        <div>
                          {/* <h2 className="welcome-title" >
                            {t("Welcome")}
                            <p>
                              {t("Property")
                                .split("\n")
                                .map((line, index) => (
                                  <React.Fragment key={index}>
                                    {line}
                                  </React.Fragment>
                                ))}
                            </p>
                          </h2> */}
                          <p className="text-center  yotel-welcome-subtitle">
                            {t("Please select an option")} {t("to begin")}
                          </p>
                        </div>

                        <div className="text-center mt-30">
                          <div className="mt-30">
                            {checkinModule ? (
                              <>
                                <button
                                  onClick={() => {
                                    generateToken(CHECKIN);
                                  }}
                                  className="btn btn-lg button-blue yotel-check-in-btn"
                                >
                                  {t("CHECK - IN")}
                                </button>
                              </>
                            ) : null}
                          </div>

                          <div >
                            {checkoutModule ? (
                              <>
                                <button
                                  onClick={() => {
                                    generateToken(CHECKOUT);
                                  }}
                                  className="btn btn-lg button-blue yotel-check-in-btn"
                                >
                                  {t("CHECK - OUT")}
                                </button>
                              </>
                            ) : null}
                          </div>

                          <div className="mt-15">
                            {keyModule ? (
                              <>
                               
                                <button
                                  onClick={() => {
                                    generateToken("/key-page");
                                  }}
                                  className="btn btn-lg button-blue yotel-check-in-btn"
                                >
                                  {t("KEY")}
                                </button>
                              </>
                            ) : null}
                          </div>
                        </div>

                        {
                          languageRequired === true &&
                          <>
                            <div className="flex text-center ">
                              <div className="mt-90" >
                                <p className="text-center  yotel-welcome-subtitle">
                                  {t("Choose the language")}:
                                </p>
                              </div>
                              {/* <br /> */}
                              
                              <div>
                                <select className="yotel-custom-select" onChange={(e) => changeLanguage(e.target.value)} value={i18n.language}>
                                  {languages.map(language => (
                                    <option key={language.languageMasterID} value={language.languageCode}>
                                      {language.languageName}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div>
                              </div>
                            </div>
                          </>
                        }

                      </div>
                    </div>
                  </div>
                  <LoaderPopup loading={loading} />
                  <ErrorPopup
                    closeErrorModal={closeErrorModal}
                    erroModalData={erroModalData}
                  />
                  <Suspense fallback={<div>loading</div>}></Suspense>
                </div>
              </div>

              <div className="col-sm-6 pr-0"></div>
            </div>
          </div>
        </div>
        </>
        ):(
     
    
    
        // changeHomepage === null &&
        <>
         <div className="container-fluid">
          <div className="">
            <div className="row">
              <div className="col-sm-6 pl-0 pr-0">
                <div className="h-100 relative">
                  <div className="vh-100">
                    <div className="content-center">
                      <div>
                        <div className="logo-img text-center">
                          <img id="logoImg" />
                        </div>
                      </div>

                      <div className="mt-50">
                        <div>
                          <h2 className="welcome-title">
                            {t("Welcome")}
                            <p>
                              {t("Property")
                                .split("\n")
                                .map((line, index) => (
                                  <React.Fragment key={index}>
                                    {line}
                                  </React.Fragment>
                                ))}
                            </p>
                          </h2>
                          <p className="text-center font-14 welcome-subtitle">
                            {t("Please select an option")} {t("to begin")}
                          </p>
                        </div>

                        <div className="text-center mt-30">
                          <div>
                            {checkinModule ? (
                              <>
                                <button
                                  onClick={() => {
                                    generateToken(CHECKIN);
                                  }}
                                  className="btn btn-lg button-blue check-in-btn"
                                >
                                  {t("CHECK - IN")}
                                </button>
                              </>
                            ) : null}
                          </div>

                          <div className="mt-30">
                            {checkoutModule ? (
                              <>
                                <button
                                  onClick={() => {
                                    generateToken(CHECKOUT);
                                  }}
                                  className="btn btn-lg button-blue check-in-btn"
                                >
                                  {t("CHECK - OUT")}
                                </button>
                              </>
                            ) : null}
                          </div>

                          <div className="mt-90">
                            {keyModule ? (
                              <>
                                <p className="text-center font-12">
                                  {t("Did you lose your key?")}
                                </p>
                                <button
                                  onClick={() => {
                                    generateToken("/key-page");
                                  }}
                                  className="btn btn-lg button-blue-outline check-in-btn"
                                >
                                  {t("KEY")}
                                </button>
                              </>
                            ) : null}
                          </div>
                        </div>

                        {
                          languageRequired === true &&
                          <>
                            <div
                              className="flex text-center "
                            >
                              <div className="mt-90" >
                                <p className="text-center font-12 welcome-subtitle">
                                  {t("Choose the language")}:
                                </p>
                              </div>
                              <br />
                              {/* <div className="chip-container ">
                                {languages.slice(0,3).map((lang, index) => (
                                  <Chip  
                                  // className="custom-chip"
                                  className={`custom-chip ${selectedLanguageCode === lang.languageCode ? 'active' : ''}`}
                                    key={index}
                                    label={lang.languageName}
                                    onClick={() => changeLanguage(lang.languageCode)} // 
                                  />
                                ))}
                              </div> */}
                              
                              {/* <div className="chip-container ">
                                {languages.slice(3,6).map((lang, index) => (
                                  <Chip  
                                  // className="custom-chip"
                                  className={`custom-chip ${selectedLanguageCode === lang.languageCode ? 'active' : ''}`}
                                    key={index}
                                    label={lang.languageName}
                                    onClick={() => changeLanguage(lang.languageCode)} // 
                                  />
                                ))}
                              </div> */}
                              <div>
                                <select className="custom-select" onChange={(e) => changeLanguage(e.target.value)} value={i18n.language}>
                                  {languages.map(language => (
                                    <option key={language.languageMasterID} value={language.languageCode}>
                                      {language.languageName}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div>
                              </div>
                            </div>
                          </>
                        }

                      </div>
                    </div>
                  </div>
                  <LoaderPopup loading={loading} />
                  <ErrorPopup
                    closeErrorModal={closeErrorModal}
                    erroModalData={erroModalData}
                  />
                  <Suspense fallback={<div>loading</div>}></Suspense>
                </div>
              </div>

              <div className="col-sm-6 pr-0"></div>
            </div>
          </div>
        </div>
        </>
     
    )}
      </section>
      <section className="section-bg2"  style={{ display: isMaintanance ? 'block' : 'none' }}>
        <div>
          <div >
            <img id="staticImage" />
          </div>
        </div>
      </section>
    </div>

                     

  );
};

export default HomePage;

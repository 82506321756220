import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { currentStep, pushAuditLog, pushStageDetails } from "../network/apiService";

export const fetchCurrentStep = createAsyncThunk(
  "fetchCurrentStep",
  async ({ confirmationNo, propertyCode, token }) => {
    const response = await currentStep(
      {
        ConfirmationNo: confirmationNo,
        type: "checkin",
        PropertyCode: propertyCode,
      },
      token
    );
    return response.data;
  }
);

export const fetchStep = createAsyncThunk(
  "stage/verifyStep",
  async (payload) => {
  
    let confirmationNo = payload.confirmationNo;
    let propertyCode = payload.propertyCode;
    let type = payload.type;
    let token = payload.token;
    let stepCode = payload.stepCode;
    let currentProfilePms = payload.profile;
    //let guestCount = payload.guestCount;

    const response = await currentStep(
      {
        ConfirmationNo: confirmationNo,
        type: type,
        PropertyCode: propertyCode,
      },
      token
    );
    if (response.status === 200 && response.data.result) {      
      const stepHistory = response.data.data.reservationStageHistory;
      const skippedStep = stepHistory.find(
        (ele) => ele.stepCode === stepCode
      );

      if (skippedStep !== null && skippedStep?.isSkipped && stepCode !== "SEI02") {
        //dispatch(stageSlice.actions.setIsSkipped(true));
        return true;
      } 
      if (skippedStep !== null && skippedStep?.isComplete && stepCode !== "SEI02") {
        //dispatch(stageSlice.actions.setIsSkipped(true));
        return true;
      }
      else {
        if (currentProfilePms === null || currentProfilePms === undefined) {
          //dispatch(stageSlice.actions.setIsSkipped(false));
          return false;
        }
        const profileDocUploaded = response.data.data.documentUploadStatus;
        const skippedProfile = profileDocUploaded.length
          ? profileDocUploaded.find(
              (ele) => ele.PMSProfileID === currentProfilePms.pmsProfileID
            )
          : profileDocUploaded;

        if (
          skippedProfile !== null &&
          skippedProfile !== undefined &&
          skippedProfile?.IsUploaded === 1
        ) {
          //dispatch(stageSlice.actions.setIsSkipped(true));
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }
);
export const postAuditLog = createAsyncThunk(
  "pushAuditLog",
  async ({ data, token }) => {
    const response = await pushAuditLog(     
      token,
      data
    );
    return response.data;
  }
);


export const postStageDetails = createAsyncThunk(
  "postStageDetails",
  async ({ data, token }) => {
    const response = await pushStageDetails(     
      token,
      data
    );
    return response.data;
  }
);
const initialState = {
  isSkipped: null,
  skippedProfile: null,
  loading: null,
  error: null,
};

const stageSlice = createSlice({
  name: "stage",
  initialState,
  reducers: {
    setIsSkipped: (state, action) => {
      state.isSkipped = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStep.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchStep.fulfilled, (state, action) => {
      state.loading = false;
      state.isSkipped = action.payload;
    });
    builder.addCase(fetchStep.rejected, (state, action) => {
      state.loading = false;
      state.isSkipped = false;
    });
  },
});

export const getIsSkipped = (state) => state.stage.isSkipped;
export const getSkippedProfiles = (state) => state.stage.skippedProfile;

export const { setIsSkipped } = stageSlice.actions;

export default stageSlice.reducer;

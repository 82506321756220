import {
  addPrefixIfSingleLetter,
  capitalizeFullName,
  formatDate,
  nameValidation,
} from "../../helpers/commonHelper";
import { initReactI18next, useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";

import { TitleCaseConverter } from "../Plugins/TitleCaseCoverter";
import {
  getParametersFromLocalStorage,
} from "../../helpers/commonHelper";
import { getSelectedLanguage } from "../../store/Checkin/languageSlice";
import i18n from "i18next";
import moment from "moment";
import { useSelector } from "react-redux";
import { RoomType,
  ArrivalDate,
DepartureDate,NoOfNights,
AdultCount,
ChildCount,
Name } from "./ReservationFormFields";
const components ={
  RoomType,
  ArrivalDate,
DepartureDate,
NoOfNights,
AdultCount,
ChildCount,
Name
}
const ReservationFormTemplate = ({
  reservationData,
  multipleUserData,
  propertyDetails
}) => {
  const { t } = useTranslation();
  const settings = getParametersFromLocalStorage("settings");
  const selectedLanguage = useSelector(getSelectedLanguage);
  const listingList = getParametersFromLocalStorage("ListingList");
  const hideChildCount = settings?.find(
    (setting) =>
      setting.parameterName === "HideChildCount" &&
      setting.parameterValue?.toLowerCase() === "true"
     
  );

  useEffect(async () => {
    i18n.changeLanguage(selectedLanguage); 
  }, []);
  const updateMappingsBasedOnFieldCode = (mappings) => {
    return mappings.map(mapping => {
      switch (mapping.fieldCode) {
          case 'roomType':
            return {
              ...mapping,
              component: 'RoomType',
              reservationData: reservationData
              
            };
          case 'arrivalDate':
            return {
              ...mapping,
              component: 'ArrivalDate',
              reservationData: reservationData
              
            };
          case 'departureDate':
            return {
              ...mapping,
              component: 'DepartureDate',
              reservationData: reservationData
              
            };
          case 'noOfNights':
            return {
              ...mapping,
              component: 'NoOfNights',
              reservationData: reservationData
              
            };
          case 'name':
            return {
              ...mapping,
              component: 'Name',
              reservationData: reservationData
              
            };
          case 'adultCount':
            return {
              ...mapping,
              component: 'AdultCount',
              reservationData: reservationData
              
            };
          case 'childCount':
            return {
              ...mapping,
              component: 'ChildCount',
              reservationData: reservationData
              
            };
          default:
              return mapping;
        }
        });
      };
      const updatedListingMapping = updateMappingsBasedOnFieldCode(listingList);
  const findNumberOfNights = (arrivalDate, departureDate) => {
    let noOfNights = null;
    if (arrivalDate && departureDate) {
      const date1 = new Date(moment(arrivalDate).format("YYYY-MM-DD"));
      const date2 = new Date(moment(departureDate).format("YYYY-MM-DD"));
      const timeDiff = date2.getTime() - date1.getTime();
      const nights = Math.ceil(timeDiff / (1000 * 3600 * 24));
      noOfNights = nights;
    }
    return noOfNights;
  };

  return (
    <div>
      {/* <div className="reservation-card-width"> */}
      {/* <div className="card"> */}
      <div className="card-header ">
        <div>
          <h6>
            {t("Confirmation Number")} :{" "}
            <strong>{reservationData?.pmsConfirmationNo}</strong>
          </h6>
        </div>
      </div>
       <div className="card-body">
        <div className="row">

        {Array.isArray(multipleUserData) && multipleUserData.length > 1 && (
          <div className="p-2 col-4">
         
          <Name reservationData = {reservationData}/>
          </div>
          )}
     
          
          <div className="p-2 col-4">
            {updatedListingMapping.map(({fieldCode,component, ...rest}) =>{
                      const ComponentToRender = components[component];
                      return (<> {fieldCode === "roomType" &&
                        <div key={fieldCode}>
                          {ComponentToRender?<ComponentToRender {...rest}/>: null}
                          </div>}
                          </>);
            })}
          </div>
             
        </div>
        <div className="row">
        {updatedListingMapping.map(({fieldCode,component, ...rest}) =>{
                                  const ComponentToRender = components[component];
                                  return (<> {fieldCode !== "roomType" && fieldCode !== "name" &&
                                    <div key={fieldCode} className="p-2 col-4">
                                      {ComponentToRender?<ComponentToRender {...rest}/>: null}
                                      </div>}
                                      </>);
                        })}
        </div>
        
      </div>
    </div>
  );
};

export default ReservationFormTemplate;

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import {
  CONFIG_MASTER_ENUM,
  STEP_MASTER_ENUM,
  timerConstantValue,
} from "../../constants/constant";
import {
  clearScanData,
  getScanDataFromWebhostQR,
} from "../../network/apiService";
import {
  fetchPropertyInfo,
  getPropertyInfo,
} from "../../store/Checkin/Scan/propertySlice";
import {
  fetchReservation,
  setReservationDataList,
} from "../../store/Checkin/reservationformSlice";
import {
  formatDate,
  getConfigurationCode,
  getParametersFromLocalStorage,
  getStepMaster,
} from "../../helpers/commonHelper";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";

import CheckInCheckOutToggleForm from "../../components/Forms/CheckInCheckOutToggleForm";
import ErrorPopup from "../../components/LoaderAndNotification/ErrorPopup";
import FilterPopupForm from "../../components/Forms/FilterPopupForm";
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import HomePageCard from "../../components/Layout/Card/HomePageCard";
import LoaderPopup from "../../components/LoaderAndNotification/LoaderPopup";
import { RESERVATION_LIST_FORM } from "../../routes/Paths";
import { getModuleConfiguration } from "../../store/Checkin/moduleConfigurationSlice";
import { getSelectedLanguage } from "../../store/Checkin/languageSlice";
import { homePageHandler } from "../../../src/helpers/commonHelper";
import i18n from "i18next";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { userReservationDetailsPassport } from "../../store/Checkin/Scan/scanPassportSlice";

const ConfirmationNumber = () => {
  const { t } = useTranslation();
  const selectedLanguage = useSelector(getSelectedLanguage);

  const timeLimit = timerConstantValue;
  const [showModal, setShowModal] = useState(false);
  const [confirmationNumberData, setConfirmationNumberData] = useState({
    lastName: "",
    confirmationNumber: "",
    checkoutDate: "",
  });

  const [loading, setLoading] = useState(false);
  const [timerValue, setTimerValue] = useState(timeLimit);
  const [restartTimer, setRestartTimer] = useState(false);
  const [erroModalData, setErrorModalData] = useState({
    toggleError: false,
    errorMessage: "",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = getParametersFromLocalStorage("token");
  const propertyData = useSelector(getPropertyInfo);

  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const calendarRef = useRef(null);

  const stepMaster = useSelector((state) => getModuleConfiguration(state));
  const stepConfigs = getStepMaster(
    stepMaster,
    STEP_MASTER_ENUM.RESERVATION_SEARCH_PAGE
  )?.configs;

  const stepReservationPage = getStepMaster(
    stepMaster,
    STEP_MASTER_ENUM.HOME_PAGE
  );
  const configs = stepReservationPage?.configs;
  const firstNameFilterRequired = getConfigurationCode(
    configs,
    CONFIG_MASTER_ENUM.FIRSTNAME_FILTER
  );
  const [firstNameFilter, setFirstNameFilter] = useState(false);
  const [reservationList, setReservationList] = useState(null);

  const handleDateChange = (date) => {
    // setSelectedDate(date);
    // setShowCalendar(false);
    // const month = pad(date.getMonth() + 1);
    // const day = pad(date.getDate());
    // const year = date.getFullYear();
    // const fullDate = month + "/" + day + "/" + year;
    // document.getElementsByName("selectedDate")[0].value = fullDate;
    setSelectedDate(date);
    setShowCalendar(false);
    const fullDate = formatDate(date);
    document.getElementsByName("selectedDate")[0].value = fullDate;
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [calendarRef]);

  const tileDisabled = ({ activeStartDate, date, view }) => {
    if (view === "month") {
      return date.getMonth() !== activeStartDate.getMonth();
    }
  };

  const pad = (number) => {
    return number < 10 ? "0" + number : number;
  };

  const resetTimerAndClosePopUp = () => {
    if (!timerValue) {
      setTimerValue(timeLimit);
    }
    setRestartTimer(true);
    setShowModal(false);
  };

  const timerCounter = (value) => {
    setTimerValue(value);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const stopTimer = () => {
    setRestartTimer(false);
  };

  const closeErrorModal = () => {
    setErrorModalData((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };

  const confirmationDataChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setConfirmationNumberData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
    setConfirmationNumberData({ lastName: "", confirmationNumber: "" });
    setFirstNameFilter(false);
  }, []);

  useEffect(() => {
    if (propertyData === null || propertyData === undefined) {
      dispatch(fetchPropertyInfo(token));
    }
  }, [propertyData]);
  // const reservationList = useSelector(getReservationListData);

  // useEffect(() => {

  // }, [reservationList]);

  const getConfirmationDetails = async () => {
    try {
      setLoading(true);
      if (selectedDate !== null && selectedDate !== undefined) {
        let checkoutDateFormatted = formatDate(selectedDate);
        confirmationNumberData.checkoutDate = checkoutDateFormatted;
      }

      dispatch(
        fetchReservation({
          lastName: confirmationNumberData?.lastName ?? null,
          confirmationNo: confirmationNumberData.confirmationNumber ?? null,
          propertyCode: propertyData?.data?.propertyCode,
          token: token,
          checkOutDate: confirmationNumberData.checkoutDate ?? null,
        })
      )
        .unwrap()
        .then((rep) => {
          fetchReservationCompleted(rep);
        })
        .catch((error) => {
          setLoading(false);
          setErrorModalData((state) => ({
            ...state,
            toggleError: true,
            errorMessage: t(error?.error) ?? t("Some error occured"),
          }));
          return;
        });
    } catch (error) {
      console.log("error2" + error);
    }
  };
  const wait = (milliseconds) =>
    new Promise((res, rej) =>
      setTimeout(
        () => rej(),
        // new Error(`Request timed out after ${milliseconds / 1000} seconds`)
        milliseconds
      )
    );
  const handleScanButtonClick = async () => {
    try {
      let inputValue = "";
      setLoading(true);
      inputValue = document.querySelector(".my-input").value;

      const clearData = await Promise.race([clearScanData({}), wait(30000)]);
      const scanDataQR = await Promise.race([
        getScanDataFromWebhostQR({}),
        wait(30000),
      ]);

      if (scanDataQR.data != null && scanDataQR.data.BarCodeData != null) {
        inputValue = scanDataQR.data.BarCodeData;
      }

      try {
        if (
          inputValue !== null &&
          inputValue !== undefined &&
          inputValue !== ""
        ) {
          dispatch(
            fetchReservation({
              lastName: null,
              confirmationNo: inputValue,
              propertyCode: propertyData?.data?.propertyCode,
              token: token,
              checkOutDate: null,
            })
          )
            .unwrap()
            .then((rep) => {
              fetchReservationCompleted(rep);
            })
            .catch((error) => {
              setLoading(false);
              setErrorModalData((state) => ({
                ...state,
                toggleError: true,
                errorMessage: t(error?.error) ?? t("Some error occured"),
              }));
              return;
            });
        } else {
          setErrorModalData((state) => ({
            ...state,
            toggleError: true,
            errorMessage: t("Oops! Please scan the QR code."),
          }));
          setLoading(false);
          return;
        }
      } catch (error) {
        setErrorModalData((state) => ({
          ...state,
          toggleError: true,
          errorMessage: t(
            "Sorry, unable to check in. Reservation is already checked in."
          ),
        }));
        setLoading(false);
        console.log(error);
        return;
      }

      // Continue with your logic using the input value
    } catch (error) {
      console.error("Error:", error);
      // Handle the error if needed
    }
  };
  const closeModal = () => {
    setFirstNameFilter(false);
  };
  const fetchReservationCompleted = (reservationList) => {
    if (reservationList?.result === true) {
      // if (reservationList?.data?. == 0 && reservationList?.data?.lastName?.toLowerCase() !== confirmationNumberData?.lastName?.toLowerCase()){
      //   setLoading(false);
      //   setErrorModalData((state) => ({
      //     ...state,
      //     toggleError: true,
      //     errorMessage:
      //       "Last name does not match the booking. Please ensure the provided last name matches the reservation."
      //   }));
      //   return;
      // }
      if (
        reservationList !== null &&
        reservationList !== undefined &&
        reservationList?.data !== null &&
        reservationList?.data !== undefined
      ) {
        setLoading(false);
        if (
          firstNameFilterRequired &&
          reservationList?.data !== null &&
          Array.isArray(reservationList?.data) &&
          reservationList.data.length > 1
        ) {
          setReservationList(reservationList.data);
          setFirstNameFilter(true);
        } else {
          dispatch(userReservationDetailsPassport(reservationList?.data));
          navigate(RESERVATION_LIST_FORM + "/search", { replace: true });
        }

        return;
      } else if (
        reservationList?.error !== undefined &&
        reservationList?.error !== null &&
        reservationList?.error === "No reservation found." &&
        !reservationList?.loading
      ) {
        setLoading(false);
        setErrorModalData((state) => ({
          ...state,
          toggleError: true,
          errorMessage: t(
            "Sorry, we couldn't find your booking. Please double-check your details or proceed to the front desk for assistance."
          ),
        }));
        return;
      } else {
        setLoading(false);
        setErrorModalData((state) => ({
          ...state,
          toggleError: true,
          errorMessage: t(
            "Unable to retrieve reservation. Please check your details or proceed to the front desk for assistance."
          ),
        }));
        return;
      }
    } else {
      setLoading(false);
      setErrorModalData((state) => ({
        ...state,
        toggleError: true,
        errorMessage: reservationList?.message ?? t("Some error occured"),
      }));
    }
  };

  const handlebackPage = () => {
    try {
      homePageHandler(dispatch, navigate);
    } catch (error) {
      console.error("Error going back:", error);
    }
  };
  const proceedWithNameFilter = (firstName) => {
    try {
      if (
        firstName !== null &&
        firstName !== undefined &&
        firstName !== "" &&
        firstName.replace(" ", "") !== ""
      ) {
        let reservations = reservationList.filter((p) => {
          return (
            p.firstName &&
            p.firstName?.toLowerCase() === firstName?.toLowerCase()
          );
        });
        if (
          reservations !== null &&
          reservations !== undefined &&
          Array.isArray(reservations) &&
          reservations?.length > 0
        ) {
          dispatch(setReservationDataList(reservations));
          dispatch(userReservationDetailsPassport(reservations));
          navigate(RESERVATION_LIST_FORM + "/search", { replace: true });
        } else {
          setLoading(false);
          setErrorModalData((state) => ({
            ...state,
            toggleError: true,
            errorMessage: t(
              "Sorry, we couldn't find your booking. Please double-check your details or proceed to the front desk for assistance."
            ),
          }));
          return;
        }
      }
    } catch (error) {
      console.error("Error proceedWithNameFilter: ", error);
      setLoading(false);
      setErrorModalData((state) => ({
        ...state,
        toggleError: true,
        errorMessage: t(
          "Sorry, we couldn't find your booking. Please double-check your details or proceed to the front desk for assistance."
        ),
      }));
      return;
    }
  };

  return (
    <div>
      <main>
        <div className="main-content confirmation-white">
          <div>
            <Header
              onClick={handlebackPage}
              setShowModal={setShowModal}
              isHome={true}
              title={t("greeting")}
              subTitle={t("Please choose your way to Check-In")}
            />
          </div>
          {stepConfigs && (
            <>
              <CheckInCheckOutToggleForm
                confirmationNumberData={confirmationNumberData}
                confirmationDataChangeHandler={confirmationDataChangeHandler}
                selectedDate={selectedDate}
                setShowCalendar={setShowCalendar}
                showCalendar={showCalendar}
                handleDateChange={handleDateChange}
                tileDisabled={tileDisabled}
                getConfirmationDetails={getConfirmationDetails}
                type="CheckIn"
                stepConfigs={stepConfigs}
                handleScanButtonClick={handleScanButtonClick}
              />
            </>
          )}

          {firstNameFilter && (
            <>
              <FilterPopupForm
                show={firstNameFilter}
                proceedFirstNameFilter={proceedWithNameFilter}
                closeModel={closeModal}
              />
            </>
          )}
        </div>

        <Footer
          page={"documentscanning"}
          showModal={showModal}
          openModal={openModal}
          timerCounter={timerCounter}
          timerValue={timerValue}
          restartTimer={restartTimer}
          stopTimer={stopTimer}
          timeLimit={timeLimit}
        />

        <LoaderPopup loading={loading} />
        <ErrorPopup
          closeErrorModal={closeErrorModal}
          erroModalData={erroModalData}
        />
      </main>
      {showModal && (
        <HomePageCard
          resetTimerAndClosePopUp={resetTimerAndClosePopUp}
          showModal={showModal}
        />
      )}
    </div>
  );
};

export default ConfirmationNumber;

/* eslint-disable jsx-a11y/alt-text */

import { initReactI18next, useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import {STEP_MASTER_ENUM,CONFIG_MASTER_ENUM} from "../../constants/constant";
import DrawIcon from "@mui/icons-material/Draw";
import FooterTimer from "./FooterTimer";
import { getSelectedLanguage } from "../../store/Checkin/languageSlice";
import i18n from "i18next";
import { useSelector } from "react-redux";
import { getModuleConfiguration } from "../../store/Checkin/moduleConfigurationSlice";
import {
  getConfigurationCode,getStepMaster
} from "../../helpers/commonHelper";

const KeyFooter = ({
  page,
  openModal,
  timerValue,
  showModal,
  restartTimer,
  stopTimer,
  timerCounter,
  timeLimit,
  isFaceMatchEnabled,
}) => {
  const { t } = useTranslation();
  const selectedLanguage = useSelector(getSelectedLanguage);

  useEffect(async () => {
    i18n.changeLanguage(selectedLanguage);
  }, []);

  const pages = ["search", "face", "key", "thankyou"];
  const [isVisible, setIsVisible] = useState(true);
  const stepMaster = useSelector((state) => getModuleConfiguration(state));

  const activeIcons = {
    search: "activeAgreedFormIcon",
    face: "activeFaceIcon",
    key: "activeKeyIcon",
    thankyou: "activeAllDoneIcon",
  };

  const stepReservationPage = getStepMaster(
    stepMaster,
    STEP_MASTER_ENUM.HOME_PAGE
  );
  const configs = stepReservationPage?.configs;
  const disableFooter = getConfigurationCode(
    configs,
    CONFIG_MASTER_ENUM.DISABLE_FOOTER
  );
  useEffect(() => {
    if (disableFooter) {
      setIsVisible(false);
    }
  }, [disableFooter]);


  let activeIcon = "";
  let activeState = {};
  let pageIndex = pages.indexOf(page);

  if (pageIndex !== -1) {
    for (let i = 0; i <= pageIndex; i++) {
      let currentPage = pages[i];
      activeIcon =
        activeIcons[currentPage] === "activeAllDoneIcon"
          ? "active"
          : activeIcons[currentPage];

      if (activeIcons[currentPage] !== activeIcons[pages[pageIndex]]) {
        activeState[currentPage + "Activated"] = "activated";
      }
      if (activeIcons[currentPage] === "activeAllDoneIcon") {
        activeState[currentPage + "Activated"] = "activated";
      }
    }
  }

  return (
    <footer  style={{ background: !isVisible ? 'transparent' : '' }} >
      <div className="footer-logo" style={{ visibility: isVisible ? 'visible' : 'hidden' }}>
        <img id="footer-logo" alt="Logo" />
      </div>
      <div className="tracker-status" style={{ visibility: isVisible ? 'visible' : 'hidden' }}>
        <div
          className={`mat-icon-wrap ${
            page === "search" ? "active" : "activated"
          }`}
        >
          <span className="bx bx-user-check mat-icon material-symbols-rounded"></span>
          <span className="title">{t("Confirmation")}</span>
        </div>
        <span className="mat-line"></span>

        {isFaceMatchEnabled !== undefined && isFaceMatchEnabled !== null && (
          <>
            <div
              className={`mat-icon-wrap ${
                activeIcon === "activeFaceIcon"
                  ? "active"
                  : activeState?.keyActivated
              }`}
            >
              <span className="bx bxs-face mat-icon material-symbols-rounded"></span>
              <span className="title">{t("Face Verification")}</span>
            </div>
            <span className="mat-line"></span>
          </>
        )}

        <div
          className={`mat-icon-wrap ${
            activeIcon === "activeKeyIcon"
              ? "active"
              : activeState?.keyActivated
          }`}
        >
          <span className="bx bxs-credit-card mat-icon material-symbols-rounded"></span>
          <span className="title">{t("Key")}</span>
        </div>
        <span className="mat-line"></span>

        <div
          className={`mat-icon-wrap ${
            activeIcon === "activeAllDoneIcon"
              ? "active"
              : activeState?.thankyouActivated
          }`}
        >
          <span className="mat-icon material-symbols-rounded">thumb_up</span>
          <span className="title">{t("PickUp Key Completed")}</span>
        </div>
      </div>
      {/* 80 */}
      <FooterTimer
        openModal={openModal}
        timerValue={timerValue}
        timerCounter={timerCounter}
        restartTimer={restartTimer}
        showModal={showModal}
        stopTimer={stopTimer}
        timeLimit={timeLimit}
        disableFooter={disableFooter}
      />
    </footer>
  );
};

export default KeyFooter;

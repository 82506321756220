import {
  CONFIG_MASTER_ENUM,
  SESSION_ENUM,
  STEP_MASTER_ENUM,
  timerConstantValue,
} from "../../constants/constant";
import { DOCUMENT_SCAN, FACE_VERIFICATION, HOME, UPGRADE } from "../../routes/Paths";
import {
  addItem,
  getScanBackData,
  getScanData,
  getScanDocResult,
  getScanDocumentCurrentCountOfGuest,
  resetDocReponse,
  resetScanDocument,
} from "../../store/Checkin/Scan/documentScanSlice";
import {
  addPackage,
  assignRoom,
  checkReservationDB,
  getAvailableRooms,
  getReservationTypes,
  isEarlyCheckin,
  updateAdultCount,
} from "../../network/apiService";
import {
  fetchProfiles,
  getProfilesListData,
  getReservationListData,
  resetIsFinished,
} from "../../store/Checkin/reservationformSlice";
import { fetchStep, getIsSkipped, setIsSkipped } from "../../store/stageSlice";
import {
  getConfigurationCode,
  getParametersFromLocalStorage,
  getStepMaster,
  homePageHandler,
  nameValidation,
  saveIntoLocalStorage,
} from "../../helpers/commonHelper";
import {
  getPassportScanData,
  userReservationDetailsPassport,
} from "../../store/Checkin/Scan/scanPassportSlice";
import {
  getconfirmationnumberData,
  userReservationDetailsConfirmatioNo,
} from "../../store/Checkin/ConfirmationNumber/confirmationNumberSlice";
import { initReactI18next, useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmLocalResidentModal from "../../components/Layout/Card/ConfirmLocalResidentModal";

import EarlyCheckinModal from "../../components/Layout/Card/EarlyCheckinModal";
import Footer from "../../components/Layout/Footer";
import HomePageCard from "../../components/Layout/Card/HomePageCard";
import QueueRoomConfirmationCard from "../../components/Layout/Card/QueueRoomConfirmationCard";
import ReservationForm from "../../components/Forms/ReservationForm";
import { getModuleConfiguration } from "../../store/Checkin/moduleConfigurationSlice";
import { getPropertyInfo } from "../../store/Checkin/Scan/propertySlice";
import { getSelectedLanguage } from "../../store/Checkin/languageSlice";
import i18n from "i18next";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";


//import { STEP_MASTER_ENUM, CONFIG_MASTER_ENUM,timerConstantValue } from "../../constants/constant";
//import { getModuleConfiguration } from "../../store/Checkin/moduleConfigurationSlice";

const PassportReservationForm = () => {
  
  const [showLocalResidentModal, setShowLocalResidentModal] = useState(false);
  //const isLocalResident = localStorage.getItem("isLocalResident") ? localStorage.getItem("isLocalResident") === "true" : false;

  
  const { t } = useTranslation();
  const selectedLanguage = useSelector(getSelectedLanguage);

  const timeLimit = timerConstantValue;
  const [showQueueModal, setQueueShowModal] = useState(false);
  const [queueMessage, setQueueMessage] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [timerValue, setTimerValue] = useState(timeLimit);
  const [restartTimer, setRestartTimer] = useState(false);
  const reservationData = useSelector(getconfirmationnumberData);
  const [updateConfirmationNo, setUpdateConfirmationNo] = useState("");
  const [page, setPage] = useState("passportscan");
  const [erroModalData, setErrorModalData] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const [erroModalData2, setErrorModalData2] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const [erroModalData3, setErrorModalData3] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const [packageCode, setPackageCode] = useState("");
  const [packageAmount, setPackageAmount] = useState("");

  const [earlyCheckin, setEarlyCheckin] = useState(false);

  const token = getParametersFromLocalStorage("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
 
  const reservationList = useSelector(getReservationListData);

  const propertyData = useSelector(getPropertyInfo);

  const guestCount = useSelector(getScanDocumentCurrentCountOfGuest);

  const profileList = useSelector(getProfilesListData);
  const scanData = useSelector(getScanData);
  const scanBackData = useSelector(getScanBackData);
  const addProfileResponse = useSelector(getScanDocResult);
  const isSkipped = useSelector(getIsSkipped);
  const { screen } = useParams();
  const [earlyCheckinCompleted, setEarlyCheckinCompleted] = useState(false);

  const [showConfirmModal, setshowConfirmModal] = useState(false);
  const [showConfirmAdultCountAtFrontDeskModal, setshowConfirmAdultCountAtFrontDeskModal] = useState(false);

  const [successMessage, setSuccessMessage] = useState("");
  const [successPopup, setSuccessPopup] = useState(false);

  const [adultCount, setAdultCount] = useState(0);
  const [confirmAdultCountCompleted, setConfirmAdultCountCompleted] =
    useState(false);
  const [confirmAdultCountAtFrontDeskCompleted, setconfirmAdultCountAtFrontDeskCompleted] = useState(false);

  //const adultCount = getParametersFromLocalStorage("totalCount");
  const stepMaster = useSelector((state) => getModuleConfiguration(state));

  const stepReservationPage = getStepMaster(
    stepMaster,
    STEP_MASTER_ENUM.HOME_PAGE
  );
  const configs = stepReservationPage?.configs;
  const adultCountChangeRequired = getConfigurationCode(
    configs,
    CONFIG_MASTER_ENUM.ADULTCOUNT_CHNAGE_REQUIRED
  );
  const adultCountChangeFromFrontDesk = getConfigurationCode(
    configs,
    CONFIG_MASTER_ENUM.ADULTCOUNT_CHANGE_FRONTDESK
  ) ?? false; // Default to false if not found


  const queueRoom = getConfigurationCode(configs, CONFIG_MASTER_ENUM.QUEUEROOM);
  const earlyCheckinQueueRoom = getConfigurationCode(configs, CONFIG_MASTER_ENUM.EARLY_CHECKIN_QUEUE);

  const settings = getParametersFromLocalStorage("settings");
  const IsLocalResidentCheckEnabled = settings?.find((setting) => setting.parameterName === "IsLocalResidentCheckEnabled" && setting.parameterValue?.toLowerCase() === "true");

  const [reservationProfiles, setReservationProfile] = useState(null);

  const enableChildScan = settings?.find(
    (setting) =>
      setting.parameterName === "EnableChildScan" &&
      setting.parameterValue?.toLowerCase() === "true"
  );
  const enableAdultScan = settings?.find(
    (setting) =>
      setting.parameterName === "EnableAdultScan" &&
      setting.parameterValue?.toLowerCase() === "true"
  );
  const handleLocalResidentResponse = (isLocalResidentConfirmed) => {
    //debugger
    setShowLocalResidentModal(false); // Close modal
    if (isLocalResidentConfirmed) {
      navigate(UPGRADE, { replace: true }); // Navigate to upgrade
    } else {
      if (isSkipped === true) {
        dispatch(resetScanDocument());
        navigate(FACE_VERIFICATION, { replace: true }); // Navigate to Face Verification
      } else {
        navigate(`${DOCUMENT_SCAN}`, { replace: true }); // Proceed to Document Scan
      }
    }
  };
  // Safely extract the setting value and ensure a default of `false`
const isLocalResidentCheck = 
IsLocalResidentCheckEnabled?.parameterValue?.toLowerCase() === "true" 
  ? true 
  : false;

  const fullName =
    reservationList?.data?.data?.length > 1
      ? reservationList?.data?.data[0].lastName
      : reservationList?.data?.data?.length === 1
      ? (reservationList?.data?.data[0].title === null ||
        reservationList?.data?.data[0].title === undefined
          ? ""
          : reservationList?.data?.data[0].title?.includes(".")
          ? reservationList?.data?.data[0].title
          : reservationList?.data?.data[0].title + ". ") +
        "" +
        nameValidation(
          reservationList?.data?.data[0].firstName,
          reservationList?.data?.data[0].lastName
        )
      : (reservationList?.data?.data?.title === null ||
        reservationList?.data?.data?.title === undefined
          ? ""
          : reservationList?.data?.data?.title?.includes(".")
          ? reservationList?.data?.data?.title
          : reservationList?.data?.data?.title + ". ") +
        "" +
        nameValidation(
          reservationList?.data?.data?.firstName,
          reservationList?.data?.data?.lastName
        );

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
    setReservationProfile(null);
    setQueueShowModal(false);
    dispatch(setIsSkipped(null));

    if (!adultCountChangeRequired) {
      setConfirmAdultCountCompleted(true);
    }
  }, []);

  useEffect(() => {
    if (earlyCheckinCompleted === true && confirmAdultCountCompleted === true) {
      //debugger
      dispatch(setIsSkipped(null));
      if (screen === "search") {
        if (isLocalResidentCheck) // Use the evaluated variable
        {
          //debugger
          setShowLocalResidentModal(true);
        }
        else 
        {
          if (isSkipped === true) {
          // dispatch(scanDocumentCountOfTheGuests(1));
          dispatch(resetScanDocument());
          navigate(FACE_VERIFICATION, { replace: true });
        } else {
          navigate(`${DOCUMENT_SCAN}`, { replace: true });
        }
        }
      } else if (addProfileResponse === true) {
        dispatch(resetDocReponse());
        navigate(FACE_VERIFICATION, { replace: true });
      }
    }
   
  }, [addProfileResponse, earlyCheckinCompleted, confirmAdultCountCompleted]);

  const getConfirmationNoOfSelectedDiv = (confNo, divBoolean) => {
    setUpdateConfirmationNo(confNo);
    return;
  };

  const disptachUserDetails = () => {
    const gotReservationData = reservationData.find(
      (data) => data.pmsConfirmationNo === updateConfirmationNo
    );
    dispatch(userReservationDetailsPassport(gotReservationData));
    dispatch(userReservationDetailsConfirmatioNo(gotReservationData));
    return;
  };
  const handlebackPage = () => {
    try {
      homePageHandler(dispatch, navigate);
    } catch (error) {
      console.error("Error returning home:", error);
    }
  };
  const resetTimerAndClosePopUp = () => {
    if (!timerValue) {
      setTimerValue(timeLimit);
    }
    setRestartTimer(true);
    setShowModal(false);
  };

  const timerCounter = (value) => {
    setTimerValue(value);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const stopTimer = () => {
    setRestartTimer(false);
  };

  const closeErrorModal = () => {
    setErrorModalData((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };

  const closeErrorModal2 = () => {
    setErrorModalData2((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
    homePageHandler(dispatch, navigate);
    return;
  };

  const closeErrorModal3 = () => {
    setErrorModalData3((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
    homePageHandler(dispatch, navigate);
    return;
  };

  const confirmEarlyCheckin = async (packageAmount, packageCode) => {
    setLoading(true);
    try {
      const addPackageResult = await addPackage(
        {
          confirmationNumber: reservationData?.pmsConfirmationNo || "",
          reservationNameID: reservationData?.reservationNameID || "",
          addOnItem: {
            itemType: "EARLY",
            itemCode: packageCode,
            startDate: reservationData?.arrivalDate,
            endDate: reservationData?.departureDate,
            quantity: 1,
            amount: packageAmount,
          },
        },
        token
      );

      if (!addPackageResult.data.result) {
        setLoading(false);

        setErrorModalData((state) => ({
          ...state,
          toggleError: true,
          errorMessage: t(
            "Early Check-In Failed. Please visit front desk for assistance"
          ),
        }));
        setTimeout(() => {
          handlebackPage();
        }, 6000);

        return;
      } else {
        proceedEarlyCheckin();
      }
    } catch (error) {
      setTimeout(() => {
        setErrorModalData((state) => ({
          ...state,
          toggleError: true,
          errorMessage: t(
            "Early Check-In Failed. Please visit front desk for assistance"
          ),
        }));
        handlebackPage();
      }, 6000);
    }
  };
  const confirmLocalResident = async (isLocalResident) => {
    setLoading(true);
  };

  const cancelEarlyCheckin = () =>{
   
    if(earlyCheckinQueueRoom !== null && earlyCheckinQueueRoom !== undefined && earlyCheckinQueueRoom !== false){
      setLoading2(false);
      setLoading3(false);
      setEarlyCheckin(false);
      setQueueMessage(t("Queue the reservation"));
      setQueueShowModal(true);
      //proceedEarlyCheckin();
    }else{
      handlebackPage();
    }
  }
  const cancelLocalResident = () =>{
   
    // if(earlyCheckinQueueRoom !== null && earlyCheckinQueueRoom !== undefined && earlyCheckinQueueRoom !== false){
    //   setLoading2(false);
    //   setLoading3(false);
    //   setEarlyCheckin(false);
    //   setQueueMessage(t("Queue the reservation"));
    //   setQueueShowModal(true);
    //   //proceedEarlyCheckin();
    // }else{
      handlebackPage();
    // }
  }

  const proceedEarlyCheckin = (isQueue) =>{
   
    saveIntoLocalStorage(
      "confirmationNo",
      reservationData.pmsConfirmationNo
    );
    saveIntoLocalStorage(
      "reservationNameID",
      reservationData.reservationNameID
    );

 
    const totalCount =
      (enableAdultScan ? reservationData?.adultCount ?? 0 : 1) +
      (enableChildScan ? reservationData?.childCount ?? 0 : 0);
    setAdultCount(totalCount);
    saveIntoLocalStorage("totalCount", totalCount);
    const totalGuestCount = reservationData?.adultCount ?? 0;
    saveIntoLocalStorage("totalGuestCount", totalGuestCount);

    dispatch(resetIsFinished());
    dispatch(userReservationDetailsPassport(reservationData));
    setLoading(false);

    if (screen !== "search") {
      debugger
      addProfile(reservationData.pmsConfirmationNo);
    } else {
      debugger
      setEarlyCheckin(false);
      if(isQueue !== undefined && isQueue !== null && isQueue === true){
        setSuccessMessage(t("Moved to Queue")); 
      }else{
        setSuccessMessage(t("Early checkin added successfully!"));        
      }
      setSuccessPopup(true);
      setTimeout(() => {
        setSuccessPopup(false);
        setEarlyCheckinCompleted(true);
        debugger
       // setshowConfirmModal(true);        
       if (!adultCountChangeFromFrontDesk) {
      
        if ((totalCount ?? 0) > 1) {
          setshowConfirmModal(true);
        }
        else{
          setshowConfirmModal(false);
          setConfirmAdultCountCompleted(true);
        
        }
       } 
       else{
        setshowConfirmAdultCountAtFrontDeskModal(true);
       }
        // if ((totalCount ?? 0) > 1) {
        //   setshowConfirmModal(true);
        // }
        // else{
        //   setshowConfirmModal(false);
        //   setConfirmAdultCountCompleted(true);
        
        // }//commneted to handle adult count change at front desk in mandai
      }, 3000);
    }
  }

  const addProfile = (pmsConfirmationNo, profiles) => {
    const currentProfile =
      profiles?.length > 0
        ? profiles[guestCount]
        : profileList.length > guestCount
        ? profileList[guestCount]
        : null;

    if (scanBackData !== undefined && scanData !== undefined) {
      if (scanBackData !== null) {
        dispatch(
          addItem({
            scanData: scanData,
            scanBackData: scanBackData,
            profile: currentProfile,
          })
        );
      } else if (scanData !== null) {
        dispatch(addItem({ scanData: scanData, profile: currentProfile }));
      }
      setEarlyCheckinCompleted(true);
      //setshowConfirmModal(true);
      if(!adultCountChangeFromFrontDesk)
      {
        setshowConfirmModal(true);
      }
      else{
        setshowConfirmAdultCountAtFrontDeskModal(true);
      }


      // if ((totalCount ?? 0) > 1) {
      //   setshowConfirmModal(true);
      // }
      // else{
      //   setshowConfirmModal(false);
      //   setConfirmAdultCountCompleted(true);
      // }

    }
  };

  const verifyEarlyCheckin = async (reservationProfiles, isSkipped) => {
    try {
      const foundReservationData = reservationProfiles?.reservation;

      saveIntoLocalStorage(
        "confirmationNo",
        foundReservationData.pmsConfirmationNo
      );
      saveIntoLocalStorage(
        "reservationNameID",
        foundReservationData.reservationNameID
      );

   
      const totalCount =
        (enableAdultScan ? foundReservationData?.adultCount ?? 0 : 1) +
        (enableChildScan ? foundReservationData?.childCount ?? 0 : 0);
      setAdultCount(totalCount);
      saveIntoLocalStorage("totalCount", totalCount);
      const totalGuestCount = foundReservationData?.adultCount ?? 0;
      saveIntoLocalStorage("totalGuestCount", totalGuestCount);

      dispatch(userReservationDetailsConfirmatioNo(foundReservationData));
      dispatch(userReservationDetailsPassport(foundReservationData));

      const earlyCheckin = await isEarlyCheckin(
        token,
        foundReservationData?.pmsConfirmationNo,
        foundReservationData?.reservationNameID
      );

      if (earlyCheckin.data.result === true) {
        const preCheckin = getParametersFromLocalStorage(
          SESSION_ENUM.PRECHECKIN
        );

        if (
          preCheckin &&
          (foundReservationData?.roomNo === "" ||
            foundReservationData?.roomNo === null ||
            foundReservationData?.roomNo === undefined)
        ) {
          setLoading2(false);
          setTimeout(() => {
            setErrorModalData((state) => ({
              ...state,
              toggleError: true,
              errorMessage: t(
                "There are no rooms available for early check-in. Please visit front desk for assistance."
              ),
            }));
            handlebackPage();
          }, 6000);

          return;
        } else {
          setPackageAmount(earlyCheckin.data.data.amount?.toString());
          setPackageCode(earlyCheckin.data.data.packageCode);
          setEarlyCheckin(true);
        }
      } else {
        //#region reservation details update

        dispatch(resetIsFinished());
        setLoading2(false);

        if (screen !== "search") {
          addProfile(
            foundReservationData.pmsConfirmationNo,
            reservationProfiles?.profiles
          );
        } else {
      
          setEarlyCheckinCompleted(true);
          if (!adultCountChangeFromFrontDesk) {
            debugger
             if ((totalCount ?? 0) > 1) {
            setshowConfirmModal(true);
            }
            else{
            setshowConfirmModal(false);
            setConfirmAdultCountCompleted(true);
            
            }
          }
          else{
            debugger
            setshowConfirmAdultCountAtFrontDeskModal(true);
          }

          // if ((totalCount ?? 0) > 1) {
          //   setshowConfirmModal(true);
          // }
          // else{
          //   setshowConfirmModal(false);
          //   setConfirmAdultCountCompleted(true);
            
          // }//commented to include adult count change at front desk for mandai
        }
        //#endregion
      }
    } catch (Exception) {
      setLoading2(false);
      setTimeout(() => {
        setErrorModalData((state) => ({
          ...state,
          toggleError: true,
          errorMessage: t(
            "Early Check-In Failed. Please visit front desk for assistance"
          ),
        }));
        handlebackPage();
      }, 6000);

      return;
    }
  };

  const proceedHandler = async () => {
    setLoading2(true);
    const foundReservationData =
      reservationList?.data?.data?.length > 0
        ? reservationList?.data?.data?.find(
            (ele) => ele?.pmsConfirmationNo === updateConfirmationNo
          )
        : reservationList?.data?.data;

    if (foundReservationData == null) {
      setLoading2(false);
      setTimeout(() => {
        setErrorModalData((state) => ({
          ...state,
          toggleError: true,
          errorMessage: t(
            "Reservation Not Found. Please visit front desk for assistance"
          ),
        }));
        handlebackPage();
      }, 6000);
    } else {
      setLoading2(true);
      if (propertyData?.data?.pms === "OHIP-SHARER") {
        if (foundReservationData?.childCount > 0) {
          setLoading2(false);
          setErrorModalData((state) => ({
            ...state,
            toggleError: true,
            errorMessage: t(
              "We cannot process check-ins for reservations that include children. Please proceed to front desk."
            ),
          }));
          setTimeout(() => {
            handlebackPage();
          }, 6000);
          return;
        }
      }

      if (foundReservationData?.adultCount === 0) {
        setLoading2(false);
        setErrorModalData((state) => ({
          ...state,
          toggleError: true,
          errorMessage: t(
            "Apologies! Accompanied guests cannot check in at the kiosk. Please proceed to the front desk."
          ),
        }));
        setTimeout(() => {
          handlebackPage();
        }, 6000);
        return;
      }
      // await checkReservationDB(
      //   {
      //     confirmationNo: foundReservationData?.pmsConfirmationNo,
      //     reservationNameID: foundReservationData?.reservationNameID,
      //   },
      //   token
      // );

      await dispatch(
        fetchProfiles({
          ConfirmationNo: foundReservationData?.pmsConfirmationNo,
          ReservationNameID: foundReservationData?.reservationNameID,
          //reservationNameID: null,
          token,
        })
      ).then(async (profileList) => {
        if (
          foundReservationData !== null &&
          foundReservationData !== undefined &&
          profileList &&
          profileList?.payload !== null &&
          profileList?.payload !== undefined &&
          profileList?.payload?.profiles?.length > 0 &&
          propertyData
        ) {
          let reservation = profileList?.payload?.reservation;
          if (reservation?.isPreCheckedIn === true) {
            setLoading2(false);
            setErrorModalData((state) => ({
              ...state,
              toggleError: true,
              errorMessage: t(
                "Reservation is already in Queue. Our mission control crew will contact you once your room is ready."
              ),
            }));
            setTimeout(() => {
              handlebackPage();
            }, 6000);
            return;
          } else if (reservation?.isCheckinAllowed === false) {
           
            setLoading2(false);
            setErrorModalData((state) => ({
              ...state,
              toggleError: true,
              errorMessage: t(
                profileList?.payload?.message ? profileList?.payload?.message : "Check In Not Allowed. This reservation is already checked in. Please visit front desk for assistance"
              ),
            }));
            setTimeout(() => {
              handlebackPage();
            }, 6000);
            return;
          } else {
            let currentProfile = profileList?.payload?.profiles[guestCount];

            dispatch(userReservationDetailsPassport(foundReservationData));
            dispatch(
              fetchStep({
                confirmationNo: foundReservationData?.pmsConfirmationNo,
                propertyCode: propertyData?.data?.propertyCode,
                type: "checkin",
                token: token,
                stepCode: "SEI02",
                profile: currentProfile,
                guestCount: guestCount,
              })
            )
              .unwrap()
              .then((result) => {
                isReservationFound(profileList?.payload, result);
              });
            return;
          }

          // setProceed(true);
        }
      });
    }
  };

  const isReservationFound = async (reservationProfiles, isSkipped) => {
    const foundReservationData = reservationProfiles?.reservation;
    if (foundReservationData?.isPreRegister === true) {
      setReservationProfile(reservationProfiles);
      setLoading2(false);
      setLoading3(false);
      setQueueMessage(t("Rooms are not ready"));
      setQueueShowModal(true);
    } else {
      if (foundReservationData?.roomNo) {
        verifyEarlyCheckin(reservationProfiles, isSkipped);
        return;
      }
    }

    if (!foundReservationData?.roomNo) {
      const getRoomResponse = await getAvailableRooms(
        {
          RoomTypeCode: foundReservationData?.roomTypeCode,
          ArrivalDate: moment(foundReservationData.arrivalDate).format(
            "YYYY-MM-DD"
          ),
          DepartureDate: moment(foundReservationData.departureDate).format(
            "YYYY-MM-DD"
          ),
        },
        token
      );

      if (
        getRoomResponse.data.data.statusCode === 200 &&
        getRoomResponse.data?.data?.data?.length > 0
      ) {
        const newObj = {
          confirmationNumber: foundReservationData?.pmsConfirmationNo,
          roomNumber: getRoomResponse?.data?.data.data[0]?.roomNumber,
          reservationNameID: foundReservationData?.reservationNameID,
        };
        const assignedRoom = await assignRoom(
          newObj,
          token,
          propertyData.propertyCode
        );

        if (assignedRoom.status === 200 && assignedRoom.data.result) {
          setLoading3(false);
          setLoading4(true);
          verifyEarlyCheckin(reservationProfiles, isSkipped);
        }
        if (assignedRoom.status === 200 && !assignedRoom.data.result) {
          setLoading3(false);
          setErrorModalData3((state) => ({
            ...state,
            toggleError: true,
            errorMessage: t(
              "Room is not available, please visit front desk for assistance."
            ),
          }));
          return;
        }
      } else if (!getRoomResponse.data.data.length) {
        if (queueRoom) {
          setReservationProfile(reservationProfiles);
          setLoading2(false);
          setLoading3(false);
          setQueueMessage(t("Rooms are not ready"));
          setQueueShowModal(true);

          // const confirmationNo = localStorage.getItem("confirmationNo");
          // const reservationNameID = localStorage.getItem("reservationNameID");
          // const requestData = {
          //   confirmationNo: confirmationNo,
          //   reservationNameID: reservationNameID,
          // };
          // const resp = await queueRoom(requestData, token);
          // if (resp?.data?.result === true) {
          // }
        } else {
          setLoading2(false);
          setErrorModalData2((state) => ({
            ...state,
            toggleError: true,
            errorMessage: t(
              "Room is not available, please visit front desk for assistance."
            ),
          }));
        }
        return;
      }
    }
  };

  const closeModal = () => {
    setshowConfirmModal(false);
    setConfirmAdultCountCompleted(true);
  };
  

  const confirmAdultCount = async (adultCount) => {
    //debugger
    try {
      debugger
      //update api for adultCount
      // const updateAdultCountResponse = await updateAdultCount(
      //   {
      //     confirmationNo: reservationData?.pmsConfirmationNo,
      //     adultCount: adultCount,
      //     adultCountSpecified: true,
      //     reservationNameID:reservationData?.reservationNameID,
      //     roomTypeCode:reservationData?.roomTypeCode,
      //     roomNo:reservationData?.roomNumber,
      //     arrivalDate: new Date(moment(reservationData?.ArrivalDate).format("YYYY-MM-DD")),
      //     CheckOutDate: new Date(moment(reservationData?.DepartureDate).format("YYYY-MM-DD")),
      //     rateCode:reservationData?.rateCode,
      //   },
      //   token
      // );

      saveIntoLocalStorage("totalCount", adultCount);
      saveIntoLocalStorage("totalGuestCount", adultCount);

      //end
      setshowConfirmModal(false);
      setConfirmAdultCountCompleted(true);
    
    } catch (error) {}
  };
  const handleModalConfirm = () => {
    setShowLocalResidentModal(false);
    navigate("/upgrade", { replace: true });
  };
  const handleModalCancel = () => {
    setShowLocalResidentModal(false);
  };
  
  const confirmQueueRoom = () => {
    setQueueShowModal(false);
    
    setLoading2(true);
    if(earlyCheckinQueueRoom !== null && earlyCheckinQueueRoom !== undefined && earlyCheckinQueueRoom !== false){
      proceedEarlyCheckin(earlyCheckinQueueRoom);
    }else{
      saveIntoLocalStorage(SESSION_ENUM.PRECHECKIN, true);
      verifyEarlyCheckin(reservationProfiles, isSkipped);      
    }
  };

  return (
    <div>
      <ReservationForm
        loading={loading}
        loading2={loading2}
        loading3={loading3}
        loading4={loading4}
        page={page}
        reservationData={reservationList?.data?.data}
        openModal={openModal}
        closeErrorModal={closeErrorModal}
        closeErrorModal2={closeErrorModal2}
        closeErrorModal3={closeErrorModal3}
        erroModalData={erroModalData}
        erroModalData2={erroModalData2}
        erroModalData3={erroModalData3}
        updateConfirmationNo={updateConfirmationNo}
        getConfirmationNoOfSelectedDiv={getConfirmationNoOfSelectedDiv}
        propertyDetails={propertyData?.data}
        disptachUserDetails={disptachUserDetails}
        proceedHandler={proceedHandler}
        earlyCheckIn={earlyCheckin}
      
        packageAmout={packageAmount}
        currency={propertyData?.data?.currency}
        confirmEarlyCheckin={confirmEarlyCheckin}
        confirmLocalResident={confirmLocalResident}
        backToHomePage={handlebackPage}
        packageCode={packageCode}
        showConfirmModal={showConfirmModal}
        showConfirmAdultCountAtFrontDeskModal={showConfirmAdultCountAtFrontDeskModal}
       
        confirmAdultCount={confirmAdultCount}
        adultCount={adultCount}
        closeModal={closeModal}
        t={t}
        fullName={fullName}
        successPopup={successPopup}
        successMessage={successMessage}
      />




      {packageAmount !== "" &&
        packageCode !== "" &&
        earlyCheckin === true &&
        propertyData?.data?.currency !== "" && (
          <EarlyCheckinModal
            earlyCheckIn={earlyCheckin}
            packageAmout={packageAmount}
            currency={propertyData?.data?.currency}
            confirmEarlyCheckin={confirmEarlyCheckin}
            packageCode={packageCode}
            backToHomePage={cancelEarlyCheckin}
          />
        )}
       {/* Local Resident Modal */}
    <ConfirmLocalResidentModal
      showLocalResidentModal={showLocalResidentModal}
      confirmLocalResident={handleLocalResidentResponse}
    />
      <Footer
        page={"documentscanning"}
        showModal={showModal}
        openModal={openModal}
        timerCounter={timerCounter}
        timerValue={timerValue}
        restartTimer={restartTimer}
        stopTimer={stopTimer}
        timeLimit={timeLimit}
      />
      {showModal && (
        <HomePageCard
          showModal={showModal}
          resetTimerAndClosePopUp={resetTimerAndClosePopUp}
        />
      )}
      {showQueueModal === true && (
        <QueueRoomConfirmationCard
          onConfirm={confirmQueueRoom}
          resetTimerAndClosePopUp={handlebackPage}
          queueMessage={queueMessage}
        />
      )}
    </div>
  );
};

export default PassportReservationForm;

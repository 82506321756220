import "../../assets/stylesheet/confirmation-number-toggle.css";

import React, { useState } from "react";
import { initReactI18next, useTranslation } from "react-i18next";
import { useEffect, useRef } from "react";

import Calendar from "react-calendar";
import Modal from "@mui/material/Modal";
import { STEP_CONFIG_ENUM } from "../../constants/constant";
import calendar from "../../assets/images/calendar.png";
import { formatDate } from "../../helpers/commonHelper";
import { getParametersFromLocalStorage } from "../../helpers/commonHelper";
import { getSelectedLanguage } from "../../store/Checkin/languageSlice";
import i18n from "i18next";
import { useSelector } from "react-redux";

// import { format } from 'date-fns';
// import { enUS } from 'date-fns/locale'; 

const CheckInCheckOutToggleForm = ({
  confirmationNumberData,
  confirmationDataChangeHandler,
  selectedDate,
  setShowCalendar,
  showCalendar,
  handleDateChange,
  tileDisabled,
  getConfirmationDetails,
  type,
  stepConfigs,
  handleScanButtonClick,
  onKeyDown,
}) => {
  const [activeCase, setActiveCase] = useState();
  const [active, setActive] = useState(false);

  const { t } = useTranslation();
  const selectedLanguage = useSelector(getSelectedLanguage);

  const calendarRef = useRef(null);
  const [minDate,setMinDate] = useState("");
  const [calendarPickerFieldName, setCalendarPickerFieldName] = useState(
    t("Checkin Date")
  );
  const settings = getParametersFromLocalStorage("settings");
  const calendarType = settings?.find(
    (setting) => setting.parameterName === "CalendarType"
    
  );

  const calendarValidation = settings?.find(
    (setting) => setting.parameterName === "calendarValidation"
    
  );
 
  useEffect(()=>{
      if(calendarValidation !== null && calendarValidation !== undefined && calendarValidation?.parameterValue==="true"){      
        setMinDate(new Date());
      }
      else {
        setMinDate(void 0);
      }     
  },[]);
  //let calendarPickerFieldName = 'Checkin Date';
  //console.log(calendarType)
  useEffect(() => {
    if (stepConfigs !== null && stepConfigs?.length > 0) {
      setActiveCase(stepConfigs[0].stepConfigurationCode);
    }
  }, [stepConfigs]);

  const handleClick = (index, configCode) => {
    setActiveCase(configCode);
    const switchBtnLeft = document.querySelector(
      ".switch-button .switch-button-case.left"
    );
    const switchBtnRight = document.querySelector(
      ".switch-button .switch-button-case.right"
    );
    const activeSwitch = document.querySelector(".switch-button-active");
    confirmationNumberData.lastName = "";
    confirmationNumberData.confirmationNumber = "";

    if (switchBtnRight && switchBtnLeft && activeSwitch) {
      switchBtnRight.classList.remove("switch-button-active-case");
      switchBtnLeft.classList.add("switch-button-active-case");
      activeSwitch.style.left = "0%";
    }
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [calendarRef]);

  useEffect(async () => {
    i18n.changeLanguage(selectedLanguage);
  }, []);

  useEffect(() => {
    if (type === "CheckIn" || type === "Key") {
      setCalendarPickerFieldName(t("Checkout Date"));
    } else {
      setCalendarPickerFieldName(t("Checkin Date"));
    }
  }, []);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.target.tagName !== "INPUT") {
        const input = document.querySelector(".my-input");
        if (input) {
          input.focus();
          input.value += e.key; // Append the pressed key to the existing value
          e.preventDefault();
        }
      }
    };

    document.addEventListener("keypress", handleKeyPress);

    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, []);

  const pad = (number) => {
    return number < 10 ? "0" + number : number;
  };

  const formatDate1 = (date) => {
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const year = date.getFullYear();
    return year + "-" + month + "-" + day + "T00:00:00";
  };

  // const formatDate1 = (date, dateFormat) => {
  //   return format(date, dateFormat.toLowerCase(), { locale: enUS });
  // };

  const weekFormats = (date, culture, localizer) => {
    // weekdayFormat: (date, culture, localizer) => localizer.format(date, 'dddd', culture),
    localizer.format(date, "dddd", culture);
  };
  useEffect(() => {
    switch (activeCase) {
      case STEP_CONFIG_ENUM.CONFIRMATION_CHKDT:
        if (
          confirmationNumberData.confirmationNumber &&
          selectedDate !== null
        ) {
          setActive(true);
        } else {
          setActive(false);
        }
        break;

      case STEP_CONFIG_ENUM.LASTNAME_CHKDT:
        if (confirmationNumberData.lastName && selectedDate !== null) {
          setActive(true);
        } else {
          setActive(false);
        }
        break;

      case STEP_CONFIG_ENUM.LASTNAME_CONFIRMATION:
        if (
          confirmationNumberData.lastName &&
          confirmationNumberData.confirmationNumber
        ) {
          setActive(true);
        } else {
          setActive(false);
        }
        break;
      case STEP_CONFIG_ENUM.LASTNAME_ROOMNUMBER:
        if (
          confirmationNumberData.lastName &&
          confirmationNumberData.roomNumber
        ) {
          setActive(true);
        } else {
          setActive(false);
        }
        break;

      case STEP_CONFIG_ENUM.LASTNAME_ROOMNUMBER_DATE:
        if (
          confirmationNumberData.lastName &&
          confirmationNumberData.roomNumber &&
          selectedDate !== null
        ) {
          setActive(true);
        } else {
          setActive(false);
        }
        break;
    }
  }, [
    confirmationNumberData.confirmationNumber,
    confirmationNumberData.lastName,
    confirmationNumberData.roomNumber,
    selectedDate,
  ]);

  return (
    <div className="d-flex checkout-content-center flex-row">
      {stepConfigs?.length > 1 && (
        <div
          className="p-1 did-floating-label-content switch-button d-flex"
          style={{ marginTop: "50px" }}
        >
          {stepConfigs && (
            <>
              {stepConfigs?.map((item, index) => (
                <>
                  <button
                    key={index}
                    id={"btn_" + index}
                    className={`justify-content-center did-floating-label-content switch-button-case  rounded-full  ${
                      activeCase === item?.stepConfigurationCode
                        ? " make-active  switch-button-active " +
                          (index % 2 === 0 && index !== 0 ? "left" : "right")
                        : ""
                    }`}
                    onClick={() => {
                      handleClick(index, item?.stepConfigurationCode);
                    }}
                  >
                    {item?.stepConfigurationCode ===
                      STEP_CONFIG_ENUM.CONFIRMATION_CHKDT && (
                      <>{t("Confirmation Number")}</>
                    )}
                    {item?.stepConfigurationCode ===
                      STEP_CONFIG_ENUM.LASTNAME_CHKDT && <>{t("Last Name")}</>}
                    {item?.stepConfigurationCode ===
                      STEP_CONFIG_ENUM.LASTNAME_CONFIRMATION && (
                      <>{t("Confirmation Number")}</>
                    )}
                    {item?.stepConfigurationCode ===
                      STEP_CONFIG_ENUM.QR_CODE && <>{t("Scan QR")}</>}
                  </button>
                  {(index === 0 || index % 2 !== 0) &&
                    stepConfigs?.length > 2 && (
                      <>
                        <span className="hr-vertical" />
                      </>
                    )}
                </>
              ))}
            </>
          )}
          <br />
          <br />
        </div>
      )}

      <div
        className="p-5 mx-auto"
        style={{ marginTop: stepConfigs?.length > 1 ? "40px" : "-30px" }}
      >
        <div className={`justify-content-center`}>
          {activeCase === STEP_CONFIG_ENUM.CONFIRMATION_CHKDT && (
            <div className="p-1 md:p-10">
              <div className="mx-auto font-semibold flex flex-col items-center">
                <p
                  className="block text-center mb-2"
                  style={{ marginBottom: "0px" , fontSize:"123%"}}
                >
                  {t("Confirmation No")}
                </p>
                <input
                  type="text"
                  key={activeCase}
                  autoComplete="off"
                  name="confirmationNumber"
                  value={confirmationNumberData.confirmationNumber}
                  onChange={confirmationDataChangeHandler}
                  className="form-control form-control-set did-floating-input py-2 px-5 bg-[#F0F8FF] border border-black rounded-xl w-full placeholder:text-[#4278A3]"
                  placeholder={t("Enter Confirmation No")}
                />
              </div>
              <div className="font-semibold flex flex-col items-center">
                <p
                  className="block text-center mb-2"
                  //style={{ marginBottom: "0px" }}
                  style={{ marginBottom: "0px" , fontSize:"123%"}}
                >
                  {calendarPickerFieldName}
                </p>
                <div className="input-with-icon">
                  <input
                    name="selectedDate"
                    placeholder={t("Select Checkout Date")}
                    type="text"                    
                    // defaultValue={selectedDate?.toLocaleDateString()}
                    onClick={() => setShowCalendar(true)}
                    autoComplete="off"
                    readOnly
                    className="form-control form-control-set did-floating-input py-2 px-5 bg-[#F0F8FF] border border-black rounded-xl w-full placeholder:text-[#4278A3]"
                  />
                  <div className="icon-container">
                    <img
                      src={calendar}
                      alt="Calendar Icon"
                      className="calendar-icon"
                      onClick={() => setShowCalendar(true)}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeCase === STEP_CONFIG_ENUM.LASTNAME_ROOMNUMBER &&
            (type === "CheckOut" || type === "Key") && (
              <>
                <div>
                  <div className="mx-auto font-semibold flex flex-col items-center">
                    <p
                      className="block text-center mb-2"
                      //style={{ marginBottom: "0px" }}
                      style={{ marginBottom: "0px" , fontSize:"123%"}}
                    >
                      {t("Last Name")}
                    </p>
                    <input
                      type="text"
                      name="lastName"
                      key={activeCase}
                      value={confirmationNumberData.lastName}
                      onChange={confirmationDataChangeHandler}
                      className="form-control form-control-set did-floating-input"
                      autoComplete="off"
                      placeholder={t("Enter your Last Name")}
                    />
                  </div>
                  <div className="mx-auto font-semibold flex flex-col items-center">
                    <p
                      className="block text-center mb-2"
                      //style={{ marginBottom: "0px" }}
                      style={{ marginBottom: "0px" , fontSize:"123%"}}
                    >
                      {t("Room Number")}
                    </p>
                    <input
                      name="roomNumber"
                      type="number"
                      value={confirmationNumberData.roomNumber}
                      onChange={confirmationDataChangeHandler}
                      className="form-control form-control-set did-floating-input"
                      autoComplete="off"
                      placeholder={t("Enter your Room Number")}
                      onKeyDown={onKeyDown}
                    />
                  </div>
                </div>
              </>
            )}
          {/* {activeCase === STEP_CONFIG_ENUM.LASTNAME_CHKDT &&
            (type === "CheckOut" || type === "Key") && (
              <>
                <div>
                  <div className="mx-auto font-semibold flex flex-col items-center">
                    <p
                      className="block text-center mb-2"
                      style={{ marginBottom: "0px" }}
                    >
                      {t("Room Number")}
                    </p>
                    <input
                      type="text"
                      name="roomNumber"
                      value={confirmationNumberData.roomNumber}
                      onChange={confirmationDataChangeHandler}
                      className="form-control form-control-set did-floating-input"
                      autoComplete="off"
                      placeholder="Enter Room number"
                    />
                  </div>
                  <div className="mx-auto font-semibold flex flex-col items-center">
                    <p
                      className="block text-center mb-2"
                      style={{ marginBottom: "0px" }}
                    >
                      {t("Last Name")}
                    </p>
                    <input
                      type="text"
                      name="lastName"
                      key={activeCase}
                      value={confirmationNumberData.lastName}
                      onChange={confirmationDataChangeHandler}
                      className="form-control form-control-set did-floating-input"
                      autoComplete="off"
                      placeholder={t("Enter Last name")}
                    />
                  </div>
                  <div className="mx-auto font-semibold flex flex-col items-center">
                    <p
                      className="block text-center mb-2"
                      style={{ marginBottom: "0px" }}
                    >
                      {t("CheckOut Date")}
                    </p>
                    <div className="input-with-icon">
                      <input
                        name="selectedDate"
                        placeholder={t("Select Checkout Date")}
                        type="text"
                        // defaultValue={selectedDate?.toLocaleDateString()}
                        onClick={() => setShowCalendar(true)}
                        autoComplete="off"
                        readOnly
                        className="form-control form-control-set did-floating-input py-2 px-5 bg-[#F0F8FF] border border-black rounded-xl w-full placeholder:text-[#4278A3]"
                      />
                      <div className="icon-container">
                        <img
                          src={calendar}
                          alt="Calendar Icon"
                          className="calendar-icon"
                          onClick={() => setShowCalendar(true)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )} */}
          {activeCase === STEP_CONFIG_ENUM.LASTNAME_CHKDT && (
            <div className="p-1 md:p-10">
              <div>
                <div className="flex justify-content-center did-floating-label-content">
                  <p
                    className="block text-center mb-2"
                   // style={{ marginBottom: "0px" }}
                   style={{ marginBottom: "0px" , fontSize:"123%"}}
                  >
                    {t("Last Name")}
                  </p>
                  <input
                    type="text"
                    key={activeCase}
                    name="lastName"
                    autoComplete="off"
                    value={confirmationNumberData.lastName}
                    onChange={confirmationDataChangeHandler}
                    className="form-control form-control-set did-floating-input py-2 px-5 bg-[#F0F8FF] border border-black rounded-xl w-full placeholder:text-[#4278A3]"
                    placeholder={t("Enter Last Name")}
                  />
                </div>
              </div>
              <div className="justify-content-center did-floating-label-content">
                <p
                  className="block text-center mb-2"
                  //style={{ marginBottom: "0px" }}
                  style={{ marginBottom: "0px" , fontSize:"123%"}}
                >
                  {calendarPickerFieldName}
                </p>
                <div className="input-with-icon">
                  <input
                    name="selectedDate"
                    placeholder={t("Select Checkout Date")}
                    type="text"
                    autoComplete="off"
                    // defaultValue={selectedDate?.toLocaleDateString()}
                    onClick={() => setShowCalendar(true)}
                    readOnly
                    className="form-control form-control-set did-floating-input py-2 px-5 bg-[#F0F8FF] border border-black rounded-xl w-full placeholder:text-[#4278A3]"
                  />
                  <div className="icon-container">
                    <img
                      src={calendar}
                      alt="Calendar Icon"
                      className="calendar-icon"
                      onClick={() => setShowCalendar(true)}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {activeCase === STEP_CONFIG_ENUM.LASTNAME_CONFIRMATION && (
            <>
              <div>
                <div className="flex justify-content-center did-floating-label-content">
                  <p
                    className="block text-center mb-2"
                    //style={{ marginBottom: "0px" }}
                    style={{ marginBottom: "0px" , fontSize:"123%"}}
                  >
                    {t("Confirmation Number")}
                  </p>
                  <input
                    type="text"
                    name="confirmationNumber"
                    value={confirmationNumberData.confirmationNumber}
                    onChange={confirmationDataChangeHandler}
                    className="form-control form-control-set did-floating-input py-2 px-5 bg-[#F0F8FF] border border-black rounded-xl w-full placeholder:text-[#4278A3]"
                    autoComplete="off"
                    placeholder={t("Enter Confirmation No")}
                  />
                </div>
                <div className="flex justify-content-center did-floating-label-content">
                  <p
                    className="block text-center mb-2"
                    //style={{ marginBottom: "0px" }}
                    style={{ marginBottom: "0px" , fontSize:"123%"}}
                  >
                    {t("Last Name")}
                  </p>
                  <input
                    type="text"
                    name="lastName"
                    value={confirmationNumberData.lastName}
                    onChange={confirmationDataChangeHandler}
                    className="form-control form-control-set did-floating-input py-2 px-5 bg-[#F0F8FF] border border-black rounded-xl w-full placeholder:text-[#4278A3]"
                    autoComplete="off"
                    placeholder={t("Enter Last Name")}
                  />
                </div>
              </div>
            </>
          )}
          {activeCase === STEP_CONFIG_ENUM.QR_CODE && (
            <>
              <div
                className="flex justify-content-center did-floating-label-content"
                //style={{ marginTop: "50px" }}
                style={{ marginBottom: "0px" , fontSize:"123%"}}
              >
                <div className="scan-text">
                  <h5 className="text-center font-14 font-weight-500">
                    {t("Scan your")} <strong> {t("QR code")}</strong>.
                  </h5>
                  <br />
                  <h5 className="text-center font-14 font-weight-500">
                    {t("Touch Scan to begin.")}
                  </h5>
                </div>
                <div className="text-center mt-30">
                  <button
                    className="btn button-blue scan-btn"
                    onClick={handleScanButtonClick}
                  >
                    <i className="bx bx-barcode-reader scan-icon"></i>
                    {t("Scan")}
                  </button>
                  <input
                    type="hidden"
                    // style={{ position: "absolute", left: "-10000px" }}
                    id="my-input"
                    className="my-input"
                  ></input>
                </div>
              </div>
            </>
          )}

          {activeCase === STEP_CONFIG_ENUM.LASTNAME_ROOMNUMBER_DATE &&
            (type === "CheckOut" || type === "Key") && (
              <>
                <div>
                  <div className="mx-auto font-semibold flex flex-col items-center">
                    <p
                      className="block text-center mb-2"
                      //style={{ marginBottom: "0px" }}
                      style={{ marginBottom: "0px" , fontSize:"123%"}}
                    >
                      {t("Last Name")}
                    </p>
                    <input
                      type="text"
                      name="lastName"
                      key={activeCase}
                      value={confirmationNumberData.lastName}
                      onChange={confirmationDataChangeHandler}
                      className="form-control form-control-set did-floating-input"
                      autoComplete="off"
                      placeholder={t("Enter your Last Name")}
                    />
                  </div>
                  <div className="mx-auto font-semibold flex flex-col items-center">
                    <p
                      className="block text-center mb-2"
                     // style={{ marginBottom: "0px" }}
                      style={{ marginBottom: "0px" , fontSize:"123%"}}
                    >
                      {t("Room Number")}
                    </p>
                    <input
                      name="roomNumber"
                      type="number"
                      value={confirmationNumberData.roomNumber}
                      onChange={confirmationDataChangeHandler}
                      className="form-control form-control-set did-floating-input"
                      autoComplete="off"
                      placeholder={t("Enter your Room Number")}
                      onKeyDown={onKeyDown}
                    />
                  </div>
                  <div className="justify-content-center did-floating-label-content">
                    <p
                      className="block text-center mb-2"
                     // style={{ marginBottom: "0px" }}
                     style={{ marginBottom: "0px" , fontSize:"123%"}}
                    >
                      {calendarPickerFieldName}
                    </p>
                    <div className="input-with-icon">
                      <input
                        name="selectedDate"
                        placeholder={t("Select Checkout Date")}
                        type="text"
                        autoComplete="off"
                        // defaultValue={selectedDate?.toLocaleDateString()}
                        onClick={() => setShowCalendar(true)}
                        readOnly
                        className="form-control form-control-set did-floating-input py-2 px-5 bg-[#F0F8FF] border border-black rounded-xl w-full placeholder:text-[#4278A3]"
                      />
                      <div className="icon-container">
                        <img
                          src={calendar}
                          alt="Calendar Icon"
                          className="calendar-icon"
                          onClick={() => setShowCalendar(true)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

          {showCalendar && (
            <Modal
              open={showCalendar}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: "1",
              }}
            >
              <div className="calendar-popup" ref={calendarRef}>
                <button
                  className="close-icon"
                  onClick={() => setShowCalendar(false)}
                >
                  {t("X")}
                </button>
                <div className="calendar-header">
                  <h5 className="h-title">
                    {" "}
                    {t("Choose Your")}{" "}
                    {t(calendarPickerFieldName)?.toLowerCase()}{" "}
                  </h5>
                </div>
                <Calendar
                  onChange={handleDateChange}
                  value={formatDate(selectedDate)}
                  // tileDisabled={({ date }) => {
                  //   const today = new Date();
                  //   // Allow interaction with visible next month dates
                  //   return date < today;
                  // }}
                  tileDisabled={() => false} 
                  locale={selectedLanguage}
                  formatLongDate={(locale, date) =>
                    formatDate1(date, "dd MMM YYYY")
                  }
                  minDate={minDate}
                  format={weekFormats}
                  //calendarType="iso8601"
                  calendarType={calendarType?.parameterValue??"iso8601"}
                />
              </div>
            </Modal>
          )}
          {activeCase !== STEP_CONFIG_ENUM.QR_CODE && (
            <div className="reservation-btn-set p-1 mt-2">
              <div className="find-booking p-0">
                <button
                  onClick={getConfirmationDetails}
                  className={`btn btn-primary find-booking-btn`}
                  disabled={active === false}
                >
                  <i className="bx bx-search"></i> {t("SEARCH")}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CheckInCheckOutToggleForm;

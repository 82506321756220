import React from 'react';
import { useTranslation } from "react-i18next";
import { resetDocReponse } from '../../store/Checkin/Scan/documentScanSlice';
import { formatDate, nameValidation, addPrefixIfSingleLetter } from '../../helpers/commonHelper';
import moment from "moment";
import { TitleCaseConverter } from "../Plugins/TitleCaseCoverter";

const findNumberOfNights = (arrivalDate, departureDate) => {
    let noOfNights = null;
    if (arrivalDate && departureDate) {
      const date1 = new Date(moment(arrivalDate).format("YYYY-MM-DD"));
      const date2 = new Date(moment(departureDate).format("YYYY-MM-DD"));
      const timeDiff = date2.getTime() - date1.getTime();
      const nights = Math.ceil(timeDiff / (1000 * 3600 * 24));
      noOfNights = nights;
    }
    return noOfNights;
  };
export function RoomType (reservationData){
    const { t } = useTranslation();
    console.log(reservationData);
    console.log(reservationData.reservationData.roomTypeDescription);
    return (<div >
    <label className="reservation-card-label">{t("Room Type")}:</label>
    <div>
      <p>{reservationData?.reservationData?.roomTypeDescription}</p>
    </div>
  </div>);
};

export function ArrivalDate(reservationData){

const { t } = useTranslation();
    
    return (<div className="col-4" style={{ margin: 0, whiteSpace: "nowrap" }}>
    <label className="reservation-card-label">{t("Arrival Date")}:</label>
    <div>
      <p>{formatDate(reservationData?.reservationData?.arrivalDate)}</p>
    </div>
  </div>);
};
export function DepartureDate (reservationData){

const { t } = useTranslation();
return (<div className="col-4" style={{ margin: 0, whiteSpace: "nowrap" }}>
    <label className="reservation-card-label">{t("Departure Date")}:</label>
    <div>
      <p>{formatDate(reservationData?.reservationData?.departureDate) || ""}</p>
    </div>
  </div>);

};
export function Name (reservationData){

const { t } = useTranslation();
    console.log(reservationData);
    console.log(reservationData.reservationData.roomTypeDescription);
    return (<div >
    <label className="reservation-card-label">{t("Name")}:</label>
    <div>
    {TitleCaseConverter({
            text:
                 (reservationData?.reservationData?.title !== "" &&
                 reservationData?.reservationData?.title !== null
                                 ? reservationData?.reservationData?.title?.includes(".")
                   ? reservationData?.reservationData?.title + " "
                     : reservationData?.reservationData?.title + "." + " "
                 : "") +
                 reservationData?.reservationData?.firstName +
                 " " +
                 reservationData?.reservationData?.lastName,
             })}
    </div>
  </div>);
};
export function NoOfNights(reservationData){

const { t } = useTranslation();
return (<div className="col-4" style={{ margin: 0, whiteSpace: "nowrap" }}>
    <label className="reservation-card-label">{t("Number of Nights")}:</label>
    <div>
      <p>{addPrefixIfSingleLetter(findNumberOfNights(
               reservationData?.reservationData?.arrivalDate,
               reservationData?.reservationData?.departureDate
             )+"")}
 </p>
    </div>
  </div>);
};

export function AdultCount(reservationData){

const { t } = useTranslation();
return (<div className="col-4" style={{ margin: 0, whiteSpace: "nowrap" }}>
    <label className="reservation-card-label">{t("Adult(s)")}:</label>
    <div>
      <p>{reservationData?.reservationData?.adultCount}
 </p>
    </div>
  </div>);
}
export function ChildCount (reservationData){


const { t } = useTranslation();
return (<div className="col-4" style={{ margin: 0, whiteSpace: "nowrap" }}>
    <label className="reservation-card-label">{t("Children (0-12years old)")}:</label>
    <div>
      <p>{reservationData?.reservationData?.childCount || 0}
 </p>
    </div>
  </div>);
}

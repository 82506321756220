import zIndex from "@mui/material/styles/zIndex";
import { useEffect } from "react";

const FooterTimer = ({
  timerValue,
  showModal,
  openModal,
  timerCounter,
  restartTimer,
  stopTimer,
  timeLimit,
  type,
  disableFooter
}) => {
  useEffect(() => {
    if (!timerValue && restartTimer && !showModal) {
      stopTimer();
      return;
    }
  }, [restartTimer, showModal, stopTimer, timerValue]);

  useEffect(() => {
    let interval = null;

    if (timerValue == 0 && !showModal && !restartTimer) {
      openModal();
      return;
    }

    if (timerValue > 0) {
      interval = setInterval(() => {
        timerCounter(timerValue - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [showModal, timerValue, restartTimer, openModal, timerCounter]);

  const progressOffset = Math.round(100 - (100 * timerValue) / timeLimit);

  return (
    <>
      {type === "sml" ? (
        <>
          <div className={ disableFooter?"completed-status-disable":"completed-status"}>
            <label>{timerValue}</label>
            <div className="base-timer">
              <svg className={disableFooter? "disable":''} xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 34 34" >
                <circle
                  cx="16"
                  cy="16"
                  r="15.9155"
                  className={ disableFooter? "progress-bar__background_Disable":"progress-bar__background"}
                />
                <circle
                  cx="16"
                  cy="16"
                  r="15.9155"
                  className={disableFooter?"progress-bar__progress_disable js-progress-bar" :"progress-bar__progress js-progress-bar"}
                  style={{ strokeDashoffset: progressOffset }}
                />
              </svg>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={ disableFooter?"completed-status-disable":"completed-status"}>
            <span>{timerValue}</span>
            <div className="base-timer">
              <svg  className={disableFooter? "disable":''} xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 34 34">
                <circle
                  cx="16"
                  cy="16"
                  r="15.9155"
                  className={ disableFooter? "progress-bar__background_Disable":"progress-bar__background"}
                />
                <circle
                  cx="16"
                  cy="16"
                  r="15.9155"
                  className={disableFooter?"progress-bar__progress_disable js-progress-bar" :"progress-bar__progress js-progress-bar"}
                  style={{ strokeDashoffset: progressOffset }}
                />
              </svg>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default FooterTimer;

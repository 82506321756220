/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { Link } from "react-router-dom";
import SkipImage from "../../../assets/images/person-image.PNG";
import { useTranslation } from 'react-i18next';
import React from "react";

import "../../../assets/stylesheet/change-homepage.css";
import { CONFIG_MASTER_ENUM } from "../../../constants/constant";
import {
  getMainModuleConfiguration
} from "../../../store/Checkin/moduleConfigurationSlice";

import { getConfigurationCode, getStepMaster } from "../../../helpers/commonHelper";

import { useSelector } from "react-redux";

import { STEP_MASTER_ENUM } from "../../../constants/constant";
import i18n from "i18next";



const SkipModal = ({ closeModal, skipHandler,navigateUrl }) => {
  const stepMaster = useSelector((state) => getMainModuleConfiguration(state));

  const stepUpsellPage = getStepMaster(
    stepMaster,
    STEP_MASTER_ENUM.UPSELL_PAGE
  );
  const configs = stepUpsellPage?.configs;
  const skipImage = getConfigurationCode(//ss
    configs,
    CONFIG_MASTER_ENUM.SKIP_IMAGE
  );
  const { t } = useTranslation();

  return (
    <div
      className="modal"
    >
      <div className="modal-dialog modal-lg modal-top">
        <div className="modal-content modal-bg">
          <div className="modal-body">
            <div className="text-center">
             
              <div>
                {
                   skipImage === true &&
                   <>
                    <img src={SkipImage} className="w-110" />
                   </>
                 
                }
              </div> 
          
              <div className="mt-30">
                <h6>
                  {t("Do you want to skip this step without")} <br></br>{t("selecting packages?")}
                </h6>
              </div>
            </div>
            <div className="text-center mt-30">
              <button
                className="btn modal-no-btn"
                data-bs-dismiss="modal"
                onClick={closeModal}
              >
                {t("No")}
              </button>
              <Link to={navigateUrl}>
                <button
                  className="btn modal-yes-btn bg-transperent"
                  onClick={() => {
                    skipHandler();
                  }}
                >
                  {t("Yes")}
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkipModal;

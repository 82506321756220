import React, { useState, useEffect, useRef } from "react";


const SoftKeyboard = ({ handleKeyClick, isVisible, setIsVisible }) => {
  // Keyboard layouts
  const regularLayout = [
    ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"],
    ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p"],
    ["⇑","a", "s", "d", "f", "g", "h", "j", "k", "l"],
    [ "z", "x", "c", "v", "b", "n", "m", ",", ".", "/"], // Shift key (⇧) for uppercase toggle
    ["123&","@", "Space",".com", "⌫"] // Toggle between regular and special characters
  ];

  const specialCharacterLayout = [
    ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"],
    ["!", "@", "#", "$", "%", "^", "&", "*", "(", ")"],
    ["-", "_", "=", "+", "{", "}", "[", "]", "|"],
    ["<", ">", "?", "/", "~", "`", "\\", ";", ":"],
    ["ABC", "Space", "⌫"] // Toggle back to regular layout
  ];

  // States to track the current layout (regular/special) and case (uppercase/lowercase)
  const [isSpecial, setIsSpecial] = useState(false);
  const [isUppercase, setIsUppercase] = useState(false);
  const keyboardRef = useRef(null);

  // Close keyboard if clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (keyboardRef.current && !keyboardRef.current.contains(event.target)) {
        setIsVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isVisible, setIsVisible]);

  // Toggle between lowercase and uppercase
  const toggleCase = () => {
    setIsUppercase(!isUppercase);
  };

  // Toggle between regular and special characters layout
  const toggleLayout = () => {
    setIsSpecial(!isSpecial);
  };

  // Handle the key click event, taking care of uppercase/lowercase
  const handleKeyPress = (key) => {
    if (key === "⇑") {
      toggleCase(); // Toggle uppercase
    } else if (key === "123&" || key === "ABC") {
      toggleLayout(); // Toggle between layouts
    } else {
      handleKeyClick(isUppercase ? key.toUpperCase() : key); // Handle the key press
    }
  };

  // Render the appropriate keyboard layout based on the current state
  const renderKeys = (layout) => {
    console.log(isUppercase);
    return layout.map((row, rowIndex) => (
      <div key={rowIndex} className="keyboard-row">
        {row.map((key, keyIndex) => (
          <button
            key={keyIndex}
            className={`key ${key.toLowerCase()} ${key === "Space" ? "space" : ""} ${key === "⌫" ? "backspace" : ""} ${key === "123&" || "ABC"? "toggle-special-btn":""} ${key === "⇑"? "toggle-case-btn": ""}`}
            onClick={() => handleKeyPress(key)}
          >
           
            {key === "Space" ? "␣" : (key === "Backspace"? "⌫":isUppercase ? key.toUpperCase() : key)}
          </button>
        ))}
      </div>
    ));
  };

  return (
    <div ref={keyboardRef} className={`soft-keyboard ${isVisible ? "visible" : ""}`}>
      <button className="close-btn" onClick={() => setIsVisible(false)}>
        ✖
      </button>

      <div className="keyboard">
        {isSpecial ? renderKeys(specialCharacterLayout) : renderKeys(regularLayout)}
      </div>
    </div>
  );
};

export default SoftKeyboard;

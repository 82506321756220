import FooterTimer from "../FooterTimer";
import { useState } from "react";
import { initReactI18next, useTranslation } from "react-i18next";
import i18n from "i18next";
import { getSelectedLanguage } from "../../../store/Checkin/languageSlice";
import { useSelector } from "react-redux";

export const RoomCard = ({ roomNo }) => {
  const timeLimit = 8;
  const [timerValue, setTimerValue] = useState(timeLimit);
  const [restartTimer, setRestartTimer] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const timerCounter = (value) => {
    setTimerValue(value);
  };
  const handleShowRoom = () => {
    setShowModal(prevState => !prevState);
    if (!showModal === true) {
      setTimerValue(timeLimit);
    }
  };
  const stopTimer = () => {
    setRestartTimer(false);
  };
  const openModel = () => {
    setShowModal(false);
  };
  const { t } = useTranslation();
  const selectedLanguage = useSelector(getSelectedLanguage);
  return (
    <div className="card-container">
      <div className="d-flex">
        <div className="card card-primary" style={{ width: "280px" , marginLeft:"50px"}}>
          <div className="card-header card-primary-header justify-content-center d-flex">
            <i className="bx bx-door-open p-1" style={{ fontSize: "30px" }}></i>
            <label className="p-1">{t("Your room number")}</label>
          </div>
          <div
            className="card-body"
            onClick={handleShowRoom}
            style={{ cursor: "pointer" }}
          >
            <div
              className="d-flex p-1 justify-content-center "
              style={{ margin: "10px" }}
            >
              { showModal === true ? (
                <>
                  <h4 className="p-1 h-strong">{Number(roomNo)}</h4>
                </>
              ) : (
                <>
                  <i
                    className="bx bx-show p-1"
                    style={{ fontSize: "35px" }}
                  ></i>
                  <h4 className="p-1 h-strong">{t("Tap to Reveal")}</h4>
                </>
              )}
            </div>
          </div>
        </div>

        <div
          className={`d-flex align-items-center flex-column fixed-div hideDiv ${
            showModal === true ? " showDiv" : ""
          }`}
        >
          <i className="bx bxs-low-vision" style={{ fontSize: "40px" , color:"#f02c2c"}}  onClick={handleShowRoom} ></i>
          <FooterTimer
            type={"sml"}
            timerValue={timerValue}
            timerCounter={timerCounter}
            restartTimer={restartTimer}
            timeLimit={timeLimit}
            openModal={openModel}
            stopTimer={stopTimer}
          />
        </div>
      </div>
    </div>
  );
};

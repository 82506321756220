import "../../assets/stylesheet/footer.css";

import { useEffect, useState } from "react";

import DrawIcon from "@mui/icons-material/Draw";
import FooterTimer from "./FooterTimer";
import { STEP_MASTER_ENUM ,CONFIG_MASTER_ENUM} from "../../constants/constant";
import { currentDate } from "../../helpers/commonHelper";
import { getModuleConfiguration } from "../../store/Checkin/moduleConfigurationSlice";
import { getSelectedLanguage } from "../../store/Checkin/languageSlice";
import i18n from "i18next";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getConfigurationCode,getStepMaster
} from "../../helpers/commonHelper";
const Footer = ({
  page,
  openModal,
  timerValue,
  showModal,
  restartTimer,
  stopTimer,
  timerCounter,
  timeLimit,
}) => {
  const stepMaster = useSelector((state) => getModuleConfiguration(state));
  const [pages, setPages] = useState([]);
  const [isVisible, setIsVisible] = useState(true);
  // const pages = [
  //   "documentscanning",
  //   "facerecognition",
  //    "upgrade",
  //   "signature",
  //   "payment",
  //   "key",
  //   "thankyou",
  // ];
  
  const stepReservationPage = getStepMaster(
    stepMaster,
    STEP_MASTER_ENUM.HOME_PAGE
  );
  const configs = stepReservationPage?.configs;
  const disableFooter = getConfigurationCode(
    configs,
    CONFIG_MASTER_ENUM.DISABLE_FOOTER
  );
  useEffect(() => {
    if (disableFooter) {
      setIsVisible(false);
    }
  }, [disableFooter]);
  useEffect(() => {
    if (pages.length === 0 && stepMaster?.length > 0) {
      const newPages = [];
      stepMaster.forEach((item) => {
        if (item.stepCode === STEP_MASTER_ENUM.SCAN_DOCUMENT_PAGE) {
          newPages.push("documentscanning");
        }
        if (item.stepCode === STEP_MASTER_ENUM.FACEVERIFICATION_PAGE) {
          newPages.push("facerecognition");
        }
        if (item.stepCode === STEP_MASTER_ENUM.UPSELL_PAGE) {
          newPages.push("upgrade");
        }
        if (item.stepCode === STEP_MASTER_ENUM.REGISTRATION_CARD_PAGE) {
          newPages.push("signature");
        }
        if (item.stepCode === STEP_MASTER_ENUM.PAYMENT_PAGE) {
          newPages.push("payment");
        }
        if (item.stepCode === STEP_MASTER_ENUM.KEY_PAGE) {
          newPages.push("key");
        }
        if (item.stepCode === STEP_MASTER_ENUM.CHECKIN_THANKYOU_PAGE) {
          newPages.push("thankyou");
        }
        setPages(newPages);
        //setPages(prevPages => [...prevPages, newPages]);
        return null; // Return null to avoid warnings about not returning a value in a map callback
      });
    }
  }, [stepMaster,pages]);

  const activeIcons = {
    documentscanning: "activeDocumentIcon",
    facerecognition: "activeFaceIcon",
    upgrade: "activeUpgradeIcon",
    signature: "activeSignatureIcon",
    payment: "activePaymentIcon",
    key: "activeKeyIcon",
    thankyou: "activeAllDoneIcon",
  };

  let activeIcon = "";
  let activeState = {};
  let pageIndex = pages?.indexOf(page);
  if (pageIndex !== -1) {
    for (let i = 0; i <= pageIndex; i++) {
      let currentPage = pages[i];
      activeIcon =
        activeIcons[currentPage] === "activeAllDoneIcon"
          ? "active"
          : activeIcons[currentPage];

      if (activeIcons[currentPage] !== activeIcons[pages[pageIndex]]) {
        activeState[currentPage + "Activated"] = "activated";
      }
      if (activeIcons[currentPage] === "activeAllDoneIcon") {
        activeState[currentPage + "Activated"] = "activated";
      }
    }
  }

  const { t } = useTranslation();
  const selectedLanguage = useSelector(getSelectedLanguage);

  useEffect(async () => {
    i18n.changeLanguage(selectedLanguage);
  }, []);

  return (
    <footer style={{ background: !isVisible ? 'transparent' : '' }}>
      <div className="footer-logo" style={{ visibility: isVisible ? 'visible' : 'hidden' }}> 
        <img id="footer-logo" alt="Logo" />
      </div>
      <div className="tracker-status" style={{ visibility: isVisible ? 'visible' : 'hidden' }}>
        <div
          className={`mat-icon-wrap ${
            page === "confirmation" ? "active" : "activated"
          }`}
        >
          <span className="bx bx-user-check mat-icon material-symbols-rounded"></span>
          <span className="title">{t("Check-in Options")}</span>
        </div>
        <span className="mat-line"></span>
        <div
          className={`mat-icon-wrap ${
            activeIcon === "activeDocumentIcon"
              ? "active"
              : activeState?.documentscanningActivated
          }`}
        >
          <span className="bx bx-scan mat-icon material-symbols-rounded"></span>
          <span className="title">{t("Document scanning")}</span>
        </div>
        <span className="mat-line"></span>
        <div
          className={`mat-icon-wrap ${
            activeIcon === "activeFaceIcon"
              ? "active"
              : activeState?.facerecognitionActivated
          }`}
        >
          <span className="mat-icon material-symbols-rounded">
            {t("familiar_face_and_zone")}
          </span>
          <span className="title">{t("Face recognition")}</span>
        </div>
        <span className="mat-line"></span>
        {pages !== null && pages?.includes("upgrade") && (
          <>
            <div
              className={`mat-icon-wrap ${
                activeIcon === "activeUpgradeIcon"
                  ? "active"
                  : activeState?.upgradeActivated
              }`}
            >
              <span className="bx bx-chevrons-up mat-icon material-symbols-rounded"></span>
              {/* <span className="title">Upgrade</span> */}
              <span className="title">{t("Upgrade")}</span>
            </div>
            <span className="mat-line"></span>
          </>
        )} 

        <div
          className={`mat-icon-wrap ${
            activeIcon === "activeSignatureIcon"
              ? "active"
              : activeState?.signatureActivated
          }`}
        >
          <span className=" mat-icon material-symbols-rounded">
            <DrawIcon fontSize="small" />
          </span>
          <span className="title">{t("Signature")}</span>
        </div>
        <span className="mat-line"></span>
        <div
          className={`mat-icon-wrap ${
            activeIcon === "activePaymentIcon"
              ? "active"
              : activeState?.paymentActivated
          }`}
        >
          <span className="bx bxs-credit-card mat-icon material-symbols-rounded"></span>
          <span className="title">{t("Payment")}</span>
        </div>
        <span className="mat-line"></span>
        <div
          className={`mat-icon-wrap ${
            activeIcon === "activeKeyIcon"
              ? "active"
              : activeState?.keyActivated
          }`}
        >
          <span className="bx bxs-key mat-icon material-symbols-rounded"></span>
          <span className="title">{t("Key")}</span>
        </div>
        <span className="mat-line"></span>
        <div
          className={`mat-icon-wrap ${
            activeIcon === "activeAllDoneIcon"
              ? "active"
              : activeState?.thankyouActivated
          }`}
        >
          <span className="mat-icon material-symbols-rounded">thumb_up</span>
          <span className="title">{t("Check-in Completed")}</span>
        </div>
      </div>

      {/* 80 */}
      <FooterTimer
        openModal={openModal}
        timerValue={timerValue}
        timerCounter={timerCounter}
        restartTimer={restartTimer}
        showModal={showModal}
        stopTimer={stopTimer}
        timeLimit={timeLimit}
        disableFooter={disableFooter}
      />
    </footer>
  );
};

export default Footer;

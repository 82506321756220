import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import CloseIcon from '@mui/icons-material/Close';
const ErrorPopup = ({ closeErrorModal, erroModalData }) => {
  useEffect(() => {
    if (erroModalData.toggleError) {
      setTimeout(() => {
        closeErrorModal();
      }, 8000);
    }
  }, [closeErrorModal, erroModalData.toggleError]);

  return (
    <div>
      <Modal open={erroModalData.toggleError} style={{ zIndex: "1" }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "350px",
            backgroundColor: "#f5f5f5",
            border: "2px #000",
            boxShadow: "0 0 24px rgba(0, 0, 0, 0.5)",
            padding: "16px",
            borderRadius: "20px",
          }}
        >
          <button className="close-icon" onClick={closeErrorModal}>
          <CloseIcon
            style={{ float: "right", color: "white" }}
            onClick={closeErrorModal}
          /> 
        </button> 

          <div style={{ textAlign: "center" }}>
            <i
              className="bx bxs-error-circle"
              style={{ fontSize: "55px", color: "#ed1111" }}
            ></i>
          </div>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, textAlign: "center", fontSize: "1.2rem" }}
          >
            {erroModalData.errorMessage}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default ErrorPopup;

import React, { useState,useEffect  } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { getParametersFromLocalStorage } from "../../../helpers/commonHelper";
import { initReactI18next, useTranslation } from "react-i18next";

const ConfirmLocalResidentModal = ({
showLocalResidentModal,
confirmLocalResident
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <Modal open={showLocalResidentModal} style={{ zIndex: "1" }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "800px",
            backgroundColor: "#f5f5f5",
            border: "2px #000",
            boxShadow: "0 0 24px rgba(0, 0, 0, 0.5)",
            padding: "70px",
            borderRadius: "20px",
          }}
        >
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, textAlign: "center", fontSize: "1.8rem" }}
          >
            <p>{t("Are you a resident of Japan")+"?"}</p>
          </Typography>
          <div style={{ textAlign: "center" }}>

            <button
            style={{ marginTop: "20px", padding: "15px", marginLeft: "15px" }}
            className="btn button-blue scan-btn"
            onClick={() => {
                confirmLocalResident(true);
            }}
            >
            {t("Yes")}
            </button>

            <button
            style={{ marginTop: "20px", padding: "15px", marginLeft: "15px" }}
            className="btn button-blue scan-btn"
            onClick={() => {
              confirmLocalResident(false);
            }}
            >
            {t("No")}
            </button>
</div>
      
        </Box>
      </Modal>
    </div>
  );
};

export default ConfirmLocalResidentModal;

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import { initReactI18next, useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import ErrorPopup from "../LoaderAndNotification/ErrorPopup";
import Header from "../Layout/Header";
import KeyEncoding from "../../assets/images/keycard.png";
import { Link } from "react-router-dom";
import LoaderPopup from "../LoaderAndNotification/LoaderPopup";
import LottieHelper from "../Layout/LottieHelper";
import { RoomCard } from "../Layout/Card/RoomCard";
import SuccessPopup from "../LoaderAndNotification/SuccessPopup";
import { getParametersFromLocalStorage } from "../../helpers/commonHelper";
import { getSelectedLanguage } from "../../store/Checkin/languageSlice";
import i18n from "i18next";
import { useSelector } from "react-redux";

const CreateKey = ({
  openModal,
  erroModalData,
  closeErrorModal,
  loading,
  userData,
  createKeyHandler,
  navigateTo,
  handlebackPage,
  skipKey,
}) => {
  const { t } = useTranslation();
  const selectedLanguage = useSelector(getSelectedLanguage);
  const settings = getParametersFromLocalStorage("settings");
  const enableDigitalKey = settings?.find(
    (setting) =>
      setting.parameterName === "EnableDigitalKey" &&
      setting.parameterValue?.toLowerCase() === "true"
  );

  useEffect(async () => {
    i18n.changeLanguage(selectedLanguage);
  }, []);

  return (
    <div>
      <main>
        <LoaderPopup loading={loading} />
        <ErrorPopup
          closeErrorModal={closeErrorModal}
          erroModalData={erroModalData}
        />
        <SuccessPopup
          closeErrorModal={closeErrorModal}
          erroModalData={erroModalData}
        />
        <div className="main-content">
          <div className="d-flex">
            <Header
              onClick={handlebackPage}
              setShowModal={openModal}
              isHome={true}
              title={""}
              subTitle={""}
            />
          </div>
          <div className="d-flex h-70 ">
            <div className="col-5 align-self-center">
              <div className="flex">
                <div>
                  {/* <div className="text-center">
                            <p className="mb-0 font-15 font-weight-500">
                              Your Room Number
                            </p>
                            <h5 className="font-weight-700 mb-0 number-color">
                              {userData?.roomNo || "-"}
                            </h5>
                          </div> */}
                  <RoomCard roomNo={Number(userData?.roomNo) || ""} />

                  <div className="room-keycard mt-30">
                    <div className="text-center">
                      <p
                        className="font-18 font-weight-500"
                        style={{ color: "rgb(0 0 0)" }}
                      >
                        {t("Place the room keycard to make your key")}
                      </p>

                      <button
                        onClick={() => {
                          createKeyHandler();
                        }}
                        className="btn button-blue btn-lg create-key-btn"
                      >
                        <i className="bx bxs-key mr-5"></i>
                        {t("Create Key")}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="text-center mt-20">
                  <div className="or-line-center">
                    <span>{t("OR")}</span>
                  </div>
                </div>
                {enableDigitalKey ? (
                  <>
                    <div className="room-keycard mt-20">
                      <div className="text-center">
                        <p className="font-14 font-weight-500">
                          {t("If you are already using the Rewards App")}
                          {t("and wish to only use a digital key.")}
                        </p>
                        <button
                          onClick={skipKey}
                          className="btn btn-lg button-blue create-key-btn "
                        >
                          <i className="bx bx-mobile-alt mr-5"></i>
                          {t("Skip")}
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="room-keycard mt-20">
                      <div className="text-center">
                        <button
                          className="btn btn-lg button-blue download-key-btn"
                          onClick={skipKey}
                        >
                          <i className="bx bx-mobile-alt mr-5"></i>
                          {t("Skip")}
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="col-7 align-self-center">
              <LottieHelper animationData="key_card.json" />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CreateKey;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import baseApi from "../../network/baseApi";
import { postFaceLiveliness } from "../../network/apiService";

export const faceVerificationCall = createAsyncThunk(
  "userFaceVerification",
  async ({imageData, transactionGuid, docImg, token, pmsConfirmationNo}) => {
    try {
      const base64Images = imageData.map((image) => image.split(",")[1]); // Remove the prefix
      const requestBody = {
        model: {
          code: 0,
          images: base64Images,
        },
        guid: transactionGuid,
        documentFaceImage: docImg,
        pmsConfirmation : pmsConfirmationNo,
      };
      const response = await baseApi.post(`api/Document/VerifyFaceImage`, requestBody, {
        headers: { Authorization: `Bearer ${token}` },
      });
      
      return response.data;
    } catch (error) {
      console.error("Error verifying face:", error?.error);
      throw error;
    }
  }
);

export const faceLivelinessCall = createAsyncThunk(
  "userFaceLiveliness",
  async ({imageData, pmsConfirmationNo}) => {
    try {
      const base64Images = imageData.map((image) => image.split(",")[1]); // Remove the prefix
      const requestBody = {    
        Image: base64Images,
        url : pmsConfirmationNo,
      };
        

      var response = await postFaceLiveliness(requestBody);
      
      return response.data;
    } catch (error) {
      console.error("Error verifying face:", error?.error);
      throw error;
    }
  }
);


const initialState = {
  faceData: { loadingFace: false, faceResponse: null,faceLivelinessResponse: null, error: "" , completedFace: false, completedLiveliness: false}
};

const faceVerificationSlice = createSlice({
  name: "faceverification",
  initialState,
  reducers: {
    resetFaceData: (state) => {
      state.faceData =  { loadingFace: false, faceResponse: null, error: "" , completedFace: false, completedLiveliness: false};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(faceVerificationCall.pending, (state) => {
      state.faceData.loadingFace = true;
      state.faceData.error = "";
      state.faceData.completedFace = false;
    });
    builder.addCase(faceVerificationCall.fulfilled, (state, action) => {
      state.faceData.loadingFace = false;
      state.faceData.faceResponse = action.payload; //{"result":false,"message":"Face match failed"}
      state.faceData.error = "";
      state.faceData.completedFace = true;
    });
    builder.addCase(faceVerificationCall.rejected, (state, action) => {
      state.faceData.loadingFace = false;
      state.faceData.error = action.error.message;
      state.faceData.completedFace = true;
    });


    builder.addCase(faceLivelinessCall.pending, (state) => {
      state.faceData.loadingFace = true;
      state.faceData.error = "";
      state.faceData.completedLiveliness = false;
    });
    builder.addCase(faceLivelinessCall.fulfilled, (state, action) => {
      state.faceData.loadingFace = false;
      state.faceData.faceLivelinessResponse = action.payload; //{"result":false,"message":"Face match failed"}
      state.faceData.error = "";
      state.faceData.completedLiveliness = true;
    });
    builder.addCase(faceLivelinessCall.rejected, (state, action) => {
      state.faceData.loadingFace = false;
      state.faceData.error = action.error.message;
      state.faceData.completedLiveliness = true;
    });
  },
});

export const {
 resetFaceData
} = faceVerificationSlice.actions;
export const selectFaceVerification = (state) => state.userFaceVerification;


export default faceVerificationSlice.reducer;

import { CHECKIN_THANKYOU, KEY_THANKYOU } from "../../routes/Paths";
import { STEP_MASTER_ENUM, timerConstantValue } from "../../constants/constant";
import {
  getDeviceDetails,
  getPropertyInfo,
} from "../../store/Checkin/Scan/propertySlice";
import {
  getParametersFromLocalStorage,
  homePageHandler,
  updateTimeToDefaultCheckout,
  updateTimeToDefaultCheckout_MIWA,

} from "../../helpers/commonHelper";
import { initReactI18next, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import CreateKey from "../../components/Createkey/Createkey";
import DownloadKeyModal from "../../components/Layout/Card/DownloadKeyModal";
import Footer from "../../components/Layout/Footer";
import HomePageCard from "../../components/Layout/Card/HomePageCard";
import KeyCard from "../../components/Layout/Card/KeyCard";
import KeyFooter from "../../components/Layout/KeyFooter";
import SuccessPopup from "../../components/LoaderAndNotification/SuccessPopup";
import { encodeKey } from "../../network/apiService";
import { getModuleConfiguration } from "../../store/Checkin/moduleConfigurationSlice";
import { getPassportScanData } from "../../store/Checkin/Scan/scanPassportSlice";
import { getStepMaster } from "../../helpers/commonHelper";
import { postAuditLog } from "../../store/stageSlice";

const CreateKeyPassportScan = () => {
  const { screen } = useParams();
  const { t } = useTranslation();
  const timeLimit = timerConstantValue;
  const [showModal, setShowModal] = useState(false);
  const [showKeyModal, setShowKeyModal] = useState(false);
  const [createAdditionalKey, setCreateAdditionalKey] = useState(false);
  const [showDownloadKeyModal, setDownloadKeyModal] = useState(false);
  const [timerValue, setTimerValue] = useState(timeLimit);
  const [restartTimer, setRestartTimer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [erroModalData, setErrorModalData] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const userData = useSelector(getPassportScanData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = getParametersFromLocalStorage("token");

  const stepMaster = useSelector((state) => getModuleConfiguration(state));
  const keyPage = getStepMaster(stepMaster, STEP_MASTER_ENUM.KEY_PAGE);

  const [showPage, setShowPage] = useState(keyPage);
  const propertyData = useSelector(getPropertyInfo);
  const deviceDetails = getParametersFromLocalStorage("DeviceInfo");
  const isFaceMatchEnabled = getParametersFromLocalStorage("faceMatch");

  const resetTimerAndClosePopUp = () => {
    if (!timerValue) {
      setTimerValue(timeLimit);
    }
    setRestartTimer(true);
    setShowModal(false);
  };

  const timerCounter = (value) => {
    setTimerValue(value);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const keyModal = () => {
    setShowKeyModal(false);
  };

  const closeKeyModal = () => {
    setShowKeyModal(false);
    doneDownloadKey();
    return;
  };

  const openDownloadKey = () => {
    setDownloadKeyModal(true);
  };

  const closeDownloadKey = () => {
    setDownloadKeyModal(false);
  };

  const doneDownloadKey = () => {
   
    setTimeout(() => {
      setSuccessPopup(false);
      if (screen === "key") {
        navigate(`${KEY_THANKYOU}`, { replace: true });
      } else {
        navigate(`${CHECKIN_THANKYOU}`, { replace: true });
      }
    }, 2000);
  };

  const skipKey = () => {
    setSuccessMessage(t("Skipping key encoding.."));
    setSuccessPopup(true);
    doneDownloadKey();
  };

  const stopTimer = () => {
    setRestartTimer(false);
  };

  const closeErrorModal = () => {
    setErrorModalData((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };
  const [keyCreationCount, setKeyCreationCount] = useState(0);
  const totalGuestCount = parseInt(
    localStorage.getItem("totalGuestCount") || 0
  );

  const createKeyHandler = async () => {
    try {
      setLoading(true);
      const kiosk = getParametersFromLocalStorage("Kiosk");

      const encoderID = deviceDetails
        ? deviceDetails?.find((ele) => ele?.settingsName === "EncoderID")
        : deviceDetails;

      const encoderUrl = deviceDetails
        ? deviceDetails?.find((ele) => ele?.settingsName === "EncoderURL")
        : deviceDetails;
        
      const encoderTerminalID = deviceDetails
        ? deviceDetails?.find(
            (ele) => ele?.settingsName === "EncoderTerminalID"
          )
        : deviceDetails;


      const encoderPort = deviceDetails
        ? deviceDetails?.find((ele) => ele?.settingsName === "EncoderPort")
        : deviceDetails;

      const encoderUsername = deviceDetails
        ? deviceDetails?.find((ele) => ele?.settingsName === "EncoderUsername")
        : deviceDetails;

      const encoderPassword = deviceDetails
        ? deviceDetails?.find((ele) => ele?.settingsName === "EncoderPassword")
        : deviceDetails;
        const encoderProperty = deviceDetails
        ? deviceDetails?.find((ele) => ele?.settingsName === "EncoderProperty")
        : deviceDetails;
      
        const settings = getParametersFromLocalStorage("settings");
      const checkOutTime = settings?.find(
          (setting) =>
            setting.parameterName === "CheckOutTime")?.parameterValue ?? '12:00 AM';

       
      const maxKeyCount = settings?.find(
          (setting) =>
            setting.parameterName === "MaxKeyCount")?.parameterValue ?? (totalGuestCount);

      const dtt =  checkOutTime?.replace(" ","")?.replace("AM","")?.replace("PM","")?.split(":");
      
      
      // const departureDate = userData?.departureDate; 

      // // Extract the date part (YYYY-MM-DD) from the departureDate
      // const [datePart, _] = departureDate.split(' ');
      
      // // Combine the date part with the desired check-out time
      // const fullDateTimeString = `${datePart} ${dtt[0]}:${dtt[1]}`; // Use hours and minutes from checkOutTime
      
      // // Convert the combined string into a Date object
      // const checkOutDtTime = new Date(`${fullDateTimeString} UTC`).toISOString();  // Ensure it's treated as UTC
      
      // console.log(checkOutDtTime);  // Example output: "2024-07-09T11:00:00.000Z"

      console.log("checkOut Time :  ",checkOutTime);
      console.log("Departure date :  ",userData?.departureDate);
      let checkOutDtTime;
      if(propertyData.data.keyVendor=='MIWA')
      {
         checkOutDtTime = updateTimeToDefaultCheckout_MIWA(userData?.departureDate, dtt[0]) //commneted as checkout time is changing to 2PM when set as 11 AM   

      }
      else
      {
         checkOutDtTime = updateTimeToDefaultCheckout(userData?.departureDate, dtt[0]) //commneted as checkout time is changing to 2PM when set as 11 AM   

      }
      //const checkOutDtTime = updateTimeToDefaultCheckout(userData?.departureDate, dtt[0]) //commneted as checkout time is changing to 2PM when set as 11 AM   
      
      console.log("checkOutDtTime :  ",checkOutDtTime);

      const sendData = {
        confirmationNo: userData?.pmsConfirmationNo,
        roomNumber: userData?.roomNo,
        guestName: userData?.lastName,
        arrivalDate: userData?.arrivalDate,
        departureDate: userData?.departureDate,
        workStation: kiosk,
        encoderID: encoderID?.settingsValue ?? "",
        terminalID: encoderTerminalID?.settingsValue,
        //keyEncoderProperty: "Main",
        keyEncoderProperty:encoderProperty?.settingsValue,
        reservationNo: userData?.pmsConfirmationNo,
        isNewKey: createAdditionalKey === true ? false : true,
        checkinDateTime: userData?.arrivalDate,
        checkoutDateTime: checkOutDtTime,
        uRL: encoderUrl?.settingsValue ?? "",
        encoderPort:
          encoderPort?.dataType == "int"
            ? parseInt(encoderPort?.settingsValue ?? "1")
            : encoderPort?.settingsValue ?? "",
        encoderUsername: encoderUsername?.settingsValue,
        encoderPassword: encoderPassword?.settingsValue,
      };

      addLog(
        "",
        JSON.stringify({
          requestData: sendData,              // Request payload
          keyVendor: propertyData.data.keyVendor, // Additional request data
          token: token                         // Token used for authentication
        }),
         `Key Encoding Request for PMSConfirmationNo: ${userData?.pmsConfirmationNo}`
      );
      //if (createAdditionalKey) setShowKeyModal(false);

      const response = await encodeKey(
        sendData,
        token,
        propertyData.data.keyVendor
      );

      addLog(
        JSON.stringify(response),
        JSON.stringify({
          requestData: sendData,              // Request payload
          keyVendor: propertyData.data.keyVendor, // Additional request data
          token: token                         // Token used for authentication
        }),
         `Key Encoding Request for PMSConfirmationNo: ${userData?.pmsConfirmationNo}`
      );

      if (response?.data?.result === true) {
        setCreateAdditionalKey(true);
        // Increment the count
        addLog(
          "Key encoding response " + response?.data?.result,
          JSON.stringify(sendData), 
          "Key encoding success"
        );
        localStorage.setItem("RoomPin", response?.data?.data?.pin ?? "");
        setLoading(false);

        setTimeout(() => {
          setKeyCreationCount((prevCount) => {
            const newCount = prevCount + 1;
            if (newCount <= (maxKeyCount-1)) {
              setShowKeyModal(true);
            } else {
              setSuccessMessage(t("Key Issued Successfully!!"));
              setSuccessPopup(true);
              doneDownloadKey();
            }
            return newCount;
          });
        }, 2000);

        return;
      } else {
       
        addLog(
          JSON.stringify(response),
          JSON.stringify(sendData),     
          "Key encoding response " + response?.data?.result
        );
        setLoading(false);
        setErrorModalData((state) => ({
          ...state,
          toggleError: true,
          errorMessage:
           t("Unable to encode the key. Please contact front desk for assistance."),
        }));
      }
    } catch (error) {
      addLog(
        JSON.stringify(error),
        "false",  
        "Key encoding exception " + userData?.pmsConfirmationNo
      );
      setLoading(false);
      setErrorModalData((state) => ({
        ...state,
        toggleError: true,
        errorMessage:
           t("Unable to encode the key. Please contact front desk for assistance."),
      }));
      return;
    }
  };
  const handlebackPage = () => {
    try {
      homePageHandler(dispatch, navigate);
    } catch (error) {
      console.error("Error capturing image:", error);
    }
  };
  
  const addLog = (response, request, message) => {
    const confirmationNo = localStorage.getItem("confirmationNo");
    const data = {
      ActionName: "Key Encoding",
      Message: message ?? "",
      ControllerName: "kiosk",
      Request: request ?? "",
      PMSConfirmationNo: confirmationNo,
      AppType: "kiosk",
      Response: response ?? "",
      PropertyCode: propertyData?.data?.propertyCode,
    };
    dispatch(postAuditLog({ data, token }));
  };
  
  return (
    <div>
      <CreateKey
        openModal={openModal}
        loading={loading}
        userData={userData}
        erroModalData={erroModalData}
        closeErrorModal={closeErrorModal}
        openDownloadKey={openDownloadKey}
        createKeyHandler={createKeyHandler}
        navigateTo={doneDownloadKey}
        handlebackPage={handlebackPage}
        skipKey={skipKey}
      />

      {screen === "key" ? (
        <>
          <KeyFooter
            page={"key"}
            showModal={showModal}
            openModal={openModal}
            timerCounter={timerCounter}
            timerValue={timerValue}
            restartTimer={restartTimer}
            stopTimer={stopTimer}
            timeLimit={timeLimit}
            isFaceMatchEnabled = {isFaceMatchEnabled}
          />
        </>
      ) : (
        <>
          <Footer
            page={"key"}
            showModal={showModal}
            openModal={openModal}
            timerCounter={timerCounter}
            timerValue={timerValue}
            restartTimer={restartTimer}
            stopTimer={stopTimer}
            timeLimit={timeLimit}
          />
        </>
      )}

      {showModal && (
        <HomePageCard
          showModal={showModal}
          resetTimerAndClosePopUp={resetTimerAndClosePopUp}
        />
      )}
      {showKeyModal && (
        <KeyCard closeKeyModal={closeKeyModal} keyModal={createKeyHandler} createAdditionalKey={createAdditionalKey} />
      )}
      {showDownloadKeyModal && (
        <DownloadKeyModal
          closeDownloadKey={closeDownloadKey}
          doneDownloadKey={doneDownloadKey}
        />
      )}
      {successMessage && (
        <SuccessPopup loading={successPopup} successMessage={successMessage} />
      )}
    </div>
  );
};

export default CreateKeyPassportScan;

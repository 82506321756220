import ConfirmAdultCountModal from "../../components/Layout/Card/ConfirmAdultCountModal";
import ChangeAdultCountAtFrontDeskModal from "../../components/Layout/Card/ChangeAdultCountAtFrontDeskModal";
import EarlyCheckinModal from "../Layout/Card/EarlyCheckinModal";
import ErrorPopup from "../LoaderAndNotification/ErrorPopup";
import Header from "../Layout/Header";
import LoaderPopup from "../LoaderAndNotification/LoaderPopup";
import ReservationFormTemplate from "./ReservationFormTemplate";
import SuccessPopup from "../LoaderAndNotification/SuccessPopup";
import { TitleCaseConverter } from "../Plugins/TitleCaseCoverter";
import { getPropertyInfo } from "../../store/Checkin/Scan/propertySlice";
import { getSelectedLanguage } from "../../store/Checkin/languageSlice";
import i18n from "i18next";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const ReservationForm = ({
  loading,
  loading2,
  loading3,
  reservationData,
  openModal,
  closeErrorModal,
  closeErrorModal2,
  closeErrorModal3,
  erroModalData,
  erroModalData2,
  erroModalData3,
  navigateUrl,
  updateConfirmationNo,
  page,
  getConfirmationNoOfSelectedDiv,
  propertyData2,
  disptachUserDetails,
  proceedHandler,
  earlyCheckIn,
  packageAmount,
  packageCode,
  currency,
  confirmEarlyCheckin,
  backToHomePage,
  showConfirmModal,
  showConfirmAdultCountAtFrontDeskModal,
  confirmAdultCount,
  adultCount,
  closeModal,
  fullName,
  successPopup,
  successMessage,
}) => {

  const propertyData = useSelector(getPropertyInfo);
  const { t } = useTranslation();
  const selectedLanguage = useSelector(getSelectedLanguage);

  useEffect(async () => {
    i18n.changeLanguage(selectedLanguage);
  }, []);

  return (
    <div>
      <main>
        <div className="main-content reservation-white">
          <div>
            <div>
              {fullName ? (
                <Header
                  onClick={backToHomePage}
                  setShowModal={openModal}
                  isHome={true}
                  hightlight={t("Hey")}
                  title={`${TitleCaseConverter({ text: fullName })},`}
                  subTitle={t("Please verify your reservation details")}
                />
              ) : (
                <Header
                  onClick={backToHomePage}
                  setShowModal={openModal}
                  isHome={true}
                  title={""}
                  subTitle={""}
                />
              )}
            </div>
          </div>
          <div className="reservation-info-title text-center">
            {/* {typeof reservationData === "object" &&
              !Array.isArray(reservationData) && (
                <h5>
                  <strong>
                    {nameValidation(
                      reservationData?.firstName,
                      reservationData?.lastName
                    )}
                  </strong>
                </h5>
              )} */}
            {/* {typeof reservationData === "object" &&
              !Array.isArray(reservationData) &&
              reservationData !== null && (
                <p>
                  {t(
                    "Here are your reservation details. Kindly review and confirm"
                  )}
                  {t("the information.")}
                </p>
              )} */}
            {Array.isArray(reservationData) && reservationData.length > 1 && (
              <p>
                {t("You have")} <strong>{reservationData.length}</strong>{" "}
                {t("reservations")} {t("under your name. Please select")}{" "}
                <strong>{t("one")}</strong> {t("to proceed")}
              </p>
            )}
          </div>

          <div className="card-scroll">
            {Array.isArray(reservationData) &&
              reservationData.map((userData, index) => (
                <div
                  key={userData?.pmsConfirmationNo}
                  onClick={() => {
                    getConfirmationNoOfSelectedDiv(userData?.pmsConfirmationNo);
                  }}
                  className="reservation-card-width"
                >
                  <div
                    className="card"
                    style={{
                      border:
                        userData?.pmsConfirmationNo === updateConfirmationNo
                          ? "4px solid var(--card-color)"
                          : "",
                      background:
                        userData?.pmsConfirmationNo === updateConfirmationNo
                          ? "#fbfafa"
                          : "transparent",
                    }}
                  >
                    <ReservationFormTemplate
                      reservationData={userData}
                      multipleUserData={reservationData}
                      propertyDetails={propertyData?.data}
                      t={t}
                    />
                  </div>
                </div>
              ))}
          </div>

          {typeof reservationData === "object" &&
            !Array.isArray(reservationData) &&
            reservationData !== null && (
              <div
                key={reservationData?.pmsConfirmationNo}
                className="reservation-card-width"
              >
                <div
                  className="card"
                  style={{
                    border:
                      reservationData?.pmsConfirmationNo ===
                      updateConfirmationNo
                        ? "5px solid var(--card-color)"
                        : "",
                  }}
                >
                  <ReservationFormTemplate
                    reservationData={reservationData}
                    propertyDetails={propertyData?.data}
                    t={t}
                  />
                </div>
              </div>
            )}

          <LoaderPopup loading={loading} />
          <LoaderPopup loading={loading2} />
          <LoaderPopup loading={loading3} />

          <SuccessPopup
            loading={successPopup}
            successMessage={successMessage}
          />

          <ErrorPopup
            closeErrorModal={closeErrorModal}
            erroModalData={erroModalData}
          />
          <ErrorPopup
            closeErrorModal={closeErrorModal2}
            erroModalData={erroModalData2}
          />
          <ErrorPopup
            closeErrorModal={closeErrorModal3}
            erroModalData={erroModalData3}
          />
        
        
          {showConfirmModal === true && (
            <>
              <ConfirmAdultCountModal
                showConfirmModal={showConfirmModal}
                confirmAdultCount={confirmAdultCount}
                closeModal={closeModal}
                adultCount={adultCount}
              />
            </>
          )}
           {showConfirmAdultCountAtFrontDeskModal === true && (
            <>
              <ChangeAdultCountAtFrontDeskModal
                showConfirmAdultCountAtFrontDeskModal={showConfirmAdultCountAtFrontDeskModal}
                confirmAdultCount={confirmAdultCount}
                // closeModal={closeModal}
                adultCount={adultCount}
              />
            </>
          )}

          <div className="reservation-btn-set">
            <div className="reservation-btn">
              <button
                className="btn cancel-blue-btn"
                onClick={() => {
                  openModal();
                }}
              >
                {t("Cancel")}
              </button>
            </div>
            <div className="reservation-btn">
              {typeof reservationData === "object" &&
                !Array.isArray(reservationData) &&
                reservationData !== null && (
                  <button
                    onClick={() => {
                      proceedHandler();
                    }}
                    className="btn button-blue proceed-blue-btn"
                  >
                    {t("Proceed")}
                  </button>
                )}

              {Array.isArray(reservationData) && reservationData.length > 1 && (
                <button
                  onClick={() => {
                    proceedHandler();
                  }}
                  disabled={!updateConfirmationNo}
                  className="btn button-blue proceed-blue-btn"
                >
                  {t("Proceed")}
                </button>
              )}
              {Array.isArray(reservationData) &&
                reservationData.length === 1 && (
                  <>
                    {getConfirmationNoOfSelectedDiv(
                      reservationData[0]?.pmsConfirmationNo
                    )}

                    <button
                      onClick={() => {
                        proceedHandler();
                      }}
                      className="btn button-blue proceed-blue-btn"
                    >
                      {t("Proceed")}
                    </button>
                  </>
                )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ReservationForm;

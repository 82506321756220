/* eslint-disable jsx-a11y/anchor-is-valid */

import {
  capitalizeFullName,
  formatDate,
  nameValidation,
} from "../../helpers/commonHelper";
import { useEffect, useState } from "react";

import Header from "../Layout/Header";
import PdfViewer from "./PdfViewer";
import SignaturePad from "../Forms/SignaturePad";
import { useTranslation } from "react-i18next";

const AgreedForm = ({
  openModal,
  navigateToPayment,
  savefolioCard,
  folio,
  clearSign,
  data,
  signature,
  setSignature,
  setUrl,
  url,
  showFolio,
  gotSignature,
  onBackPress,
  signatureRequired,
  showReservationPage,
  signatureFolioManadatory
}) => {
  const [containerHeight, setContainerHeight] = useState(0);
  const { t } = useTranslation();
  useEffect(() => {
    const calculateContainerHeight = () => {
      const footerHeight = document.querySelector("footer").clientHeight;
      const viewportHeight = window.innerHeight;
      setContainerHeight(`calc(${viewportHeight}px - ${footerHeight}px)`);
    };

    calculateContainerHeight();
    window.addEventListener("resize", calculateContainerHeight);

    return () => {
      window.removeEventListener("resize", calculateContainerHeight);
    };
  }, []);

  return (
    <div>
      <main>
        <div
          className="main-content"
          // style={{ height: containerHeight, overflowY: "auto" }}
        >
          <div className="h-100">
            <div className="d-flex justify-content-between">
              <div>
                <Header
                  onClick={onBackPress}
                  setShowModal={openModal}
                  isHome={true}
                  title={""}
                  subTitle={""}
                />
              </div>
            </div>
            <div className="content-bg justify-content-center" style={{marginTop:"20px"}}>
              <div className={showReservationPage === false && signatureRequired === false ? "d-flex h-70 justify-content-center":"d-flex h-100 justify-content-center"}>
                {showFolio && (
                  <div className={showReservationPage === false && signatureRequired === false ? "col-lg-7" : "col-lg-7"} >
                    
                    <PdfViewer folio={folio} />
                    
                  </div>
                )}

                <div
                  className={
                    showReservationPage === false && signatureRequired === false
                      ? "flex"
                      : "flex col-lg-5 pd-30"
                  }                 
                >
                  {showReservationPage && (
                    <div>
                      <div className="info-invoice">
                        <div>
                          <h5>{t("Reservation Details")}</h5>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-4">
                            <label className="reservation-card-label">
                              {t("Confirmation")}:
                            </label>
                            <div>
                              <p>{data?.pmsConfirmationNo}</p>
                            </div>
                          </div>
                          <div className="col-4">
                            <label className="reservation-card-label">
                              {t("Guest Name")}:
                            </label>
                            <div>
                              <p>
                                {data?.firstName + " " + data?.lastName || ""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-4">
                            <label className="reservation-card-label">
                              {t("Arrival Date:")}
                            </label>
                            <div>
                              <p>{formatDate(data?.arrivalDate)}</p>
                            </div>
                          </div>
                          <div className="col-4">
                            <label className="reservation-card-label">
                              {t("Departure Date:")}
                            </label>
                            <div>
                              <p>{formatDate(data?.departureDate) || ""}</p>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <label className="reservation-card-label">
                              {t("Room Number")}:
                            </label>
                            <div>
                              <p>{Number(data?.roomNo)}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {signatureRequired === true && (
                    <>
                      <div className="flex">
                        

                        <h5
                            style={{
                              color: "var(--secondary-color)",
                              marginBottom: "40px",
                              marginTop: "84px",
                            }}
                          >
                            {t("Agree by signing here")}
                          </h5>
                          <div className="sign-wrapper">
                            <div style={{ marginRight: "10px" }}>
                              <button
                                onClick={clearSign}
                                className="terms-retry-btn-folio"
                              >
                                <i className="bx bx-revision"></i>
                              </button>
                            </div>
                            <SignaturePad
                              signature={signature}
                              setSignature={setSignature}
                              gotSignature={gotSignature}
                              setUrl={setUrl}
                              url={url}
                              showRetryBtn={false}
                            />
                          </div>
                      </div>
                      <div>
                        <button
                          disabled={signatureFolioManadatory && !url}
                          onClick={savefolioCard}
                          className="btn button-blue proceed-blue-btn"
                          style={{
                            float: "right",
                            marginTop: "50px",
                            marginLeft:"30px"
                          }}
                        >
                          {t("Proceed")}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div> 
              {showReservationPage === false && signatureRequired === false && (
                <>
                  <button
                    onClick={savefolioCard}
                    className="btn button-blue proceed-blue-btn"
                    style={{
                      float: "left",
                      // marginTop: "8%",
                      marginLeft:"46%",
                      marginBottom:"5%"
                    }}
                  >
                    {t("Proceed")}
                  </button>
                </>
              )}             
            </div>
         
          </div>
        </div>
      </main>
    </div>
  );
};

export default AgreedForm;

/* eslint-disable jsx-a11y/alt-text */

import { CHECKIN_THANKYOU, HOME } from "../../routes/Paths";
import {
  CONFIG_MASTER_ENUM,
  SESSION_ENUM,
  STEP_MASTER_ENUM,
  thankyouPageTimerValue,
} from "../../constants/constant";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import {
  getConfigurationCode,
  getParametersFromLocalStorage,
  getStepMaster,
  homePageHandler,
  removeParametersFromLocalStorage,
} from "../../helpers/commonHelper";

import DownloadKeyModal from "../../../src/components/Layout/Card/DownloadKeyModal";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import HomePageCard from "../Layout/Card/HomePageCard";
import LinkImg from "../../assets/images/tick.png";
import LoaderPopup from "../LoaderAndNotification/LoaderPopup";
import { RoomCard } from "../Layout/Card/RoomCard";
import SendMessage from "../Forms/SendMessage";
import SuccessPopup from "../LoaderAndNotification/SuccessPopup";
import { getMailDetails } from "../../store/Checkin/Scan/scanPassportSlice";
import { getModuleConfiguration } from "../../store/Checkin/moduleConfigurationSlice";
import { getSelectedLanguage } from "../../../src/store/Checkin/languageSlice";
import { resetScanDocumentGuestCount } from "../../store/Checkin/Scan/documentScanSlice";
import { sendCheckinMail } from "../../network/apiService";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { userReservationDetailsConfirmatioNo } from "../../store/Checkin/ConfirmationNumber/confirmationNumberSlice";
import { userReservationDetailsPassport } from "../../../src/store/Checkin/Scan/scanPassportSlice";
import { userReservationDetailsQr } from "../../store/Checkin/QrCode/qrCodeSlice";

const Thankyou = ({ userData, roomPin }) => {
  const timeLimit = thankyouPageTimerValue;
  const [showModal, setShowModal] = useState(false);
  const [timerValue, setTimerValue] = useState(timeLimit);
  const [restartTimer, setRestartTimer] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDownloadKeyModal, setDownloadKeyModal] = useState(false);
  const token = getParametersFromLocalStorage("token");
  const [loading, setLoading] = useState(false);
  const [emailValidation, setEmailValidation] = useState(false);
  const [phoneValidation, setPhoneValidation] = useState(false);
  const [successLoader, setSuccessLoader] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const { t } = useTranslation();
  const selectedLanguage = useSelector(getSelectedLanguage);
  const [thankyouMessage, setThankyouMessage] = useState();

  const [counter, setCounter] = useState(0);
  const stepMaster = useSelector((state) => getModuleConfiguration(state));
  const stepEmailPage = getStepMaster(
    stepMaster,
    STEP_MASTER_ENUM.CHECKIN_THANKYOU_PAGE
  );
  const configs = stepEmailPage?.configs;
  const emailRequired = getConfigurationCode(
    configs,
    CONFIG_MASTER_ENUM.EMAIL_REQUIRED
  );
  const emailAutoSend = getConfigurationCode(
    configs,
    CONFIG_MASTER_ENUM.AUTO_EMAIL
  );
  //const emailRequired = true;
  const smsRequired = getConfigurationCode(
    configs,
    CONFIG_MASTER_ENUM.SMS_REQUIRED
  );
  const whatsappRequired = getConfigurationCode(
    configs,
    CONFIG_MASTER_ENUM.WHATSAPP_REQUIRED
  );
  const printRequired = getConfigurationCode(
    configs,
    CONFIG_MASTER_ENUM.PRINT_REQUIRED
  );

  const preCheckin = getParametersFromLocalStorage(SESSION_ENUM.PRECHECKIN);
  const profileData = useSelector(getMailDetails);
  const openDownloadKey = () => {
    setDownloadKeyModal(true);
  };

  const closeDownloadKey = () => {
    setDownloadKeyModal(false);
  };

  const doneDownloadKey = () => {
    handlebackPage();
  };
  // useEffect(() => {

  //   if (
  //     emailAutoSend !== null &&
  //     emailAutoSend !== undefined &&
  //     emailAutoSend !== false &&
  //     profileData !== null &&
  //     (profileData.mail !== null || profileData.email !== "")
  //   ) {
  //     updateEmailHandler(profileData?.mail, "", false, "email");
  //   } else if (
  //     emailAutoSend !== null &&
  //     emailAutoSend !== undefined &&
  //     emailAutoSend !== false &&
  //     userData !== null &&
  //     (userData?.mail !== null || userData?.email !== "")
  //   ) {
  //     updateEmailHandler(userData?.mail, "", false, "email");
  //   }
  // }, [emailAutoSend]);
  useEffect(() => {
    if (
      emailRequired !== null &&
      emailRequired !== undefined &&
      emailRequired !== false
    ) {
      setCounter((prevCount) => prevCount + 1);
    }
    if (
      smsRequired !== null &&
      smsRequired !== undefined &&
      smsRequired !== false
    ) {
      setCounter((prevCount) => prevCount + 1);
    }
    if (
      whatsappRequired !== null &&
      whatsappRequired !== undefined &&
      whatsappRequired !== false
    ) {
      setCounter((prevCount) => prevCount + 1);
    }

    if (preCheckin) {
      setThankyouMessage(
        t("Our mission control crew will contact you once your room is ready")
      );
    } else {
      setThankyouMessage(t("Thank you for using self check-in counter"));
    }
  }, []);

  const timerCounter = (value) => {
    setTimerValue(value);
  };
  const resetTimerAndClosePopUp = () => {
    if (!timerValue) {
      setTimerValue(timeLimit);
    }
    setRestartTimer(true);
    setShowModal(false);
  };
  const openModal = () => {
    setShowModal(true);
  };

  const stopTimer = () => {
    setRestartTimer(false);
  };
  const handlebackPage = () => {
    try {
      homePageHandler(dispatch, navigate);
    } catch (error) {
      console.error("Error capturing image:", error);
    }
  };
  const updateEmailHandler = async (
    email,
    phone,
    counter1,
    communicationType
  ) => {
    setLoading(true);
    let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (communicationType === "email") {
      if (email !== null && email !== undefined && !mailFormat.test(email)) {
        setEmailValidation(true);
        return;
      } else {
        setSuccessMessage(t("Mail sent successfully!!"));
      }
    } else {
      if (phone !== null && phone !== undefined) {
        if (phone === "") {
          setPhoneValidation(true);
          return;
        }
        setSuccessMessage(t("Message sent successfully!!"));
      }
    }

    const response = await sendCheckinMail(
      {
        EmailAddress: email,
        PhoneNumber: phone,
        ConfirmationNo: userData?.pmsConfirmationNo,
        lan: selectedLanguage,
        communicationType: communicationType,
      },
      token
    );

    if (response !== null && response?.data?.result === true) {
      setLoading(false);
      setSuccessLoader(true);
      setTimeout(() => {
        setSuccessLoader(false);
        setLoading(false);
        if (counter === 1) {
          handlebackPage();
        }
      }, 3000);
    } else {
      setLoading(false);
    }

    return;
  };
  return (
    <div>
      <main>
        <div className="main-content">
          <div>
            <div>
              <Header
                onClick={handlebackPage}
                setShowModal={openModal}
                isHome={true}
                title={""}
                subTitle={""}
              />
            </div>
          </div>
          <div
            className={
              preCheckin
                ? "d-flex justify-center-center h-100"
                : "d-flex justify-center-center"
            }
            style={{ justifyContent: "center" }}
          >
            <div className="col-4 col-sm-6 col-md-6" style={{ margin: "auto" }}>
              <div
                className="thank-you-box"
                style={{
                  padding: preCheckin ? "50px" : "35px",
                  margin: "auto",
                  marginTop: preCheckin ? "-100px" : "0px",
                }}
              >
                <div>
                  <img src={LinkImg} style={{ width: "60px" }} />
                </div>

                {/* <div>
              <img src={QrImage} className="thank-qr-img" />
            </div> 
            className="mt-30 font-weight-500 font-14"
            */}
                {/* <img
                src={LinkImg}
                className="thank-logo-img"
              /> */}

                <div className="mt-10 font-weight-500 font-12">
                  {preCheckin === null ||
                  preCheckin === undefined ||
                  preCheckin === false ? (
                    <>
                      <p>
                        {t(thankyouMessage)
                          .split("\n")
                          .map((line, index) => (
                            <React.Fragment key={index}>
                              {line}
                              <br />
                            </React.Fragment>
                          ))}
                        <br />
                      </p>
                    </>
                  ) : (
                    <>
                      <br />
                      <h3>
                        {t(thankyouMessage)
                          .split("\n")
                          .map((line, index) => (
                            <React.Fragment key={index}>
                              {line}
                              <br />
                            </React.Fragment>
                          ))}
                        <br />
                      </h3>
                    </>
                  )}

                  {(preCheckin === null ||
                    preCheckin === undefined ||
                    preCheckin === false) && (
                    <>
                      <div className="mb-10">
                        <RoomCard roomNo={Number(userData?.roomNo) || ""} />
                      </div>
                    </>
                  )}

                  {(preCheckin === null ||
                    preCheckin === undefined ||
                    preCheckin === false) &&
                    roomPin &&
                    roomPin !== "undefined" &&
                    roomPin !== null &&
                    roomPin !== undefined && (
                      <div className="mb-10">
                        <h1 style={{ fontWeight: "bold" }}>
                          {" "}
                          {t("Room Pin")}: {roomPin ?? "-"}
                        </h1>
                      </div>
                    )}
                </div>
              </div>
              {(preCheckin === null ||
                preCheckin === undefined ||
                preCheckin === false) && (
                <>
                  <div className="message-wrapper ">
                    <SendMessage
                      updateEmailHandler={updateEmailHandler}
                      emailValidation={emailValidation}
                      title="thankyou"
                      subtitle={t("Please provide details")}
                      phoneValidation={phoneValidation}
                      successLoader={successLoader}
                      loading={loading}
                      successMessage={successMessage}
                      emailRequired={emailRequired}
                      smsRequired={smsRequired}
                      whatsappRequired={whatsappRequired}
                      printRequired={printRequired}
                      emailAutoSend ={emailAutoSend}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          {/* <button className="btn btn-lg button-blue skip-key-btn"  onClick={handlebackPage}>
            <i className="bx bx-mobile-alt mr-5"></i>
              {t("Skip")}
          </button> */}
        </div>

        {showDownloadKeyModal && (
          <DownloadKeyModal
            closeDownloadKey={closeDownloadKey}
            doneDownloadKey={doneDownloadKey}
          />
        )}
        <LoaderPopup loading={loading} />

        <Footer
          page={"thankyou"}
          showModal={showModal}
          openModal={openModal}
          timerCounter={timerCounter}
          timerValue={timerValue}
          restartTimer={restartTimer}
          stopTimer={stopTimer}
          timeLimit={timeLimit}
        />
      </main>
      {showModal && (
        <HomePageCard
          showModal={showModal}
          resetTimerAndClosePopUp={resetTimerAndClosePopUp}
        />
      )}
    </div>
  );
};

export default Thankyou;

import { Box, Modal, Typography } from "@mui/material";
import {
  PopupCitySection,
  PopupComplementSection,
  PopupNeighborhoodSection,
  PopupNumberofResidenceSection,
  PopupPincodeSection,
  PopupStateSection,
  PopupStreetSection,
} from "./UserReservationFields";
import React, { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { getParametersFromLocalStorage } from "../../helpers/commonHelper";
import { getPostalCodeDetails } from "../../store/Checkin/reservationDetailsSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const UserReservationAddressDetails = ({
  loading,
  closeModalPopup,
  // searchPinCode,
  addressData,
  //enterPincodeValidation,
  numberofResidence,
  addressChangeHandler0,
  confirmationDetails,
  onError,
  onLoader,
  onSubmit
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = getParametersFromLocalStorage("token");

  const popupList = getParametersFromLocalStorage("PopupList");
  const [pincode, setPincode] = useState({ status: false, isMandatory: false });
  const [address1, setAddress1] = useState({
    status: false,
    isMandatory: false,
  });
  const [address2, setAddress2] = useState({
    status: false,
    isMandatory: false,
  });
  const [neighborhood, setNeighborhood] = useState({
    status: false,
    isMandatory: false,
  });
  const [complement, setComplement] = useState({
    status: false,
    isMandatory: false,
  });
  const [city, setCity] = useState({ status: false, isMandatory: false });
  const [state, setState] = useState({ status: false, isMandatory: false });
  const [isDisable, setIsDisable] = useState(false);
  const [isClear, setIsClear] = useState(false);
  const [isDisableContinue, setIsDisableContinue] = useState(true);


  const [addressDataParam, setAddressData] = useState({

    pincode: "",
    numberofResidence: "",
    neighborhood: "",
    street: "",
    state: "",
    city: "",
    complement: "",
  });
  const addressChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setAddressData((preState) => ({ ...preState, [name]: value }));
    if (
      (pincode?.isMandatory === false || (pincode?.isMandatory === true && addressDataParam.pincode != null && addressDataParam.pincode?.trim() !== "") || (pincode?.isMandatory === true && e.target.name == "pincode" && e.target.value.trim() !== "")) &&
      (neighborhood?.isMandatory === false || (neighborhood?.isMandatory === true && addressDataParam.neighborhood != null && addressDataParam.neighborhood?.trim() !== "") || (neighborhood?.isMandatory === true && e.target.name == "neighborhood" && e.target.value != "")) &&
      (city?.isMandatory === false || (city?.isMandatory === true && addressDataParam.city != null && addressDataParam.city?.trim() !== "") || (city?.isMandatory === true && e.target.name == "city" && e.target.value != "")) &&
      (state?.isMandatory === false || (state?.isMandatory === true && addressDataParam.state != null && addressDataParam.state?.trim() !== "") || (state?.isMandatory === true && e.target.name == "state" && e.target.value != "")) &&
      (complement?.isMandatory === false || (complement?.isMandatory === true && addressDataParam.complement != null && addressDataParam.complement?.trim() !== "") || (complement?.isMandatory === true && e.target.name == "complement" && e.target.value != "")) &&
      (address1?.isMandatory === false || (address1?.isMandatory === true && addressDataParam.street != null && addressDataParam.street?.trim() !== "") || (address1?.isMandatory === true && e.target.name == "street" && e.target.value != "")) &&
      (address2?.isMandatory === false || (address2?.isMandatory === true && addressDataParam.numberofResidence?.trim() !== ""))
    ) {
      setIsDisableContinue(false);
    }
    else {
      setIsDisableContinue(true);
    }

  };

  // Function to update a specific field
  const updateAddressData = (field, value) => {
    setAddressData((preState) => ({ ...preState, [field]: value }));
  };
  useEffect(() => {
    popupList.forEach((element) => {
      if (element.fieldCode.toLowerCase() === "pincode") {
        setPincode({ status: true, isMandatory: element.isMandatory });
      }
      if (element.fieldCode.toLowerCase() === "addressline1") {
        setAddress1({ status: true, isMandatory: element.isMandatory });
      }
      if (element.fieldCode.toLowerCase() === "neighborhood") {
        setNeighborhood({ status: true, isMandatory: element.isMandatory });
      }
      if (element.fieldCode.toLowerCase() === "addressline2") {
        setAddress2({ status: true, isMandatory: element.isMandatory });
      }
      if (element.fieldCode.toLowerCase() === "complement") {
        setComplement({ status: true, isMandatory: element.isMandatory });
      }
      if (element.fieldCode.toLowerCase() === "city") {
        setCity({ status: true, isMandatory: element.isMandatory });
      }
      if (element.fieldCode.toLowerCase() === "state") {
        setState({ status: true, isMandatory: element.isMandatory });
      }
    });
  }, []);

  const onSearchClick = () => {
    fetchPostalDetails();
    //searchPinCode();    
  };

  const fetchPostalDetails = () => {
    if (!addressDataParam || !addressDataParam.pincode) {
      onError(t("Please Enter the Pincode to Proceed"));
      setIsClear(false);
      setIsDisable(false);
      setIsDisableContinue(true);
      return;
    }
    onLoader(true);
    dispatch(
      getPostalCodeDetails({
        postalCode: addressDataParam.pincode,
        token: token,
      })
    )
      .unwrap()
      .then((rep) => {
        postAddressInfo(rep);
        setIsClear(true);
        setIsDisable(true);
        onLoader(false);
      })
      .catch((error) => {
        onLoader(false);
        setIsClear(true);
        onError(t("Please Enter a Valid Pincode"));
        setIsDisableContinue(true);
        return;
      });
  };

  const onAddressSubmit = () => {
    onSubmit(addressDataParam);
  }

  useEffect(() => {
    if (confirmationDetails !== null) {
      updateAddressData("pincode", confirmationDetails.pincode);
      updateAddressData(
        "numberofResidence",
        confirmationDetails.addressLine2
      );
      updateAddressData("neighborhood", confirmationDetails.neighborhood);
      updateAddressData("street", confirmationDetails.addressLine1);
      updateAddressData("state", confirmationDetails.state);
      updateAddressData("city", confirmationDetails.city);
      updateAddressData("complement", confirmationDetails.complement);
      if ((confirmationDetails.pincode != null) && (confirmationDetails.pincode?.trim() !== "")) {
        setIsClear(true);
      }
      else {
        setIsClear(false);
      }
      if (((confirmationDetails.pincode !== null) && (confirmationDetails.pincode?.trim() !== "")) && ((confirmationDetails.addressLine1 !== null) && (confirmationDetails.addressLine1?.trim() !== "")) &&
        ((confirmationDetails.neighborhood !== null) && (confirmationDetails.neighborhood?.trim() !== "")) && ((confirmationDetails.state !== null) && (confirmationDetails.state?.trim() !== ""))
        && ((confirmationDetails.city !== null) && (confirmationDetails.city?.trim() !== "")) &&
        ((confirmationDetails.street !== null) && (confirmationDetails.street?.trim() !== ""))) {
        setIsDisableContinue(false);
        setIsDisable(true);

      }
      else {
        setIsDisable(false);

      }

    }
    if (addressData !== null) {
      updateAddressData("pincode", addressData.pincode);
      updateAddressData(
        "numberofResidence",
        addressData.numberofResidence
      );
      updateAddressData("neighborhood", addressData.neighborhood);
      updateAddressData("street", addressData.street);
      updateAddressData("state", addressData.state);
      updateAddressData("city", addressData.city);
      updateAddressData("complement", addressData.complement);

      if ((addressData.pincode != null) && (addressData.pincode?.trim() !== "")) {
        setIsClear(true);
      }
      else {
        setIsClear(false);
      }
      if (((addressData.pincode !== null) && (addressData.pincode?.trim() !== "")) &&
        ((addressData.neighborhood !== null) && (addressData.neighborhood?.trim() !== "")) && ((addressData.state !== null) && (addressData.state?.trim() !== "")) &&
        ((addressData.city !== "") && (addressData.city?.trim() !== "")) && ((addressData.complement !== "") && (addressData.complement?.trim() !== "")) &&
        ((addressData.street !== null) && (addressData.street?.trim() !== ""))) {
        setIsDisableContinue(false);
        setIsDisable(true);

      }
      else {
        setIsDisable(false);

      }

    }
  }, []);



  const postAddressInfo = (adddress) => {
    updateAddressData("pincode", adddress.pincode);
    updateAddressData(
      "numberofResidence",
      adddress.numberofResidence
    );
    updateAddressData("neighborhood", adddress.neighborhood);
    updateAddressData("street", adddress.street);
    updateAddressData("state", adddress.state);
    updateAddressData("city", adddress.city);
    updateAddressData("complement", adddress.complement);
    if (((adddress.pincode !== null) && (adddress.pincode?.trim() !== "")) &&
    ((adddress.neighborhood !== null) && (adddress.neighborhood?.trim() !== "")) &&
    ((adddress.state !== null) && (adddress.state?.trim() !== "")) &&
    ((adddress.city !== null) && (adddress.city?.trim() !== "")) && 
    ((adddress.complement !== null) && (adddress.complement?.trim() !== "")) &&
    ((adddress.street !== null) && (adddress.street?.trim() !== ""))) {

      setIsDisableContinue(false);
      setIsDisable(true);

    }
    else {
      setIsDisable(false);

    }

  }


  const clearData = () => {
    updateAddressData("pincode", "");
    updateAddressData("numberofResidence", "");
    updateAddressData("neighborhood", "");
    updateAddressData("street", "");
    updateAddressData("state", "");
    updateAddressData("city", "");
    updateAddressData("complement", "");
    // confirmationDetails.pincode = "";
    // confirmationDetails.numberofResidence = "";
    // confirmationDetails.neighborhood = "";
    // confirmationDetails.street = "";
    // confirmationDetails.state = "";
    // confirmationDetails.city = "";
    // confirmationDetails.complement = "";
    //setIsDisable(false);
    setIsClear(false);
    setIsDisable(false);
    setIsDisableContinue(true);

  };
  return (
    <Modal open={loading} style={{ zIndex: "1" }}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "770px",
          backgroundColor: "#f5f5f5",
          border: "2px #000",
          boxShadow: "0 0 24px rgba(0, 0, 0, 0.5)",
          borderRadius: "20px",
        }}
      >
        {/* <button className="close-icon" onClick={closeModalPopup}>
          <CloseIcon
            style={{ float: "right", color: "white" }}
            onClick={closeModalPopup}
          />
        </button> */}

        <div className="flex" style={{ padding: "50px" }}>
          {/* <Typography
      id="modal-modal-description"
      sx={{ mt: 2, fontSize: "1.8rem"}}
    >
      {"Address Details"}<hr/>
    </Typography> */}
          <div style={{ textAlign: "left" }}>
            <div
              className="card"
              style={{
                //"transparent"
                border: "3px solid #ddd",
                background: "transparent",
              }}
            >
              <div className="card-header">
                <h5 className="card-title">{t("Search Pincode")}</h5>
              </div>
              <div className="card-body">
                {pincode.status && (
                  <PopupPincodeSection
                    isMandatory={pincode.isMandatory}
                    addressChangeHandler={addressChangeHandler}
                    addressData={addressDataParam}
                    searchPinCode={onSearchClick}
                    isDisable={isDisable}
                    clearData={clearData}
                    confirmationDetails={confirmationDetails}
                    isClear={isClear}
                  />
                )}
              </div>
            </div>
            <br />
            <div
              className="card"
              style={{
                //"transparent"
                border: "3px solid #ddd",
                background: "transparent",
              }}
            >
              <div className="card-header">
                <h5 className="card-title">{t("Address Details")}</h5>{" "}
                {/* Card header with a title */}
              </div>
              <div className="card-body">
                {address1.status && (
                  <PopupStreetSection
                    isMandatory={address1.isMandatory}
                    addressChangeHandler={addressChangeHandler}
                    addressData={addressDataParam}
                    confirmationDetails={confirmationDetails}
                    isClear={isClear}
                    searchPinCode={onSearchClick}
                  />
                )}
                {address2.status && (
                  <PopupNumberofResidenceSection
                    isMandatory={address2.isMandatory}
                    addressChangeHandler={addressChangeHandler}
                    numberofResidence={addressDataParam}
                    confirmationDetails={confirmationDetails}
                    addressData={addressDataParam}
                  />
                )}
                {neighborhood.status && (
                  <PopupNeighborhoodSection
                    isMandatory={neighborhood.isMandatory}
                    addressChangeHandler={addressChangeHandler}
                    confirmationDetails={confirmationDetails}
                    addressData={addressDataParam}
                  />
                )}
                {complement.status && (
                  <PopupComplementSection
                    isMandatory={complement.isMandatory}
                    addressChangeHandler={addressChangeHandler}
                    confirmationDetails={confirmationDetails}
                    addressData={addressDataParam}
                  />
                )}
                {city.status && (
                  <PopupCitySection
                    isMandatory={city.isMandatory}
                    addressChangeHandler={addressChangeHandler}
                    addressData={addressDataParam}
                    isDisable={isDisable}
                  />
                )}
                {state.status && (
                  <PopupStateSection
                    isMandatory={state.isMandatory}
                    addressChangeHandler={addressChangeHandler}
                    addressData={addressDataParam}
                    isDisable={isDisable}
                  />
                )}

                <div style={{ marginTop: "30px" }}>
                  <button
                    className="btn button-blue"
                    type="button"
                    disabled={isDisableContinue}
                    onClick={onAddressSubmit}
                    style={{ marginTop: "", alignItems: "end", width: "100%", fontSize: "20px" }}
                  >
                    {(confirmationDetails.pincode !== null && confirmationDetails.pincode !== "" &&
                      confirmationDetails.city !== null && confirmationDetails.city !== "" &&
                      confirmationDetails.state !== null && confirmationDetails.state !== "")
                      ? t("Update")
                      : t("Save")}
                  </button>
                </div>
                <div style={{ marginTop: "20px" }}>
                  {<span style={{ color: "red" }}>*</span>}{t("Please fill in all mandatory fields.")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default UserReservationAddressDetails;

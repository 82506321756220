/* eslint-disable jsx-a11y/alt-text */

import {
  fetchModulesConfiguration,
  moduleConfigurationInfo,
} from "../../store/Checkin/moduleConfigurationSlice";
import {
  fetchPropertyInfo,
  setDeviceConfiguration,
  setStepMasterInfo,
} from "../../store/Checkin/Scan/propertySlice";
import {
  getFieldList,
  getKioskConfiguration,
  getKioskData,
  getSettingList,
  getStepWithModules,
} from "../../network/apiService";
import {
  getStepMaster,
  saveTokenInSessionStorage,
} from "../../helpers/commonHelper";
import { initReactI18next, useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import Backend from "i18next-http-backend";
import ErrorPopup from "../../components/LoaderAndNotification/ErrorPopup";
import { HOME } from "../../routes/Paths";
import LanguageDetector from "i18next-browser-languagedetector";
import LoaderPopup from "../../components/LoaderAndNotification/LoaderPopup";
import ReactDOM from "react-dom";
import { getLanguageMaster } from "../../network/apiService";
import i18n from "i18next";
import i18nextXHRBackend from "i18next-xhr-backend";
import { login } from "../../store/AuthSlice";
import { loginApi } from "../../network/apiService";
import { saveParametersIntoLocalStorage } from "../../helpers/commonHelper";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useState,useRef } from "react";
import SoftKeyboard from "../../components/SoftKeyboard/SoftKeyboard";
//import ReactDOM from 'react-dom';

//import { useTranslation, initReactI18next } from 'react-i18next';

//import i18n from 'i18next';

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
  returnEmptyString: false,
  debug: true,
  fallbackLng: "en",
});

const Login = () => {
  const { t } = useTranslation();
  //const [language, setLanguage] = useState("english");

  const [loginData, setLoginData] = useState({
    name: "",
    password: "",
    kiosk: null,
    VendorCode: "",
  });

  const [validateUser, setValidateUser] = useState(false);
  const [validateKiosk, setValidateKiosk] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadPage, setLoadPage] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [kioskData, setKioskData] = useState(false);
  const [errorModalData, setErrorModalData] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [focusedField,setFocusedField] = useState(null);
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);

  const nameRef = useRef(null);
  const passRef = useRef(null);

  const [kioskOptions, setKioskOptions] = useState([
    {
      kioskID: null,
      kioskName: "",
    },
  ]);
  const closeErrorModal = () => {
    setErrorModalData((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };
  useEffect(() => {
    if (!kioskData) {
      getKiosks();
    }
  });

  const getKiosks = async () => {
    const kioskResponse = await getKioskData();
    if (kioskResponse.data.result && kioskResponse.data.data != null) {
      setKioskData(true);
      setKioskOptions(kioskResponse.data.data);
      setLoadPage(true);
      console.log(JSON.stringify(kioskOptions));
    }
  };

  const location = useLocation();
  //removeParametersFromLocalStorage("token");

  useEffect(() => {
    if (location.pathname === "/") {
      // const token = sessionStorage.getItem("token");
      // console.log("checking path before token check.....within login");
      // if(token && token !== 'undefined'){
      //   console.log("within if.. passed token check.. within login");
      //  navigate(HOME);
      // }
      // else{
      localStorage.clear();
      sessionStorage.clear();
      // }
    }
  }, [location.pathname]);

  const loginHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const kiosk = event.target.KioskID;
    setValidateUser(false);

    setLoginData({ ...loginData, [name]: value, [kiosk]: kiosk });
  };
  const handleKeyClick = (key) => {
    if (!focusedField) return;
    const currentValue = loginData[focusedField];
    let updatedValue;
    if (key === "⌫") {
      updatedValue = currentValue.slice(0, -1); // Remove last character
    } else if (key === "Space") {
      updatedValue = currentValue + " "; // Add space
    } else if (key !== "Enter") {
      updatedValue = currentValue + key; // Append the key value
    }
  
    // Call the appropriate onChange handler with the updated value
    const updatedEvent = {
      target: {
        name: focusedField,
        value: updatedValue,
      },
    };

    loginHandler(updatedEvent);
  }
  const handleFocus = (field,ref) => {
    setFocusedField(field);
    setIsKeyboardVisible(true);
    document.body.classList.add('no-scroll');
    setTimeout(() => scrollToField(ref), 100);
  };
  const scrollToField = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };
  const [confirmationNos, setConfirmationNos] = useState([]);

  const addConfirmationNo = (newItem) => {
    // Update the state using the current state
    setConfirmationNos((prevItems) => {
      const updatedItems = [...prevItems, newItem];
      console.log("Updated confirmationNos:", updatedItems);
      return updatedItems;
    });
  };

  const loginClick = async () => {
    if (!loginData.name || !loginData.password) {
      setValidateUser(true);
      return;
    }
    if (!loginData.kiosk) {
      setValidateKiosk(true);
      return;
    }
    try {
      setValidateUser(false);
      setValidateKiosk(false);
      setLoading(true);
      console.log(loginData);
      const response = await loginApi(
        JSON.stringify({ ...loginData, userName: loginData.name })
      );
      console.log(response);
      //if (response.status === 200) {
      if (response.data.message === "Success") {
        const data = response.data || {};

        saveTokenInSessionStorage(data.token);
        //  saveParametersIntoLocalStorage("TerminalID", loginData.kiosk);
        saveParametersIntoLocalStorage("Kiosk", loginData.kiosk);
        saveParametersIntoLocalStorage("username", loginData.name);
        saveParametersIntoLocalStorage("password", loginData.password);

        const kioskResponse = await getSettingList(data.token);
        if (kioskResponse.data.result && kioskResponse.data.data != null) {
          saveParametersIntoLocalStorage("settings", kioskResponse.data.data);
        }
        //Data fetching for Dynamic fields
        const fieldResponse = await getFieldList(data?.token);

        if (fieldResponse.data.result && fieldResponse.data.data != null) {
          const labelFieldMappings = fieldResponse.data.data
            .filter((item) => item.fieldType === "label")
            .map((item) => {
              return {
                fieldCode: item.fieldCode,
                isMandatory: item.isMandatory,
                sortOrder: item.sortOrder,
              };
            });
          const inputFieldMappings = fieldResponse.data.data
            .filter(
              (item) =>
                item.fieldType === "input" && item.fieldCategory !== "popup"
            )
            .map((item) => ({
              fieldCode: item.fieldCode,
              isMandatory: item.isMandatory,
              sortOrder: item.sortOrder,
            }));
          const listingMappings = fieldResponse.data.data
            .filter((item) => item.fieldType === "listing")
            .map((item) => ({
              fieldCode: item.fieldCode,
              isMandatory: item.isMandatory,
              sortOrder: item.sortOrder,
            }));
          const popupMappings = fieldResponse.data.data
            .filter((item) => item.fieldCategory === "popup")
            .map((item) => ({
              fieldCode: item.fieldCode,
              isMandatory: item.isMandatory,
              sortOrder: item.sortOrder,
            }));
          inputFieldMappings.sort((a, b) => a.sortOrder - b.sortOrder);
          labelFieldMappings.sort((a, b) => a.sortOrder - b.sortOrder);
          listingMappings.sort((a, b) => a.sortOrder - b.sortOrder);
          popupMappings.sort((a, b) => a.sortOrder - b.sortOrder);
          console.log(labelFieldMappings);
          console.log(inputFieldMappings);
          console.log(listingMappings);
          console.log(popupMappings);
          saveParametersIntoLocalStorage("InputFieldList", inputFieldMappings);
          saveParametersIntoLocalStorage("LabelFieldList", labelFieldMappings);
          saveParametersIntoLocalStorage("ListingList", listingMappings);
          saveParametersIntoLocalStorage("PopupList", popupMappings);
        }
        dispatch(fetchPropertyInfo(data.token));
        dispatch(
          fetchModulesConfiguration({
            IsStepEnabled: true,
            IsModuleEnabled: true,
            imageSpecified: true,
            Apptype: "kiosk",
            StepType: "checkin",
            token: data?.token,
          })
        );

        const deviceConfig = await getKioskConfiguration(
          {
            KioskName: loginData.kiosk,
          },
          data.token
        );

        if (deviceConfig.data.result && deviceConfig.data.data != null) {
          dispatch(setDeviceConfiguration(deviceConfig?.data?.data));
          const deviceID = deviceConfig?.data?.data.length
            ? deviceConfig?.data?.data?.find(
                (ele) => ele?.settingsName === "PaymentDevice"
              )
            : deviceConfig?.data?.data;
          localStorage.setItem("PaymentDevice", deviceID?.settingsValue);
          saveParametersIntoLocalStorage(
            "DeviceInfo",
            deviceConfig?.data?.data
          );
        } else {
          setLoading(false);
          setLoginError(t("Unable to find the configuration details"));
          return;
        }

        const getStepsWithModules = await getStepWithModules(
          {
            IsStepEnabled: true,
            IsModuleEnabled: true,
            imageSpecified: true,
            Apptype: "kiosk",
            StepType: "",
          },
          data?.token
        );
        if (
          getStepsWithModules.status === 200 &&
          getStepsWithModules.data.result
        ) {
          dispatch(moduleConfigurationInfo(getStepsWithModules?.data?.data)); //moduleConfigurationInfo
        }

        try {
          const getLanguageMasterData = await getLanguageMaster(data?.token);
          if (getLanguageMasterData.status === 200) {
            const result = getLanguageMasterData.data;

            saveParametersIntoLocalStorage("Languages", result.data);
          }
        } catch (error) {
          // setErrorModalData4((state) => ({
          //   ...state,
          //   toggleError: true,
          //   errorMessage: error.message,
          // }));
        }

        setLoading(false);
        setLoginData({ name: "", password: "", VendorCode: "Hotel" });
        dispatch(login({ token: data.token }));
        navigate(HOME, { replace: true });
        return;
      } else {
        console.log(response.data);
        setLoading(false);
        setLoginError(response.data.message);
        setErrorModalData((state) => ({
          ...state,
          toggleError: true,
          errorMessage: t(response.data.message) ?? t("Some error occured"),
        }));
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setLoginError(error.error);
      setErrorModalData((state) => ({
        ...state,
        toggleError: true,
        errorMessage: t(error.error) ?? t("Some error occured"),
      }));
      return;
    }
    return;
  };

  if (loadPage) {
    return (
      <div>
        <section>
          <div className="container-fluid">
            <div className="content-bg">
              <div className="row">
                <div className="col-sm-5 pl-0">
                  <div className="h-100">
                    <div
                      className="content-left"
                      style={{ background: "white" }}
                    >
                      {loading && <LoaderPopup loading={loading} />}
                      <div className="mt-90">
                        <div>
                          <h2 className="welcome-title">LOG-IN</h2>
                        </div>

                        <div className="login-box">
                          <div>
                            <label>{t("Username")}</label>
                            <input
                              //ref = {nameRef}
                              value={loginData.name}
                              onChange={loginHandler}
                              name="name"
                              type="text"
                              placeholder="username"
                              className="form-control form-control-set"
                              //onFocus={() =>handleFocus("name",nameRef)}
                            />
                          </div>

                          <div className="">
                            <label>{t("Password")}</label>
                            <input
                              value={loginData.password}
                              onChange={loginHandler}
                              name="password"
                              type="password"
                              placeholder="password"
                              className="form-control form-control-set"
                            />
                          </div>
                          <div className="">
                            <label>
                              {t("Select Kiosk")} :
                              {/* <span style={{ color: "red" }}>*</span>  */}
                            </label>
                            <select
                              name="kiosk"
                              value={kioskOptions.kioskName}
                              onChange={loginHandler}
                              className="form-control form-control-set"
                            >
                              <option value="" default>
                                {t("Select")}
                              </option>
                              {kioskOptions?.map((kiosk, idx) => {
                                return (
                                  <option key={idx} value={kiosk.kioskName}>
                                    {kiosk.kioskName}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          {validateKiosk && (
                            <p className="paragraph-validation error-message">
                              {t("Please select the Kiosk")}
                            </p>
                          )}
                        </div>
                        <div className="text-center mt-30">
                          <div>
                            {validateUser && (
                              <p className="paragraph-validation error-message">
                                {t(
                                  "Please enter correct username and password"
                                )}
                              </p>
                            )}

                            {loginError && (
                              <ErrorPopup
                                closeErrorModal={closeErrorModal}
                                erroModalData={errorModalData}
                              />
                            )}
                            <button
                              onClick={loginClick}
                              type="button"
                              className="btn btn-warning btn-lg button-yellow"
                            >
                              {loading && <i className="bx bx-loader"></i>}
                              {t("Log - In")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-7 pr-0">
                  <div className="login-image"></div>
                </div>
              </div>
            </div>
          </div>
          <SoftKeyboard
          handleKeyClick={handleKeyClick}
           isVisible={isKeyboardVisible}
           setIsVisible={setIsKeyboardVisible}
           focusedField={focusedField}
           setFocusedField={setFocusedField}
          />
        </section>
      </div>
    );
  }

  return <>...</>;
};

export default Login;
